import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_VEHICLES_URL, ROUTE_VEHICLE_URL, CHOICE_VEHICLE, ROUTE_CARRIERS_URL, TAB_ADMIN, ADMIN_VEHICLES } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_ACTIVATE, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import { getStateIcon } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';
import FMaterialTableNew from '../widgets/FMaterialTableNew';
import FTableActionNew from '../widgets/FTableActionNew';


class Vehicles extends FListComponent {

  constructor(props) {
    super(props, API_VEHICLES_URL, CHOICE_VEHICLE)
    this.currentTitle = 'Vehicles'
    this.currentTab = TAB_ADMIN
    this.routeUrl = ROUTE_VEHICLE_URL
    this.policyID = ADMIN_VEHICLES
  }

  onTableAction = (actionId, rowData) => {
    let data = { id: rowData.id };
    let url = this.getListURL() + rowData.id + '/event/';

    switch (actionId) {
      case "ACTION_DELETE":
        this.deleteRow(rowData);
        break;
      case "ACTION_INACTIVE":
        this.updateRow(rowData, { state: "INACTIVE" });
        break;
      case "ACTION_ACTIVE":
        this.updateRow(rowData, { state: "ACTIVE" });
        break;
      case "REQUEST_VEHICLE_DOCUMENT_SUBMIT_DOCUMENT_IRP":
      case "REQUEST_VEHICLE_DOCUMENT_SUBMIT_DOCUMENT_REGISTRATION":
        this.actionHandler(url, { ...data, eventType: actionId });
        break;
      default:
        console.warn(`Unhandled action: ${actionId}`);
    }
  };


  render() {

    return (
      <div>
        <FMaterialTableNew
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Vehicle',
              render: rowData => {
                const { vehicleInfo } = rowData
                return (
                  <React.Fragment>
                    {this.getVehicleInfoEditLink(vehicleInfo)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Driver',
              render: rowData => {
                const { driver } = rowData
                return (
                  <React.Fragment>
                    <div style={{ display: 'inline-flex' }}>
                      {this.getDriverInfoEditLink(rowData)}

                    </div>
                  </React.Fragment>
                );
              }
            },
            {
              title: 'Trailer ',
              render: rowData => {
                const { trailerInfo } = rowData
                return (
                  <React.Fragment>
                    {this.getVehicleInfoEditLink(trailerInfo)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Plate / State / VIN ',
              render: rowData => {
                const { vehicleInfo } = rowData
                return (
                  <React.Fragment>
                    {vehicleInfo.vehiclePlateNumber} / {vehicleInfo.vehiclePlateState} / {vehicleInfo.vehicleVIN}
                  </React.Fragment>
                )
              }
            },

            {
              title: 'Date Activated', field: 'vehicleStartDate',
              render: rowData => this.getPrettyDate(rowData.vehicleStartDate)
            },


            {
              title: 'Actions',
              render: rowData => {
                const actions = rowData.workflowActionItems || [];

                return (
                  <React.Fragment>
                    {!!actions.length ? (
                      <FTableActionNew
                        actions={actions}
                        onTableAction={this.onTableAction}
                        rowData={rowData}
                      />
                    ) : (
                      <BlockIcon color="disabled" titleAccess="No Actions Available" />
                    )}
                  </React.Fragment>
                );
              }
            }
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          options={{
            pageSize: this.state.pageSize,
            pageSizeOptions: this.state.pageSizeOptions,
            actionsColumnIndex: -1
          }}
          data={this.fetchData}
          title="Vehicles"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Vehicles)
