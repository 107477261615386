import React from 'react';
// import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
import { getStateIcon } from '../../../util/icon_util';

export default class Drivers extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            {
              title: 'State', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStateIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            { title: 'Driver ID', field: 'driverAccessID' },
            {
              title: 'Name', 
              field: 'prettyName',
              render: rowData => {
                return (
                  <React.Fragment>
                    {`${rowData.driverFirstName} ${rowData.driverMiddleName || ''} ${rowData.driverLastName}`}
                  </React.Fragment>
                )
              }
            },
            { title: 'Licence Number', field: 'driverLicenceNumber' },

            { title: 'Date Hired', field: 'driverHiredDate', render: rowData =>getPrettyDate(rowData.driverHiredDate) },
            

           
          ]}
          
          options = {{
              pageSize: this.props.pstate.pageSize,
              pageSizeOptions: this.props.pstate.pageSizeOptions,
              actionsColumnIndex: -1,
              search: false
           }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
          
        />

      </div>
    );
  }
}


// export default connect(
//   mapStateToProps, mapDispatchToProps)(Drivers)
