import React, { Component } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { mapStateToProps } from '../../fredux/ActionMaker';
import { connect } from 'react-redux'
import {  has_view_permission} from '../../util/ACL';
import * as CONST from "../../util/Const";
import FListItem from '../widgets/FListItem';


class SafetyAndComplianceListItems extends Component {

    render() {
         const profile = this.props.profile
        return (
            <div>
            <FListItem  
                disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_EXPIRING_DOCS)}
                to={CONST.ROUTER_EXPIRING_URL} 
                label='Expiring Docs'>
               <ListItemIcon>ED</ListItemIcon>
            </FListItem>

            <FListItem 
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_EXPIRED_DOCS)}
               to={CONST.ROUTER_EXPIRED_URL} label='Expired Docs'>
               <ListItemIcon>EX</ListItemIcon>
            </FListItem>

           
            <FListItem  
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_MISSING_DOCS)}
               to={CONST.ROUTER_MISSING_DOCUMENTS_URL} label='Missing Docs'>
               <ListItemIcon>MD</ListItemIcon>
            </FListItem>
           
            <hr />

            <FListItem 
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_DRUG_TESTS)}
               to={CONST.ROUTE_DRUG_TEST_URL} label='Drug Tests'>
               <ListItemIcon>DT</ListItemIcon>
            </FListItem>

            {/* // Moved to archived features we don't manage this since we have our own employement verification module
             <FListItem 
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS)}
               to={CONST.ROUTE_EMPLOYEMENT_HISTORY_URL} label='Employment History'>
               <ListItemIcon>EH</ListItemIcon>
            </FListItem> */}

            {/* <FListItem 
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS)}
               to={CONST.ROUTE_RESIDENT_HISTORY_URL} label='Residential History'>
               <ListItemIcon>RH</ListItemIcon>
            </FListItem> */}

            <FListItem 
              disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS)}
              to={CONST.ROUTE_VEHICLE_INSPECTION_URL} label='Vehicle Inspections'>
               <ListItemIcon>VI</ListItemIcon>
            </FListItem>

            <FListItem  
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES)}
               to={CONST.ROUTE_VEHICLE_MAINTENANCE_URL} label='Vehicle Maintenance'>
               <ListItemIcon>MA</ListItemIcon>
            </FListItem>

           <FListItem  
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS)}
               to={CONST.ROUTE_TRAFFIC_VIOLATION_URL} label='Traffic Violations'>
              <ListItemIcon>TV</ListItemIcon>
           </FListItem>

           <FListItem 
              disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS)}
              to={CONST.ROUTE_ACCIDENT_REGISTER_URL} label='Accident Registers'>
              <ListItemIcon>ACC</ListItemIcon>
           </FListItem>  
    
           <FListItem  
              disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS)}
              to={CONST.ROUTE_DRIVER_DISCIPLINARY_EVENT_URL} label='Disciplinable Event'>
              <ListItemIcon>DSD</ListItemIcon>
           </FListItem> 
           
           <hr/>

           <FListItem  
               disabled={!has_view_permission(profile, CONST.SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES)}
              to={CONST.ROUTE_MAINTENANCE_SCHEDULE_URL} label='Maintenance Schedule'>
              <ListItemIcon>VMS</ListItemIcon>
           </FListItem>
        </div>
        );
    }
}


export default connect(mapStateToProps)(SafetyAndComplianceListItems)
