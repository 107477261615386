import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Error as ErrorIcon, Warning as WarningIcon, Info as InfoIcon, CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { INFO_COLOR, ERROR_COLOR, WARNING_COLOR, SUCCESS_COLOR, ATTENTION_REQUIRED_COLOR, NEUTRAL_COLOR, PRIMARY_COLOR } from "../../util/Const";

const alertTypes = {
  info: { color: INFO_COLOR, icon: InfoIcon, iconColor: "#2196f3" },
  error: { color: ERROR_COLOR, icon: ErrorIcon, iconColor: "#f44336" },
  warning: { color: WARNING_COLOR, icon: WarningIcon, iconColor: "#ff9800" },
  success: { color: SUCCESS_COLOR, icon: CheckCircleIcon, iconColor: "#4caf50" },
  attention: { color: ATTENTION_REQUIRED_COLOR, icon: InfoIcon, iconColor: "#ffeb3b" },
  neutral: { color: NEUTRAL_COLOR, icon: InfoIcon, iconColor: "#757575" },
  primary: { color: PRIMARY_COLOR, icon: InfoIcon, iconColor: "#90a4ae" },
};


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(1, 2),
    borderRadius: "5px",
    whiteSpace: "nowrap",
    width: "100%",
    fontSize: "1.2rem", // Set default font size
    transform: "scale(1)", // Scale the text without affecting row height
    transformOrigin: "left center", // Ensure the scaling starts from the left side
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const FAlert = ({ alertType = "info", style, children }) => {
  const classes = useStyles();

  const { color, icon: Icon, iconColor } = alertTypes[alertType] || alertTypes.info;

  return (
    <p className={classes.root}
      style={{
        ...style,
        backgroundColor: alertType === "neutral" ? "transparent" : color, // No background for neutral
      }}
    >
      <Icon className={classes.icon} style={{ color: iconColor }} />
      {children}
    </p>
  );
};

export default FAlert;
