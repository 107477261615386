import React, { Component } from 'react';
import {
  API_DRIVER_EMPLOYMENT_APPLICATION_URL,
  CHOICE_DRIVER_EMPLOYMENT_APPLICATION, TAB_DRIVER_APP,
  DRIVER_APP_EMPLOYMENT_APPLICATION, ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL
} from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import BlockIcon from '@material-ui/icons/Block';
import { getDriverEmploymentVerificationReport, getDriverHiringProcessStatusIcon, getEmploymentApplicationIcon, getEmploymentHistoryForDriverApplicationView, getEmploymentHistoryResponseForDriverApplicationView, getEmploymentVerificationReport, getStateIcon, showPhoneUserView } from '../../util/icon_util';
import FLinearProgressBar from '../widgets/FLinearProgressBar';
import FMaterialTableNew from '../widgets/FMaterialTableNew';
import FTableActionNew from '../widgets/FTableActionNew';


class DriverEmploymentApplicatoins extends FListComponent {
  constructor(props) {
    super(props, API_DRIVER_EMPLOYMENT_APPLICATION_URL, CHOICE_DRIVER_EMPLOYMENT_APPLICATION)
    this.currentTitle = 'Employment Applications'
    this.currentTab = TAB_DRIVER_APP
    this.routeUrl = ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL
    this.policyID = DRIVER_APP_EMPLOYMENT_APPLICATION
    this.includeDeleteAction = false
    this.includeDriverHiringProcessStatus = true
  }


  onTableAction = (actionId, rowData) => {
    let data = { id: rowData.id };
    let url = this.getListURL() + rowData.id + '/event/';

    switch (actionId) {
      case "INVITE_DRIVER_TO_APPLY_VIA_APP":
      case "COMPLETE_DRIVER_APPLICATION":
      case "INTIATE_APPLICATION_BACKGROUND_VERIFICATION_PROCESS":
        this.actionHandler(url, { ...data, eventType: actionId });
        break;
      default:
        console.warn(`Unhandled action: ${actionId}`);
    }
  };


  render() {
    const { classes } = this.props;
    return (
      <div>
        <FMaterialTableNew
          isLoading={this.state.isLoading}
          tableRef={this.tableRef}
          columns={[
            // {
            //   title: 'App User',
            //   width: '20%',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <div style={{ display: 'inline-flex' }}>
            //           {showPhoneUserView(rowData)}
            //         </div>
            //       </React.Fragment>
            //     );
            //   }
            // },
            {
              title: 'Driver / Prospect Driver',
              render: rowData => {
                return (
                  <React.Fragment>
                    <div style={{ display: 'inline-flex' }}>
                      {this.getDriverInfoEditLink(rowData.driverProfile)}
                      
                    </div>
                </React.Fragment>
                );
              }
            },
            // {
            //   title: 'Verfication Status',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <div style={{ display: 'inline-flex' }}>
            //           {rowData.employmentVerification?.verificationStatus}
                      
            //         </div>
            //     </React.Fragment>
            //     );
            //   }
            // },
            {
              title: 'Driver Applicationnn',
              render: rowData => {
                if (!rowData.is_profile_app_user) {
                  return null;
                }

                // const hasEmploymentApplication = rowData.applicationFile || false;
                return (
                  <React.Fragment>
                    <div style={{ display: 'inline-flex' }}>
                      {getEmploymentApplicationIcon(rowData)}
                    </div>
                  </React.Fragment>
                );
              }
            },
            {
              title: 'Employment Verification Status',
              fields: 'verificationInfo',
              render: rowData => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
                    <span style={{ fontWeight: 'bold', color: '#333', marginRight: 3 }}>
                      {getEmploymentVerificationReport(rowData)}
                    </span>
                  </div>
                );
              }
            },

            {
              title: 'Verification Progress & Estimated Date',
              render: rowData => {
                if (!rowData?.is_profile_app_user) {
                  return null;
                }
            
                const progress = rowData.employmentInfo?.employmentVerificationProgress || {};
                const completedSteps = progress.completedSteps || 0;
                const totalSteps = progress.totalSteps || 0;
                const estimatedCompletionDate = progress.estimatedCompletionDate;
            
                // Only render if there is progress
                if (completedSteps > 0 && totalSteps > 0) {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                      {/* Driver Hiring Status Icon */}
                      <div style={{ display: 'inline-flex', marginRight: 16 }}>
                        {getDriverHiringProcessStatusIcon(rowData)}
                      </div>
            
                      {/* Progress Bar with Percentage */}
                      {totalSteps && (
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 16, maxWidth: 'auto' }}>
                          <FLinearProgressBar
                            completedSteps={completedSteps}
                            totalSteps={totalSteps}
                            style={{ height: 8, width: 150, marginRight: 8 }}
                          />
                          <span>{Math.round((completedSteps / totalSteps) * 100)}%</span>
                        </div>
                      )}
            
                      {/* Display Completed and Total Steps */}
                      {totalSteps > 0 && (
                        <span style={{ marginRight: 16, fontSize: '1em', color: '#333', whiteSpace: 'nowrap' }}>
                          {completedSteps}/{totalSteps} Steps
                        </span>
                      )}
            
                      {/* Estimated Completion Date */}
                      {estimatedCompletionDate && (
                        <span style={{ fontSize: '1em', fontWeight: 'bold', color: '#333', whiteSpace: 'nowrap' }}>
                          ETA: {this.getPrettyDate(estimatedCompletionDate)}
                        </span>
                      )}
                    </div>
                  );
                }
            
                return null; // If no progress, return nothing
              }
            },
            
            {
              title: 'Actions',
              render: rowData => {
                const actions = rowData.workflowActionItems || [];

                return (
                  <React.Fragment>
                    {!!actions.length ? (
                      <FTableActionNew
                        actions={actions}
                        onTableAction={this.onTableAction}
                        rowData={rowData}
                      />
                    ) : (
                      <BlockIcon color="disabled" titleAccess="No Actions Available" />
                    )}
                  </React.Fragment>
                );
              }
            }
          ]}
          options={{
            pageSize: this.state.pageSize,
            pageSizeOptions: this.state.pageSizeOptions,
            actionsColumnIndex: -1
          }}
          data={this.fetchData}
          title="Driver Employment Application"
          listErrCode={this.state.listErrCode}
        />
      </div>
    );
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(DriverEmploymentApplicatoins))
