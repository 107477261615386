import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const DateDeliverBadge = ({ verification, date }) => {
  if (!date) return null;

  const isVerified = verification?.isVerified;

  return (
    <div style={{ position: "relative", display: "inline-block", paddingRight: "0.3em" }}>
      <span style={{fontWeight: "bold", whiteSpace: "nowrap" }}>
        {date}
      </span>
      {isVerified && (
        <CheckCircleIcon
          style={{
            color: "#007BFF",
            position: "absolute",
            top: "-0.5em",
            right: "-0.68em",
          }}
        />
      )}
    </div>
  );
};

export default DateDeliverBadge;
