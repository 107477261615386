import Axios from "axios";
import {
    AUTH_TOKEN
} from "./Const"; //tODO(Estifanos) Remove already exist below 
import * as CONST from './Const';
import * as ACTION from '../fredux/ActionTypes';
import store from '../fredux/Store'
import {
    isNotNullOrEmpty,
    isObjEmpty
} from './Validator'
import {
    isProfileActive
} from "./ACL";
import {
    DarkRawTheme
} from "material-ui/styles";
import { getErrroMessage } from "../components/widgets/FMessage";

export const getAuthToken = function () {
    let profileData = JSON.parse(localStorage.getItem(CONST.PROFILE))
    if (!!profileData) return profileData.AUTH_TOKEN
}

export const getZTUIAdminVersion = function () {
    let profileData = JSON.parse(localStorage.getItem(CONST.PROFILE))
    if (!!profileData) return profileData[CONST.ZT_ADMIN_UI_VERSION_NAME]
    return undefined
}

export const getAuthProfile = function () {
    let profileData = JSON.parse(localStorage.getItem(CONST.PROFILE))
    if (!!profileData) return profileData
}

export const getProfileType = function () {
    let profileData = JSON.parse(localStorage.getItem(CONST.PROFILE))
    if (!!profileData) return profileData.profileType
}

export const updateZTVersionInStore = function (props, res) {
    if (res.headers[CONST.ZT_ADMIN_UI_VERSION_NAME] == props[CONST.ZT_ADMIN_UI_VERSION_NAME]){
        return
    }
    props.updateParent({
                [CONST.ZT_ADMIN_UI_VERSION_NAME]: res.headers[CONST.ZT_ADMIN_UI_VERSION_NAME]
    })
}
export const hasNewVersionUpdate = function (props) {
    let currentVersion = props[CONST.ZT_ADMIN_UI_VERSION_NAME]
    let perviousVersion =  getZTUIAdminVersion()
    let hasVersionDifference = false
    if (currentVersion !== undefined && perviousVersion !== undefined){
            hasVersionDifference = currentVersion != perviousVersion
    }
    return hasVersionDifference

}
export const isAdminProfile = function () {
    let profileType = getProfileType()
    profileType = profileType && profileType.toLowerCase()
    return (profileType == 'admin' || profileType == 'carrier')
}

export const updateAuthToken = function (res) {
    let data = GET_RESULT(res)
    let authToken = res.headers[AUTH_TOKEN]
    localStorage.setItem(CONST.PROFILE, JSON.stringify({
        AUTH_TOKEN: authToken,
        profileID: data.id,
        profileType: data.profileType,
        prettyName: data.prettyName,
        ownerSubscriptionStatus: data.ownerSubscriptionStatus,
        [CONST.ZT_ADMIN_UI_VERSION_NAME]:  res.headers[CONST.ZT_ADMIN_UI_VERSION_NAME]
    }))
}


export const inValidateAuthToken = function (res) {
    localStorage.removeItem(CONST.PROFILE)
}


export const ROUTE = function (url, props) {
    props.history.push(url)
}

export const GET_RESULT = function (res) {
    let data = res.data
    if (data && data.results) {
        return data.results
    }
    return data
}
export const GET = function (url) {

    let config = {
        headers: {
            'Authorization': `Token ${getAuthToken()}`
        }
    }
    return Axios.get(url, config = config)
}

export const DOWNLOAD_FILE = function (url, fileName, type = 'csv') {

    let config = {
        responseType: 'blob',
        headers: {
            'Authorization': `Token ${getAuthToken()}`
        }
    }
    return Axios.get(url, config = config).then(({
        data
    }) => {

        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', `${fileName}.${type}`);

        document.body.appendChild(link);

        link.click();

        link.remove();

    });
}


export const DELETE = function (url) {

    let config = {
        headers: {
            'Authorization': `Token ${getAuthToken()}`
        }
    }
    return Axios.delete(url, config = config)
}


export const UPDATE = function (url, method, data, config = null) {
    if (config) {
        alert("unknown config")
    }

    let headers = {
        'content-type': 'multipart/form-data',
        'Authorization': `Token ${getAuthToken()}`
    }
    //This should be added on logged in profile backend.
    // data.append('authority', 1)
    return Axios({
        url: url,
        method: method,
        data: data,
        headers: headers,
    })
}


export const fetchAndUpdateStoreWithUrl = function (url, action) {
    GET(url).then(res => {
        store.dispatch({
            type: action,
            payload: GET_RESULT(res)
        })
    }).catch(err => {
    })
}

const API_TO_STORE = {
    [CONST.CHOICE_CARRIER]: {
        url: CONST.API_CARRIERS_URL,
        action: ACTION.ACTION_FETCH_CARRIERS
    },
    [CONST.CHOICE_DISPATCHER]: {
        url: CONST.API_DISPATCHERS_URL,
        action: ACTION.ACTION_FETCH_DISPATCHERS,
    },
    [CONST.CHOICE_VEHICLE]: {
        url: CONST.API_VEHICLES_URL,
        action: ACTION.ACTION_FETCH_VEHICLES,
    },
    [CONST.CHOICE_DRIVER]: {
        url: CONST.API_DRIVERS_URL,
        action: ACTION.ACTION_FETCH_DRIVERS,
    },
    [CONST.CHOICE_CUSTOMER]: {
        url: CONST.API_CUSTOMER_URL,
        action: ACTION.ACTION_FETCH_CUSTOMERS,
    }
}

export const fetchAndUpdateStore = function (module) {
    GET(API_TO_STORE[module].url).then(res => {
        store.dispatch({
            type: API_TO_STORE[module].action,
            payload: GET_RESULT(res)
        })
    }).catch(err => {
        
    })
}

export const getChoice = function (storeKey, choiceValue) {
    let choice = {}
    if (!(storeKey == undefined || storeKey == [] || storeKey == null)) {
        choice = storeKey.find(v => v.choiceValue == choiceValue)
    }
    return choice;

}

const fetchAndUpdateChoiceStore = function (props) {
    let key = ''
    let store_key = ''
    let DATA = {}
    GET(CONST.API_CHOICE_URL).then(res => {
        const choices = GET_RESULT(res)
        choices.forEach(function (choice) {
            key = choice.choiceCategory + '_' + choice.choiceSubCategory;
            store_key = `${key}_choices`
            if (!DATA.hasOwnProperty(store_key)) {
                DATA[store_key] = [];
            }
            DATA[store_key].push(choice);
        });
        // store.dispatch({ type: ACTION.ACTION_UPDATE_PARENT, payload: DATA})
        props.updateParent(DATA)
        // props.updateParent({updateProfileData: false})

    }).catch(err => {
        handleApiError(props, err )
        if(err['default_code'] && err['default_code'].includes('permission.')) {
        //   handleApiError(props, err )
          logout(props)
        }
      
        // alert('Error code S-100, please contact your admin!')
    })
}

//Get model from list of models
export const getModelByID = function (models, modelID) {
    let model = {}

    models.forEach(function (m, key) {
        if (m.id == modelID) {
            model = m;
        }
    });
    return model;
}

export const getDriver = function (props, driverID) {
    let drivers = props.drivers
    let driver = {}

    drivers.forEach(function (d, key) {
        if (d.id == driverID) {
            driver = d;
        }
    });
    return driver;
}

export const initStore = function (props) {
    fetchAndUpdateChoiceStore(props)
    // fetchAndUpdateStoreWithUrl(CONST.API_DASHBOARD_SUMMARY, ACTION.ACTION_UPDATE_PARENT)
    // updateDashBoardPreview()
}

export const isValidOwnerSubscription = function (ownerSubscriptionStatus) {
    if (ownerSubscriptionStatus == 'VALID' || ownerSubscriptionStatus == 'TRIAL') {
        return true
    }
    return false
}


export const rerouteToPayment = function () {
    if (window.location.pathname != '/UI/profile/payment/') {
        window.location.href = '/UI/profile/payment/'
    }
}


export const validateSubscriptionWithRerouteToPaymentPage = function (profileData, props) {
    let ownerSubscriptionStatus = profileData.ownerSubscriptionStatus || props.profile && props.profile.ownerSubscriptionStatus || getAuthProfile().ownerSubscriptionStatus
    
    if (!!!ownerSubscriptionStatus){
        //getProfile(props) //  TODO(Estifanos)  Force User to Logout
        // alert(" Please Signout and Signin again for our new version to take effect ")
    }
    if (!isValidOwnerSubscription( ownerSubscriptionStatus)){
        rerouteToPayment()
    }
   
  }


export const updateProfileState = function (props) {
    GET(CONST.FETCH_PROFILE_STATE).then(res => {
        let data = GET_RESULT(res)
        if (!isProfileActive(data)) {
            logout(props)
        }
        validateSubscriptionWithRerouteToPaymentPage(data, props)
        updateZTVersionInStore(props, res)
        props.updateParent(data)
    }).catch(err => {
        handleApiError(props, err )
        healthCheck(props)
    })
}

export const healthCheck = function (props) {
    GET(CONST.API_HEALTH_CHECK).then(res => {
        // Do Nothing if there is no error
    }).catch(err => {
        window.location.href = '/maintenancemode';
    })
}



//TOOD(Estifo) Not sure the purpose of these remove 
export const getProfile = function (props) {
    let profileData = JSON.parse(localStorage.getItem(CONST.PROFILE))
    if (!!!profileData || !!!props.updateProfileData) return
    
    let profileUrl = CONST.API_PROFILE_URL + profileData.profileID
    GET(profileUrl).then(res => {
        let data = GET_RESULT(res)
        props.updateParent({
            profile: data
        })
        props.updateParent({
            updateProfileData: false
        })
        
    }).catch(err => {
        healthCheck(props)
        handleApiError(props, err )
        
    })
}


export const getCarriers = function (props) {
    return props.profile && props.profile.carriers || []
}

export const getCarrier = function (props, carrier) {

    let carriers = getCarriers(props)
    if (typeof (carrier) == 'object' && carrier != null) {
        carrier = carrier.id
    } else {
        carrier = carrier
    }
    if (carrier == undefined || carrier == {} || carrier == null || carriers.length == 0) return

    let c = carriers.find(item => item.id == carrier)
    if (c == undefined || c == {} || c == null) return {}
    return c
}

export const getCarrierID = function (props, carrier) {
    carrier = getCarrier(props, carrier) || {}
    return carrier.id

}

export const getDrivers = function (props, carrier) {
    let c = getCarrier(props, carrier)
    if (c != undefined) {
        return c.drivers
    }
}

export const getDispatchers = function (props, carrier) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.dispatchers
    }
}

export const getCustomers = function (props, carrier) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.customers
    }
}

export const getVehicles = function (props, carrier, vehicleType) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.vehicles && c.vehicles.filter(v => v.vehicleType == vehicleType)
    }
}

export const getDrugTestCompany = function (props, carrier) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.companies && c.companies.filter(v => v.companyType == 'DRUG_TEST_SERVICE_PROVIDER')
    }
}


export const getCompany = function (props, carrier, companyType) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.companies && c.companies.filter(v => v.companyType == companyType)
    }
}

export const getFormerEmployers = function (props, carrier) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.companies && c.companies.filter(v => v.companyCode == CONST.CompanyCode.FORMER_EMPLOYER)
    }
}


export const getEmploymentHistory = function (props, carrier, driver) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        if (typeof (driver) == 'object') {
            driver = driver.id
        }
        return c.employmentHistories && c.employmentHistories.filter(
            v => v.driver == driver)
    }
}


export const getAllAccidentRegisters = function (props, carrier) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.accidentOrViolationRegisters && c.accidentOrViolationRegisters.filter(v => v.accidentOrViolationIncidentType == 'ACCIDENT')
    }
}

export const getAccidentRegisters = function (props, carrier, driver) {
    let c = getCarrier(props, carrier)
    if (!(c == undefined || c == {} || c == null)) {
        return c.accidentOrViolationRegisters && c.accidentOrViolationRegisters.filter(
            v => (v.accidentOrViolationIncidentType == 'ACCIDENT' && v.driver == driver)
        )
    }
}

export const refreshInSyncProfile = function (props) {
    let storeProfile = getAuthProfile() || {}
    let localProfile = props.profile || {}
    if (localProfile.id != storeProfile.profileID) {
        window.location.href = '/';
    }
}

export const logout = function (props) {

    UPDATE(CONST.API_AUTH_SIGNED_OUT, 'post', {}).then(res => {
        inValidateAuthToken()
        props.dispatchSignOut()
        window.location.href = '/';
    }).catch(err => {
        inValidateAuthToken()
        window.location.href = '/';
    })
}

export const getCompanyDocumentChoices = function (props, documentType) {
    let choices = []
    switch (documentType) {
        case 'BROKER':
            choices = props.document_company_broker_choices;
            break;
        case 'CARRIER':
            choices = props.document_company_carrier_choices;
            break;
        case 'SHIPPER':
            choices = props.document_company_shipper_choices;
            break;
        case 'ELD_SERVICE_PROVIDER':
            choices = props.document_company_eld_choices;
            break;
        case 'FUEL_CARD_SERVICE_PROVIDER':
            choices = props.document_company_fuel_card_choices;
            break;
        case 'DRUG_TEST_SERVICE_PROVIDER':
            choices = props.document_company_drug_test_choices;
            break;
        case 'AUTO_SERVICE_PROVIDER':
            choices = props.document_company_auto_shop_choices;
            break;
        case 'GENERAL_SERVICE_PROVIDER':
            choices = props.document_company_general_service_choices;
            break;
    }
    return choices;
}

export const getExpenseChoices = function (props, expenseForModule) {
    switch (expenseForModule) {
        case CONST.CHOICE_DISPATCHER:
        case CONST.CHOICE_DRIVER:
        case CONST.CHOICE_PERSONNEL:
            return props.expense_personnel_choices
        case CONST.CHOICE_VEHICLE:
            return props.expense_vehicle_choices
        case CONST.CHOICE_CARRIER:
        case CONST.CHOICE_COMPANY:
            return props.expense_carrier_choices
        case CONST.CHOICE_FLEET:
            return props.expense_fleet_choices
    }
    return props.expense_fleet_choices
}

export const getDriverEvents = function(props) {
    
    let eventDrivers  = props.event_driver_hard_event_choices
    if (!!!eventDrivers) {
        return []
    }
    eventDrivers = eventDrivers.concat(props.event_driver_eld_violation_choices)
    eventDrivers = eventDrivers.concat(props.event_driver_inspection_violation_choices)
    eventDrivers = eventDrivers.concat(props.event_driver_miscellaneous_choices)
    return eventDrivers
}


export const getPrettyNameFromListDict = function(listOfDict, idVal,  idKey='id',  prettyName='prettyName') {
    
    
    if (!!!listOfDict) {
        return ''
    }
    let resp = ''
    listOfDict.forEach(obj => {
         if (!!obj[idKey]) {
          
            if (obj[idKey] == idVal) {
                if (!!obj[prettyName]) {
                    resp =  obj[prettyName]
                }else{
                    resp =  obj[idKey]
                }
            return
            }
        }
    });
    return resp;
}

export const getIdFromURL = function(props) {
    return props.match && props.match.params && props.match.params.id
}

export const  getFormDatafromDict = (data) => {
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if (data[key] && data[key].hasOwnProperty("id")) {
            formData.append(key, data[key]['id'])
          } else {
            formData.append(key, data[key])
          }
    })
    
    return formData
  }


export const  getObjectFromList = (listData, keyVal,  key='id') => {
    let res = null
    listData.forEach(function (item, index) {
   		 if (item[key] == keyVal) {
            res =  item
            return 
         }
	});
    return res
}

export const handleApiError = (props, err) => {
    let status = err && err.response && err.response.status || 200
    let errMessage = 'Unknown Error'
    if(status == 401 ) {
        logout(props)
    }
    if(status == 403 ) {
        errMessage = getErrroMessage(err)
    }else{
        props.updateServerError && props.updateServerError({ msg: errMessage })
    }

    return {errMessage: errMessage, errCode: status}
}

export const getDriverProfileSubscriptionStatus = (profile, props ) =>{
    let status = profile.subscription &&  profile.subscription.subscriptionStatus
    if (status == 'VALID') {
        return 'VALID'
    }
    return 'IN_VALID'
}

