import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import FButton from "../widgets/FButton";
import FDialog from '../widgets/FDialog';
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import FSelect from "../widgets/FSelect";
import * as CONST from "../../util/Const";
import FInput from '../widgets/FInput';
import { FListDetailView } from '../../util/FListDetailView';
import { isValid } from "../../util/Validator";

import { withStyles } from "@material-ui/core/styles";

import {VALIDATE_REQUIRED, VALIDATE_EMAIL, VALIDATE_PHONE_NUMBER, VALIDATE_ADDRESS } from "../../util/ValidatorConst";
import FAddress from '../widgets/FAddress';

const styles = theme => ({
  root: {

  },
  padding: {
      marginTop: theme.spacing(4),
  },

  header: {
      background: '#f5f5f5'
  },
  button: {
      marginRight: theme.spacing(2),
  },
  dropDown: {},
});
const PROPS = {
  editMode: false,
  isNew: true,
  url: CONST.API_COMPANY,
  errorData: {
      companyName: false,
      companyMailingAddress: false,
      companyBillingAddress: false,
      companyPhoneNumber: false,
      companyEmail: false,
      companyType: false,
      companyContactName: false,
      companyContactPhoneNumber: false,
      companyContactEmail: false,
      companyIsInvoiceable: false,
      
      carrier: false,
      address: false
  },
  errorMessage: {
      companyName: 'Invalid Comany Name',
      companyMailingAddress: 'Invalid Mailing Address',
      companyBillingAddress: 'Invalid Billing Address',
      companyPhoneNumber: 'Invalid Phone Number',
      companyEmail: 'Invalid Email',
      companyType: 'Invalid Company Type',
      companyIsInvoiceable: 'Invald Selection',
      companyContactName: '',
      companyContactPhoneNumber: '',
      companyContactEmail: '',
      carrier: 'Invalid Carrier'
  },
  touched: {
      companyCode: true
  },
  data: {
      companyName: '',
      companyMailingAddress: '',
      companyBillingAddress: '',
      companyPhoneNumber: '',
      companyEmail: '',
      companyType: '',
      companyCode: '',
      companyContactName: '',
      companyContactPhoneNumber: '',
      companyContactEmail: '',
      companyIsInvoiceable: '',
      carrier: '',
      address: ''
  },
  validators: {
      companyName: [VALIDATE_REQUIRED],
      companyMailingAddress: [VALIDATE_REQUIRED],
      companyBillingAddress: [],
      companyPhoneNumber: [VALIDATE_PHONE_NUMBER],
      companyEmail: [VALIDATE_REQUIRED, VALIDATE_EMAIL],
      companyType: [VALIDATE_REQUIRED],
      companyContactName: [],
      companyContactPhoneNumber: [VALIDATE_PHONE_NUMBER],
      companyContactEmail: [VALIDATE_EMAIL],
      companyIsInvoiceable: [],
      carrier: [VALIDATE_REQUIRED],
      address: [VALIDATE_ADDRESS]

  }
}

class CompanyPopUpView extends FListDetailView {

      constructor(props) {
        PROPS['data']['companyCode'] = props.companyCode || CONST.CompanyCode.SERVICE_PROVIDER
        super(props, PROPS)
      }


    componentDidMount = () => {};

    fetchListData = () => {};

    notifyDialogClose = () => {
        this.props.notifyDialogClose();
        this.resetErrorMessage();
      };
    
    onEdit = (rowData = null) => {
        if (rowData === null) {
          this.isNew = true;
          rowData = {};
        }
        else {
          this.isNew = false;
        }
        this.setState({ data: rowData });
        this.setState({ editMode: true });
      };

    updateFormData = (formData) => {
        formData.set('carrier',  this.props.carrier.id)
        return formData
     }

     resetErrorMessage = () => {
        this.setState(PROPS)
      };
    
    getErrorData = () => {
        let errorData = {
            companyName: !isValid(this.state.data.companyName, this.state.validators.companyName),
            companyPhoneNumber: !isValid(this.state.data.companyPhoneNumber, this.state.validators.companyPhoneNumber),
            companyType: !isValid(this.state.data.companyType, this.state.validators.companyType),
            companyEmail:  !isValid(this.state.data.companyEmail, this.state.validators.companyEmail),
            
        }
        return errorData
    }

    
    
    hasCustomErrors = () => {
        return !(this.isAddressValid(false, true, true, false))
     }
   
  render() {
    const title = this.props.title || 'Contacts'
    const { classes } = this.props;
    return (
          <FDialog
            maxWidth='xl'
            fullWidth={false}
            title={title}
            owner={this.props.owner}
            openDialog={this.props.dialogEditMode}
            notifyDialogClose={this.notifyDialogClose}>
              
                <Grid container direction='column'  >
                    
           
                            <Grid item xs>
                                <FSelect
                                    name='companyType' required
                                    value={this.state.data.companyType}
                                    hasError={this.state.errorData.companyType}
                                    helpMessage={this.state.errorMessage.companyType}
                                    label='Contact Type *'
                                    data={this.props.choices}
                                    
                                    valueRef='prettyName'
                                    keyRef='id'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='companyName' required
                                    value={this.state.data.companyName}
                                    hasError={this.state.errorData.companyName}
                                    helpMessage={this.state.errorMessage.companyName}
                                    label="Name"
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                        
                           
                    
                            <Grid item xs>
                                <FInput name='companyEmail' required
                                    value={this.state.data.companyEmail}
                                    hasError={this.state.errorData.companyEmail}
                                    helpMessage={this.state.errorMessage.companyEmail}
                                    label="Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='companyPhoneNumber' required
                                    value={this.state.data.companyPhoneNumber}
                                    hasError={this.state.errorData.companyPhoneNumber}
                                    helpMessage={this.state.errorMessage.companyPhoneNumber}
                                    label="Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>
                    
                       <Grid item xs>
                                <FAddress
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        place={false}
                                        city={true}
                                        state={true}
                                        zipCode={false}
                                />
                        </Grid>
                    
                            <Grid item xs>
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                       
                    <Grid container alignItems='stretch' spacing={2} >
                        <Grid item xs={3}>
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                        </Grid>
                        <Grid item  xs={3}>
                            <FButton 
                                label={'Cancel'}
                                onClick={this.notifyDialogClose}
                                color='secondary'
                            />
                        </Grid>
                    </Grid>
                </Grid>
            
          </FDialog>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(CompanyPopUpView))
