import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, CircularProgress, Grid } from '@material-ui/core';
import MaterialTable, { MTableToolbar, MaterialTableProps } from 'material-table';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux";
import FMessage from './FMessage';
import { TablePagination, TablePaginationProps } from '@material-ui/core';

// Patch for pagination material table
function PatchedPagination(props: TablePaginationProps) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    width: '100%',
    borderTopWidth: '1px',
    borderColor: 'red',
    borderStyle: 'solid',
    backgroundColor: 'red', // Test background color
    fontFamily: 'Arial, sans-serif', // Global font family
    fontSize: '14px', // Global font size
  },
});

class FMaterialTableNew extends Component {
  getErrorMessage = () => {
    if (this.props.listErrCode === 403) {
      return "You don't have enough permission!";
    } else {
      return "No records to display";
    }
  }

  render() {
    const { classes, columns, data, actions, title, theme } = this.props;
    let options = { ...this.props.options };

    // Custom styles for table rows and header
    options.sorting = false;
    options.padding = 'dense';

    // Apply global font size
    const fontSize = `${theme.spacing(2.2) / 17}rem`;  // Convert spacing to rem

    // Define rowStyle using theme.spacing and valid CSS units
    options.rowStyle = (rowData, index) => ({
      height: `${theme.spacing(2)}px`, // 2 * 8 = 16px row height
      fontSize,  // Apply globally set font size here
      backgroundColor: index % 2 === 0 ? '#fff' : '#f5f5f5', // Alternating row colors
      fontFamily: 'Arial, sans-serif', // Consistent font family
    });

    // Define headerStyle using theme.spacing and valid CSS units
    options.headerStyle = {
      backgroundColor: '#d1e3f0', // Header background color
      color: '#003366',            // Header font color
      fontSize: `${theme.spacing(2) / 14}rem`, // 24px converted to rem
      padding: `${theme.spacing(1.2) / 14}rem`,  // Adjust padding (16px converted to rem)
      fontWeight: 'bold',
      fontFamily: 'Arial, sans-serif', // Consistent font family
    };

    const modifiedColumns = columns.map((column, index) => ({
      ...column,
      cellStyle: {
        padding: '4px 8px',
        textAlign: index === columns.length - 1 ? 'right' : 'left', // Align last column to the right
        marginRight: index === columns.length - 1 ? '8px' : '0', // Add margin to the right of the last column
      },
      headerStyle: {
        ...column.headerStyle,
        padding: '4px 8px',
        textAlign: index === columns.length - 1 ? 'right' : 'left', // Align header of last column to the right
        marginRight: index === columns.length - 1 ? '8px' : '0', // Add margin to the right of the last column
      },
    }));
    

    return (
      <div className="form-group">
        <MaterialTable
          className={classes.root} // Apply root styles
          tableRef={this.props.tableRef}
          columns={modifiedColumns}
          actions={actions}
          options={options}
          data={data}
          title={this.props.showTitle ? title : ''}
          isLoading={this.props.isLoading}
          components={{
            Toolbar: props => (
              <div style={{ backgroundColor: '#d1e3f0', fontFamily: 'Arial, sans-serif' }}>
                <MTableToolbar {...props} />
              </div>
            ),
            Pagination: PatchedPagination, // Ensure PatchedPagination is correctly implemented
          }}
          localization={{
            body: {
              emptyDataSourceMessage: this.getErrorMessage(),
            }
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FMaterialTableNew);