import React, {useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

// import Dashboard from '../dashboard'
import ComplianceDashboard from '../dashboard/ComplianceDashboard';
import FinancialDashboard from '../dashboard/FinancialDashboard';
import Carriers from '../carrier/'
import Integrations from '../integration/'
import CarrierDetail from '../carrier/CarrierDetail';
import Vehicles from '../vehicle/Vehicles';
import VehicleDetail from '../vehicle/VehicleDetail';
import Dispatchers from '../dispatcher/Dispatchers';
import DispatcherDetail from '../dispatcher/DispatcherDetail';
import Fleets from '../fleet/Fleets';
import FleetDetail from '../fleet/FleetDetail';
import Customers from '../customer/Customers';
import CustomerDetail from '../customer/CustomerDetail';
import Expenses from '../expense/Expenses';
import RecExpenses from '../expense/RecExpense';

import ExpenseDetail from '../expense/ExpenseDetail';
import RecExpenseDetail from '../expense/RecExpenseDetail';

import SignUp from '../auth/SignUp';
import ProfileView from '../profile'
import PayChecks from '../paycheck/PayChecks';
import PaycheckDetail from '../paycheck/PayCheckDetail';
import Invoices from '../invoice/Invoices';

import SignIn from '../auth/SignIn';
import { getAuthProfile, isAdminProfile } from '../../util/FAPI';
import { hasPermission } from '../../util/ACL';
import AccidentRegisters from '../accident_register/AccidentRegisters';
import AccidentRegisterDetail from '../accident_register/AccidentRegisterDetail';


import VehicleInspections from '../vehicle_inspection/VehicleInspections';
import VehicleInspectionDetail from '../vehicle_inspection/VehicleInspectionDetail';

import VehicleMaintenances from '../vehicle_maintenance/VehicleMaintenances';
import VehicleMaintenancDetailDetail from '../vehicle_maintenance/VehicleMaintenanceDetail';
import VehicleMaintenannceSchedule from '../maintenance_schedule/MaintenanceSchedule'
import VehicleMaintenanceScheduleDetail from '../maintenance_schedule/MaintenanceScheduleDetail'

import TrafficViolations from '../traffic_violation/TrafficViolations';
import TrafficViolationDetail from '../traffic_violation/TrafficViolationDetail';
import DrugTests from '../drug_test/DrugTests';
import DrugTestDetail from '../drug_test/DrugTestDetail';
import Contacts from '../contact/Contacts';
import FormerEmployerDetail from '../contact/ContactDetail';
// import EmploymentHistories from '../employment_history/EmploymentHistories';
// import EmploymentHistoryDetail from '../employment_history/EmploymentHistoryDetail';

// import ResidentHistories from '../resident_history/ResidentHistories';
// import ResidentHistoryDetail from '../resident_history/ResidentHistoryDetail';


import * as CONST from "../../util/Const";
import FleetReport from '../reports/fleets';
import DriverReport from '../reports/drivers';
import VehicleReport from '../reports/vehicles';
import ExpenseReport from '../reports/expenses';
import MaintenanceRecordReport from '../reports/maintenance_record';
import MaintenanceScheduleReport from '../reports/maintenance_schedule';
import DocumentReport from '../reports/documents';
import InvoiceReport from '../reports/invoices'

import ExpiringDocuments from '../reports/compliance/ExpiringDocuments';
import MissingDocuments from '../reports/compliance/MissingDocuments';
import ExpiredDocuments from '../reports/compliance/ExpiredDocuments';



import FuelCards from '../fuel_card/FuelCards';
import FuelCardDetail from '../fuel_card/FuelCardDetail';
import Trips from '../trip/Trips';
import TripDetail from '../trip/TripDetail';
import FuelImports from '../fuel_import/FuelImports';
import FuelImportDetail from '../fuel_import/FuelImportDetail';
import MileageImports from '../mileage_import/MileageImports';
import MileageImportDetail from '../mileage_import/MileageImportDetail';
import ifta_report from '../reports/ifta_report';
import driver_disciplinary_report from '../reports/driver_disciplinary_event';

import DriverDisciplinaryEvents from '../driver_disciplinary_event/DriverDisciplinaryEvents';
import DriverDisciplinaryEventDetail from '../driver_disciplinary_event/DriverDisciplinaryEventDetail';

import Users from '../user/Users';
import UserDetail from '../user/UserDetail';
import AccountPayment from '../profile/AccountPayment';
import DriverProfiles from '../driver_profile/DriverProfiles';
import DriverProfileDetail from '../driver_profile/DriverProfileDetail';
import DriverEmploymentApplicatoins from '../driver_employment_application/DriverEmploymentApplicatoins';
import DriverAppUploads from '../driver_upload_document/DriverAppUploads';
import DriverDetail from '../driver_profile/DriverDetail';

import ReactGA from 'react-ga4';
import MaintenanceMode from './MaintenanceMode';
import ForgotCredential from '../auth/ForgotCredential';
import Help from '../help';
import SignUpNew from '../auth/SignUpNew';



ReactGA.initialize(CONST.GOOGLE_ANYTICS_TRACKING_ID, {
  debug: CONST.IS_DEBUG_MODE,
  gaOptions: {
    userId: 'xxx-xxx-xxx-xxx'
  }
});


export const FRouteExt = () => (
  <main>
    <Switch>
      <Route exact path='/' component={SignIn} />
      <Route exact path='/signin' component={SignIn} />
      <Route exact path='/signup_carrier_owner' component={SignUpNew} />
      <Route exact path='/signupm' component={SignUpNew} />
      <Route exact path='/maintenancemode' component={MaintenanceMode} />
      <Route exact path='/forgotcred' component={ForgotCredential} />

      <Route component={SignIn} />
    </Switch>
  </main>
)
const FRoute = () =>{

  useEffect(() => {
    try{
      let pathName = window.location && window.location.pathname
      let search = window.location.search && window.location.search || ""
      let profileData =  getAuthProfile() || {}
      let profileID = !!profileData && profileData.profileID || 'UNKNOWN'
      ReactGA.set({ page: pathName });
      ReactGA.set({ userId: profileID} );
      ReactGA.send(pathName + search);
    } catch (e) {
     console.log('Routing Error')
    }
   
  }, []);

  return (
    <main>
      <Switch>

        {hasPermission(CONST.ROUTE_OVERVIEW_DASHBOARD_URL) &&<Route exact path={CONST.ROUTE_OVERVIEW_DASHBOARD_URL} component={ComplianceDashboard} />}
        {hasPermission(CONST.ROUTE_FINANCIAL_DASHBOARD_URL) &&<Route exact path={CONST.ROUTE_FINANCIAL_DASHBOARD_URL} component={FinancialDashboard} />}

        {hasPermission(CONST.ROUTE_DRIVER_REPORT_URL) && <Route exact path={CONST.ROUTE_DRIVER_REPORT_URL} component={DriverReport} />}
        {hasPermission(CONST.ROUTE_VEHICLE_REPORT_URL) &&<Route exact path={CONST.ROUTE_VEHICLE_REPORT_URL} component={VehicleReport} />}

        
        {hasPermission(CONST.ROUTE_FLEET_REPORT_URL) &&<Route exact path={CONST.ROUTE_FLEET_REPORT_URL} component={FleetReport} />}
        {hasPermission(CONST.ROUTE_EXPENSE_REPORT_URL) &&<Route exact path={CONST.ROUTE_EXPENSE_REPORT_URL} component={ExpenseReport} />}
        {hasPermission(CONST.ROUTE_MAINTENANCE_RECORD_REPORT_URL) &&<Route exact path={CONST.ROUTE_MAINTENANCE_RECORD_REPORT_URL} component={MaintenanceRecordReport} />}
        {hasPermission(CONST.ROUTE_MAINTENANCE_SCHEDULE_REPORT_URL) &&<Route exact path={CONST.ROUTE_MAINTENANCE_SCHEDULE_REPORT_URL} component={MaintenanceScheduleReport} />}

        {hasPermission(CONST.ROUTE_DOCUMENT_REPORT_URL) &&<Route exact path={CONST.ROUTE_DOCUMENT_REPORT_URL} component={DocumentReport} />}
        {hasPermission(CONST.ROUTE_INVOICE_REPORT_URL) &&<Route exact path={CONST.ROUTE_INVOICE_REPORT_URL} component={InvoiceReport} />}
        {hasPermission(CONST.ROUTE_IFTA_SUMMARY_REPORT_URL) &&<Route exact path={CONST.ROUTE_IFTA_SUMMARY_REPORT_URL} component={ifta_report} />}
      
        {hasPermission(CONST.ROUTER_EXPIRING_URL) &&<Route exact path={CONST.ROUTER_EXPIRING_URL} component={ExpiringDocuments} />}

        {/* {hasPermission(CONST.ROUTER_EXPIRING_URL) &&<Route exact path={CONST.ROUTER_EXPIRING_URL} component={ExpiringDocuments} />} */}
        {hasPermission(CONST.ROUTER_EXPIRED_URL) &&<Route exact path={CONST.ROUTER_EXPIRED_URL} component={ExpiredDocuments} />}

        {hasPermission(CONST.ROUTER_MISSING_DOCUMENTS_URL) &&<Route exact path={CONST.ROUTER_MISSING_DOCUMENTS_URL} component={MissingDocuments} />}

        {hasPermission(CONST.ROUTE_INTEGRATION_URL) && <Route exact path={CONST.ROUTE_INTEGRATION_URL} component={Integrations} /> }

        {hasPermission(CONST.ROUTE_CARRIERS_URL) && <Route exact path={CONST.ROUTE_CARRIERS_URL} component={Carriers} /> }
        {hasPermission(CONST.ROUTE_CARRIERS_URL) && <Route exact path={`${CONST.ROUTE_CARRIERS_URL}:id?`} component={CarrierDetail} />}

      
        {<Route exact path={CONST.ROUTE_CUSTOMER_URL}  component={Customers} />}
        { <Route exact path={`${CONST.ROUTE_CUSTOMER_URL}:id?`} component={CustomerDetail} />}

        {hasPermission(CONST.ROUTE_VEHICLE_URL) && <Route exact path={CONST.ROUTE_VEHICLE_URL} component={Vehicles} />}
        {hasPermission(CONST.ROUTE_VEHICLE_URL) && <Route exact path={`${CONST.ROUTE_VEHICLE_URL}:id?`} component={VehicleDetail} />}


        {hasPermission(CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL) && <Route exact path={CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_URL} component={DriverProfiles} />}
        {/* {hasPermission(CONST.ROUTE_DRIVER_URL) && <Route exact path={CONST.ROUTE_DRIVER_URL} component={Drivers} />} */}
        {hasPermission(CONST.ROUTE_DRIVER_URL) && <Route exact  path={`${CONST.ROUTE_DRIVER_URL}:id?`} component={DriverDetail} />}
        {hasPermission(CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL) && <Route exact  path={`${CONST.ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL}:id?`} component={DriverProfileDetail} />}

        {hasPermission(CONST.ROUTE_FUEL_CARD_URL) && <Route exact path={CONST.ROUTE_FUEL_CARD_URL} component={FuelCards} />}
        {hasPermission(CONST.ROUTE_FUEL_CARD_URL) && <Route exact  path={`${CONST.ROUTE_FUEL_CARD_URL}:id?`} component={FuelCardDetail} />}
      
        {hasPermission(CONST.ROUTE_TRIP_URL) && <Route exact path={CONST.ROUTE_TRIP_URL} component={Trips} />}
        {hasPermission(CONST.ROUTE_TRIP_URL) && <Route exact  path={`${CONST.ROUTE_TRIP_URL}:id?`} component={TripDetail} />}


        {hasPermission(CONST.ROUTE_DISPATCHER_URL) && <Route exact path={CONST.ROUTE_DISPATCHER_URL} component={Dispatchers} />}
        {hasPermission(CONST.ROUTE_DISPATCHER_URL) && <Route exact path={`${CONST.ROUTE_DISPATCHER_URL}:id?`} component={DispatcherDetail} />}

        { <Route exact path={CONST.ROUTE_FLEET_URL} component={Fleets} />}
        { <Route exact  path={`${CONST.ROUTE_FLEET_URL}:id?`} component={FleetDetail} />}

        { <Route exact  path={CONST.ROUTE_EXPENSE_URL} component={Expenses} />}
        {<Route exact path={`${CONST.ROUTE_EXPENSE_URL}:id?`} component={ExpenseDetail} />}

        { <Route exact  path={CONST.ROUTE_REC_EXPENSE_URL} component={RecExpenses} />}
        {<Route exact path={`${CONST.ROUTE_REC_EXPENSE_URL}:id?`} component={RecExpenseDetail} />}

        {<Route exact  path={CONST.ROUTE_FUEL_IMPORT_URL} component={FuelImports} />}
        {<Route exact path={`${CONST.ROUTE_FUEL_IMPORT_URL}:id?`} component={FuelImportDetail} />}

        {<Route exact  path={CONST.ROUTE_MILEAGE_IMPORT_URL} component={MileageImports} />}
        {<Route exact path={`${CONST.ROUTE_MILEAGE_IMPORT_URL}:id?`} component={MileageImportDetail} />}


        {hasPermission(CONST.ROUTE_PAY_CHECK_URL) && <Route exact path={CONST.ROUTE_PAY_CHECK_URL}  component={PayChecks} />}
        {hasPermission(CONST.ROUTE_PAY_CHECK_URL) && <Route exact  path={`${CONST.ROUTE_PAY_CHECK_URL}:add`} component={PaycheckDetail} />}

        {hasPermission(CONST.ROUTE_INVOICE_URL) && <Route exact path={CONST.ROUTE_INVOICE_URL} component={Invoices} />}

        {hasPermission(CONST.ROUTE_COMPANY_URL) && <Route exact  path={CONST.ROUTE_COMPANY_URL} component={Contacts} />}
        {hasPermission(CONST.ROUTE_COMPANY_URL) && <Route exact path={`${CONST.ROUTE_COMPANY_URL}:id?`} component={FormerEmployerDetail} />}
        
        {/*       
        {hasPermission(CONST.ROUTE_COMPANY_URL) && <Route exact path={CONST.ROUTE_COMPANY_URL}  component={Companies} />}
        {hasPermission(CONST.ROUTE_COMPANY_URL) && <Route exact path={`${CONST.ROUTE_COMPANY_URL}:id?`} component={CompanyDetail} />}
        */}

      
        {/* {hasPermission(CONST.ROUTE_EMPLOYEMENT_HISTORY_URL) && <Route exact path={CONST.ROUTE_EMPLOYEMENT_HISTORY_URL} component={EmploymentHistories} />}
        {hasPermission(CONST.ROUTE_EMPLOYEMENT_HISTORY_URL) && <Route exact path={`${CONST.ROUTE_EMPLOYEMENT_HISTORY_URL}:id?`} component={EmploymentHistoryDetail} />}
        

        {hasPermission(CONST.ROUTE_RESIDENT_HISTORY_URL) && <Route exact path={CONST.ROUTE_RESIDENT_HISTORY_URL} component={ResidentHistories} />}
        {hasPermission(CONST.ROUTE_RESIDENT_HISTORY_URL) && <Route exact path={`${CONST.ROUTE_RESIDENT_HISTORY_URL}:id?`} component={ResidentHistoryDetail} />}
         */}

        {hasPermission(CONST.ROUTE_ACCIDENT_REGISTER_URL) && <Route exact path={CONST.ROUTE_ACCIDENT_REGISTER_URL} component={AccidentRegisters} />}
        {hasPermission(CONST.ROUTE_ACCIDENT_REGISTER_URL) && <Route exact path={`${CONST.ROUTE_ACCIDENT_REGISTER_URL}:id?`}  component={AccidentRegisterDetail} />}
        
        {hasPermission(CONST.ROUTE_VEHICLE_INSPECTION_URL) && <Route exact path={CONST.ROUTE_VEHICLE_INSPECTION_URL} component={VehicleInspections} />}
        {hasPermission(CONST.ROUTE_VEHICLE_INSPECTION_URL) && <Route exact path={`${CONST.ROUTE_VEHICLE_INSPECTION_URL}:id?`} component={VehicleInspectionDetail} />}
        
        {hasPermission(CONST.ROUTE_MAINTENANCE_SCHEDULE_URL) && <Route exact path={CONST.ROUTE_MAINTENANCE_SCHEDULE_URL} component={VehicleMaintenannceSchedule} />}
        {hasPermission(CONST.ROUTE_MAINTENANCE_SCHEDULE_URL) && <Route exact path={`${CONST.ROUTE_MAINTENANCE_SCHEDULE_URL}:id?`} component={VehicleMaintenanceScheduleDetail} />}
        {hasPermission(CONST.ROUTE_VEHICLE_MAINTENANCE_URL) && <Route exact path={CONST.ROUTE_VEHICLE_MAINTENANCE_URL} component={VehicleMaintenances} />}
        {hasPermission(CONST.ROUTE_VEHICLE_MAINTENANCE_URL) && <Route exact path={`${CONST.ROUTE_VEHICLE_MAINTENANCE_URL}:id?`} component={VehicleMaintenancDetailDetail} />}
        

        {hasPermission(CONST.ROUTE_TRAFFIC_VIOLATION_URL) && <Route exact path={CONST.ROUTE_TRAFFIC_VIOLATION_URL} component={TrafficViolations} />}
        {hasPermission(CONST.ROUTE_TRAFFIC_VIOLATION_URL) && <Route exact path={`${CONST.ROUTE_TRAFFIC_VIOLATION_URL}:id?`} component={TrafficViolationDetail} />}
        
        {hasPermission(CONST.ROUTE_DRUG_TEST_URL) && <Route exact path={CONST.ROUTE_DRUG_TEST_URL} component={DrugTests} />}
        {hasPermission(CONST.ROUTE_DRUG_TEST_URL) && <Route exact path={`${CONST.ROUTE_DRUG_TEST_URL}:id?`} component={DrugTestDetail} />}
      
        {hasPermission(CONST.ROUTE_DRIVER_DISCIPLINARY_EVENT_URL) && <Route exact path={CONST.ROUTE_DRIVER_DISCIPLINARY_EVENT_URL} component={DriverDisciplinaryEvents} />}
        {hasPermission(CONST.ROUTE_DRIVER_DISCIPLINARY_EVENT_URL) && <Route exact path={`${CONST.ROUTE_DRIVER_DISCIPLINARY_EVENT_URL}:id?`} component={DriverDisciplinaryEventDetail} />}
        {hasPermission(CONST.ROUTER_DRIVER_DISCIPLINARY_EVENT_REPORT_URL) &&<Route exact path={CONST.ROUTER_DRIVER_DISCIPLINARY_EVENT_REPORT_URL} component={driver_disciplinary_report} />}

        
        {hasPermission(CONST.ROUTE_USERS_URL) && <Route exact path={CONST.ROUTE_USERS_URL} component={Users} />}
        {hasPermission(CONST.ROUTE_USERS_URL) && <Route exact  path={`${CONST.ROUTE_USERS_URL}:id?`} component={UserDetail} />}

        {/* This doesn't have application detail */}
        {hasPermission(CONST.ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL) && <Route exact path={CONST.ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL} component={DriverEmploymentApplicatoins} />}
        

        {hasPermission(CONST.ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL) && <Route exact path={CONST.ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL} component={DriverAppUploads} />}
        {hasPermission(CONST.ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL) && <Route exact  path={`${CONST.ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL}:id?`} component={DriverAppUploads} />}
        



        {/* { <Route exact path={CONST.ROUTE_MY_ACCOUNT_URL} component={ProfileView} />} */}
        
        { <Route exact path={CONST.ROUTE_PAYMENT_URL} component={AccountPayment} />}
        { <Route exact path={`${CONST.ROUTE_MY_ACCOUNT_URL}:id?`} component={ProfileView} />}
        { <Route exact path={CONST.ROUTE_HELP_URL} component={Help} />}
        {/* {!isAdminProfile() && <Redirect to={CONST.ROUTE_FLEET_URL}/> } */}
        {/* Any Personnel / Account is directed to the dashboard. */}
        {!isAdminProfile() && <Redirect to={CONST.ROUTE_DASHBOARD_URL}/>} 
        {isAdminProfile() && <Redirect to={CONST.ROUTE_DASHBOARD_URL}/>}
      </Switch>
    </main>
  )
      }

export default FRoute
