
export const getPrettyDate = function (d) {
  try {
    return (d.slice(0, 10))
  } catch (err) {
    return ''
  }
}

export const getPrettyID = function (d, len) {
  try {
    return (d.slice(d.length -len, d.lenth))
  } catch (err) {
    return d
  }
}

export const roundDecimal = function (val, fixed=2) {
    try {
      if (isNaN(val)) return 0.00;
      return val.toFixed(fixed)
    } catch (err) {
      return 0.00
    }
}


export const getRand = function () {
    let d = new Date()
    d = `${d.getMonth()}${d.getMilliseconds()}`
    let  value = Math.random().toString().substr(3, 5)
    return `${d}${value}`   
}


export const isPaymentPastDue = function(paymentDate) {
   let d = Date(paymentDate)
   let today = new Date(); 
  
   return (d <= today)
}


export const isFormDataNullOrEmptpy = function(val) {
   return (val === undefined || val === 'undefined' || val == null || val === 'null' || val.length <= 0) ? true : false;
}


export const toFloat = function(val, rnd=2) {
  let parsed =  parseFloat(val)
  return parsed
  // val =  parsed.toPrecision(rnd)
  // return parseFloat(val)
}


export const roundDisplay = function(val, rnd=3) {
  val =  val.toFixed(rnd)
  return val
}


export const getFloor = function(val) {
  return Math.floor(toFloat(val))
}



export const validateIsValidInterval = (dateFrom, dateTo) => {
  return (new Date(dateFrom) <= new Date(dateTo))
}

export const getNextDateFrom = function(numberOfDays, startDate=null) {
  try {
    if (startDate == null) {
      startDate = new Date()
    }else{
      startDate = new Date(Date.parse(startDate))
    }
    startDate.setDate(startDate.getDate() + numberOfDays); 
    return startDate.toString()  

  } catch (err) {
    return new Date().toString()
  }
  
}


export const getDate = (myDate, numDays=0) => {
  if (!!!myDate){
    myDate = new Date()
  }
  let dateOffset = (24*60*60*1000) * numDays; //num days
  myDate.setTime(myDate.getTime() - dateOffset);
  

  return Date.parse(myDate)
}

export const getValueFromListOfDict = (lDict, keyValue, keyid='id', labelValue='prettyName') => {
  for (var i in lDict) {
    let dict = lDict[i]
    if (dict[keyid] == keyValue) {
      return dict[labelValue]
    }
  }
}
export const toJson = (str) => {
  if (typeof str === 'object' && str !== null) {
    return str;  // Directly return the object or dictionary
  }
  if (typeof str === 'string' || str instanceof String) {
    try {
      return JSON.parse(str);
    } catch (error) {
      console.warn("Invalid JSON format, attempting to fix single quotes...");
      try {
        const fixedStr = str.replace(/'/g, '"'); // Replace all single quotes with double quotes
        return JSON.parse(fixedStr);
      } catch (fixError) {
        console.error("Failed to fix JSON format:", fixError);
        return {}; 
      }
    }
  }
  return {};
};


export const getPrettyAddress = (str) => {
  try {
    if (typeof str === 'string' || str instanceof String) {
      str =  JSON.parse(str) 
    }
  
     return `${str.place}, ${str.city}, ${str.state} ${str.zipCode}`;
  }
  catch (err) {
    return ''
  }
}

export const  getLocalDateTime  = (invoicedDate) => {
  if (!invoicedDate) {
    return '';
  }
  const date = new Date(invoicedDate);
  return isNaN(date) ? '' : date.toLocaleString();
}

