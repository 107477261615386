import React, { Component } from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import {Link, Typography } from '@mui/material';
import { green } from '@material-ui/core/colors';

class FAttachment extends Component {


  render() {
    return (
      <React.Fragment>
           {this.props.label}
           { this.props.url && <Link href={this.props.url} target="_blank">
              <AttachmentIcon style={{ fontSize: 24, paddingLeft: '2px', 
              color: green[500],
               }}/>
          </Link>}

    </React.Fragment>
    )
  }
}

export const FAttachmentAlert = ({ label, labelLink, attachmentLink, color = '#4caf50' }) => {
  const widgetStyles = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: color, 
    color: 'black', 
    padding: '12px',
    borderRadius: '4px', 
    fontSize: '14px', 
    marginRight: '12px',
    minWidth: '170px', 
    minHeight: '55px',


  };

  const iconStyles = {
    // fontSize: 30,
    color: 'red',
    marginRight: '12px', // Add spacing between the icon and text
  };

  const linkStyles = {
    textDecoration: 'underline',
    color: '#black',
  };

  return (
    <span style={widgetStyles}>
      {/* Only show the icon if there's an attachmentLink */}
      {attachmentLink && (
        <Link href={attachmentLink} target="_blank" style={{ color: '#fff' }}>
          <AttachmentIcon style={iconStyles} />
        </Link>
      )}

      {/* Show the label, optionally linking it */}
      {label && (
        <Typography variant="body2" component="span">
          {labelLink ? (
            <Link href={labelLink} style={linkStyles}>
              {label}
            </Link>
          ) : (
            label
          )}
        </Typography>
      )}
    </span>
  );
};

export default FAttachment
