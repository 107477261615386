import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const StyledMenu = withStyles({
  paper: { border: '1px solid #d3d4d5' },
})(Menu);

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'black',
      },
    },
  },
}))(MenuItem);

const actionIcons = {
  "ACTION_INVOICE_MARK_INVOICED": <MailOutlineIcon fontSize="small" />,
  "ACTION_INVOICE_MARK_FULLY_PAID": <CheckCircleIcon fontSize="small" />,
  "ACTION_INVOICE_MARK_CANCELED": <CancelIcon fontSize="small" />,
  "ACTION_DELETE": <DeleteIcon fontSize="small" />,
  "REQUEST_INVOICE_TO_TRIUMPH_BUSINESS_CAPITAL": <SendIcon fontSize="small" />,
  "REQUEST_INVOICE_TO_APEX": <SendIcon fontSize="small" />
};

export default function FTableActionNew({ actions, onTableAction, rowData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onActionClick = (event, actionId) => {
    setAnchorEl(null);
    onTableAction(actionId, rowData);
  };

  return (
    <div>
      {actions.length > 0 ? (
        <Button aria-controls="action-menu" aria-haspopup="true" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </Button>
      ) : (
        <MoreVertIcon color="disabled" titleAccess="No Actions Available" />
      )}

      <StyledMenu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map(action => (
          <StyledMenuItem key={action.id} onClick={event => onActionClick(event, action.id)}>
            <ListItemIcon>{actionIcons[action.id] || <MoreVertIcon fontSize="small" />}</ListItemIcon>
            <ListItemText primary={action.label} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
