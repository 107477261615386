import React from 'react';
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import logo from '../images/logo_zt_square.png';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
},

logo: {
    width: 80,
    height: 80,
    alignSelf: 'flex-center',
    marginBottom: theme.spacing(2),
},
  wrapper: {
    position: 'relative',
    minHeight: '100vh',
  },
  logoContainer: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
  },
  topPadding: {
      paddingTop: theme.spacing(8),
  },
 
  header1: {
    margin: theme.spacing(1),
    fontSize: '34px',
    paddingBottom: 10,
    textAlign: 'center',
    color: '#0077CC',
    fontWeight: '600',
},
header2: {
  margin: theme.spacing(1),
  fontSize: '18px',
  textAlign: 'center',
  paddingBottom: 10,
  letterSpacing: 2,
  color: '#005599', 
  fontWeight: '500',
},
  videoContainer: {
    position: 'relative',
    paddingBottom: '42.25%',
    height: 0,
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  video: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '80%',
  },
  videoTitle: {
    textAlign: 'center',
    margin: '15px 0',
    fontSize: '18px',
    color: '#0055AA',
    fontWeight: 'bold',
},
});

class NotSupportedBrowserSize extends React.Component {
    videos = [
        {
            url: "https://youtube.com/embed/FFBZzrSgvM8?rel=0",
            title: "UI-101: Understanding Menus, Submenus, and How to Use Your Interface"
        },
        {
            url: "https://youtube.com/embed/fi_wivgfp3U?rel=0",
            title: "Maximizing Your Experience: Navigating and Interacting with Our Platform's UI"
        },
        {
            url: "https://youtube.com/embed/BISSokb8iek?rel=0",
            title: "How to Use and Analyze Dashboards and Graphs in Ztrucking"
        },
        {
            url: "https://youtube.com/embed/mVgq_5Hp16E?rel=0",
            title: "How to Use Reporting Tools for Effective Asset and Record Management"
        },
        {
            url: "https://youtube.com/embed/6bqGVwkL_Q4?rel=0",
            title: "Streamline Your Data Entry with Our Importing Tools: How to Import Fuel Transactions"
        },
    ];

    render() {
      const { classes } = this.props;

      return (
          <div className={classes.container}>
              <img src={logo} alt="ZTrucking Logo" className={classes.logo} />

              <Grid container direction='column' spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                    <p className={classes.header1}>Welcome to ZTrucking!</p>
                    <p className={classes.header2}>For the best experience, we recommend using ZTrucking on a laptop or desktop. In the meantime, feel free to watch some tutorial videos to familiarize yourself with our platform.</p>
                </Grid>


                  {this.videos.map((video, index) => (
                      <Grid item xs={12} key={index}>
                          <p className={classes.videoTitle}>{video.title}</p>
                      <div className={classes.videoContainer}>
                          <iframe 
                              className={classes.video}
                              src={video.url}
                              title={video.title}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                          />
                      </div>
                      </Grid>
                  ))}
              </Grid>
              <div className={classes.footer}>
                <Typography variant="body2" align="center" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgba(0, 0, 0, 0.54)' }}>
                    &copy; Copyright <a href="https://www.ztrucking.com" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>ZTRUCKING GROUP CORP.</a> &nbsp;| &nbsp;
                    <a href="https://www.ztrucking.com/zlegals/UserAgreements.html" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Terms of Use</a>  &nbsp;|  &nbsp;
                    <a href="https://www.ztrucking.com/zlegals/PrivacyAndData.html" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Privacy Policy</a>
                </Typography>
            </div>
          </div>
      );
  }
}

export default withStyles(styles)(NotSupportedBrowserSize);
