import React, { Component } from 'react';

import { GET, UPDATE, DELETE, GET_RESULT, handleApiError } from './FAPI';
import { PROFILE_UPDATE_MODULES } from './Const';
import * as CONST from './Const';

import FMessage, { getSuccessMessage, getErrroMessage, showSuccessNotification, showErroNotification } from '../components/widgets/FMessage';
import { FRouterAddLink, FRouterLink } from '../components/widgets/FRouterLink';
import { has_create_permission, has_delete_permission, has_edit_permission, has_link_permission } from './ACL';
import { getCarrierAvatarUrl, getDispatcherAvatarUrl, getDriverAvatarUrl, getTrailerAvatarUrl, getTruckAvatarUrl, getVehicleAvatarUrl } from './icon_util';
import FToolTip from '../components/widgets/FTooltip';
import FAlert from '../components/widgets/FAlert';

export const StyledAssetLink = ({ children, theme, maxWidth = "15em" }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start', // Changed 'left' to 'flex-start' for valid value
      // height: theme.spacing(3.75), // Use spacing for height (30px)
      maxWidth: maxWidth,
      whiteSpace: 'nowrap', // Prevent text wrapping
      overflow: 'hidden',   // Hide overflow text
      textOverflow: 'ellipsis', // Add ellipsis for overflow text
    }}>
      {children}
    </div>
  );
};

export default class FListComponent extends React.Component {
  tableRef = React.createRef();

  constructor(props, url, module = null, queryParams = null) {
    super(props)
    this.state = {
      listData: [],
      currentRow: {},
      listUrl: url,
      updatedUrl: '',
      module: module,
      queryParams: queryParams || '',
      pageSize: CONST.getPageSize(),
      pageSizeOptions: CONST.getPageSizeOptions(),
      currentAction: null,
      isLoading: false,
      listErrCode: undefined,
      hideFutureExpenses: true,
      dialogEditMode: false,
      showImportEror: false

    }
    this.currentTitle = ''
    this.currentTab = ''
    this.routeUrl = undefined
    this.policyID = undefined

    this.includeStateAction = true
    this.includeInvoiceStatus = true
    this.includeDeleteAction = true
    this.includePaycheckExpensify = false


    // this.actions = [ACTION_TABLE_DELETE_ROW, ACTION_TABLE_ACTIVATE, 
    //   ACTION_TABLE_DEACTIVATE, ]

  }

  getPrettyDate = (d) => {
    try {
      return (d.slice(0, 10))
    } catch (err) {
      return d
    }
  }

  componentDidMount() {
    if (!!this.currentTitle) {
      this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.currentTitle)
    }
    if (!!this.currentTab) {
      this.props.updateCurrentTab(this.currentTab)
    }
  }

  fetchData = (query) => {
    return new Promise((resolve, reject) => {
      let url = ''
      url = this.getListURL() + '?'
      let page = query.page + 1
      if (this.state.currentAction === 'delete') {
        page = query.page == 0 ? 1 : query.page
        this.setState({ currentAction: 'get' })
      }

      let search = ''

      if (!!query.search) {
        search = `search=${query.search}&`
      }
      url += `${this.state.queryParams}${search}per_page=${this.state.pageSize}&page=${page}`
      const that = this
      GET(url)
        .then(response => {

          let page = parseInt(url.substr(url.lastIndexOf('=') + 1));
          const res = response.data;
          resolve({
            data: res.results,
            page: page - 1,
            totalCount: res.count,
          })
        }).catch(error => {
          let err = handleApiError(that.props, error)
          if (!!err.errCode) {
            this.setState({ listErrCode: err.errCode })
          }
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          })
        })
    })
  }



  fetchListData = () => {
    const that = this;
    //Depreciate if not used
    GET(this.getListURL()).then(res => {

      let data = GET_RESULT(res)
      let results = data || []
      this.setState({ listData: results })
    }).catch(error => {
      let err = handleApiError(that.props, error)
      if (!!err.errCode) {
        this.listErrCode = err.errCode
      }
      this.setState({ listData: [] })
    })
  }

  getFormData = (data) => {
    let fomrData = new FormData();
    for (var key in data) {
      fomrData.append(key, data[key]);
    }
    return fomrData;
  }

  // Handler when action menu is triggered from table
  onTableAction = (action, rowData) => {
    switch (action) {
      case CONST.ACTION_TABLE_DELETE_ROW:
        this.deleteRow(rowData)
        break
      case CONST.ACTION_TABLE_ACTIVATE:
        this.updateRow(rowData, { state: 'ACTIVE' })
        break;
      case CONST.ACTION_TABLE_DEACTIVATE:
        this.updateRow(rowData, { state: 'INACTIVE' })
        break;
      case (CONST.ACTION_TABLE_COMPLETE):
        this.updateRow(rowData, { status: 'COMPLETE' })
        break;
      case (CONST.ACTION_TABLE_INPROGRESS):
        this.updateRow(rowData, { status: 'IN_PROGRESS' })
        break;
      case (CONST.ACTION_TABLE_INVOICE_CREATED):
        this.updateRow(rowData, { invoiceStatus: CONST.INVOICE_STATE.CREATED })
        break;
      case (CONST.ACTION_TABLE_INVOICE_INVOICED):
        this.updateRow(rowData, { invoiceStatus: CONST.INVOICE_STATE.INVOICED })
        break;
      case (CONST.ACTION_TABLE_INVOICE_FULLY_PAID):
        this.updateRow(rowData, { invoiceStatus: CONST.INVOICE_STATE.PAID })
        break;
      case (CONST.ACTION_TABLE_INVOICE_CANCELED):
        this.updateRow(rowData, { invoiceStatus: CONST.INVOICE_STATE.CANCELED })
        break;

      case (CONST.ACTION_TABLE_EXPENSIFY_PAYCHECK):
        this.updateRow(rowData, { status: 'COMPLETE' })
        break;
    }
  }

  /**
   * Add button.
   */
  getAddNewModelActions = () => {
    let disabled = true
    if (!!!this.props || !!!this.props.profile || !!!this.routeUrl ||
      !has_create_permission(this.props.profile, this.policyID)) {
      disabled = true
    } else {
      disabled = false
    }

    return {
      icon: () => (
        <FRouterAddLink disabled={disabled} to={this.routeUrl + 'add'} color='secondary' />
      ),
      isFreeAction: true
    }
  }

  /**
   * Table Aciton Delete / Status / State change / Other to be included
   */
  getActions = (rowData, target, ...actions) => {
    let _actions = []

    if (!!this.includeInvoiceStatus && !!rowData.invoiceStatus) {
      _actions = _actions.concat(CONST.INVOICE_STATE_MACHINE[rowData.invoiceStatus])
      _actions.push(CONST.ACTION_TABLE_EMAIL_INVOICE)
    }
    // Delete / Deactivate
    if (!!this.includeDeleteAction && !!has_delete_permission(this.props.profile, target)) {
      _actions.push(CONST.ACTION_TABLE_DELETE_ROW)
    }

    if (!!this.includePaycheckExpensify &&
      // Permission is required for both paycheck and expense model.
      rowData.status == CONST.INVOICE_STATE.IN_PROGRESS_PAYCHECK &&
      !!has_edit_permission(this.props.profile, CONST.EXPENSE_EXPNESES) &&
      !!has_edit_permission(this.props.profile, target)) {
      _actions.push(CONST.ACTION_TABLE_EXPENSIFY_PAYCHECK)
    }

    if (!!this.includeStateAction && !!rowData.state && !!has_delete_permission(this.props.profile, target)) {
      _actions = _actions.concat(CONST.INVOICE_STATE_MACHINE[rowData.state])
    }

    // Any uncover state.
    for (var action of actions) {
      _actions.push(action)
    }
    return _actions
  }

  getDeleteURL = () => {
    return this.state.listUrl
  }

  getListURL = () => {
    return this.state.listUrl
  }

  deleteRow = (rowData) => {
    const that = this
    this.setState({ isLoading: true })
    DELETE(this.getDeleteURL() + rowData.id).then(response => {
      this.setState({ currentAction: 'delete' }, () => {
        this.setState({ isLoading: false })
        if (that.tableRef && that.tableRef.current) {
          that.tableRef.current.onQueryChange();
        }
      })
      showSuccessNotification(response, CONST.getModuleTitle(this.state.module, 'DELETE'))
    }).catch(err => {
      this.setState({ isLoading: false })
      showErroNotification(err, CONST.getModuleTitle(this.state.module, 'DELETE'))
      // that.props.updateServerError && that.props.updateServerError({ msg: getErrroMessage(err) })
    })
  }

  updateRow = (rowData, toUpdate = {}) => {
    let data = {
      ...{ id: rowData.id },
      ...toUpdate
    }
    const that = this
    this.setState({ isLoading: true })
    UPDATE(this.getListURL() + rowData.id, 'patch', this.getFormData(data)).then(res => {
      // TODO (Estifanos) This should only update the list entry
      // this.fetchListData();
      this.setState({ isLoading: false })
      this.setState({ currentAction: 'update' }, () => {
        that.tableRef.current.onQueryChange();
      })

      that.props.updateServerSuccess && that.props.updateServerSuccess({ msg: getSuccessMessage(res) })

    }).catch(err => {
      this.setState({ isLoading: false })
      that.props.updateServerError && that.props.updateServerError({ msg: getErrroMessage(err) })
    })
  }

  // Table Action
  actionHandler = (url, data) => {
    const that = this
    this.setState({ isLoading: true })
    UPDATE(url, 'patch', this.getFormData(data)).then(res => {
      this.setState({ isLoading: false })
      this.setState({ currentAction: 'update' }, () => {
        that.tableRef.current.onQueryChange();
      })
      showSuccessNotification(res, CONST.getModuleTitle(this.state.module, 'Request Action'))
      that.props.updateServerSuccess && that.props.updateServerSuccess({ msg: getSuccessMessage(res) })

    }).catch(err => {
      this.setState({ isLoading: false })
      showErroNotification(err, CONST.getModuleTitle(this.state.module, 'Request Action'))
      that.props.updateServerError && that.props.updateServerError({ msg: getErrroMessage(err) })
    })
  }

  updateProfile = () => {
    if (PROFILE_UPDATE_MODULES.includes(this.state.module)) {
      // TODO(Estifanos) On delete this is not updating profile detail which we are forced to 
      // fetch profile data either ways.
      this.props.updateParent({ updateProfileData: true })
    }
  }

  safeGet = (dict, key) => {
    try {
      return dict[key]
    } catch {
      return undefined
    }
  }
  getObjFromNestedDict(rowData, key, nestedKey = false) {
    if (!!!rowData) return ''
    let obj = this.safeGet(rowData, key) //rowData[key]
    if (!!obj) {
      return obj
    }
    if (!nestedKey in rowData) {
      return ''
    }
    obj = this.safeGet(rowData, nestedKey) //rowData[nestedKey][key]
    return obj && this.safeGet(obj, key)
  }


  getSelfCarrierLink = (rowData, row) => {
    return this.getCarrierLink(rowData, row, true)
  }

  getCarrierLink = (rowData, row, editIcon = false) => {

    if (!rowData.carrier && !(rowData.driverProfile && rowData.driverProfile.carrier) && !rowData.carrierDOT) {
      return '';
    }

    let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_CARRIERS);

    let carrier = rowData.carrier || rowData.driverProfile && rowData.driverProfile.carrier || rowData;


    return (
      <StyledAssetLink>
        {getCarrierAvatarUrl(carrier)}
        <React.Fragment>
          {hasPermission && <FRouterLink
            label={carrier.prettyName}
            editIcon={editIcon}
            disabled={!hasPermission}
            to={CONST.ROUTE_CARRIERS_URL + carrier.id}
          />}
          {!hasPermission && carrier.prettyName}
        </React.Fragment>
      </StyledAssetLink>
    );
  }


  getProfileDriverCarrierLink = (rowData, row, editIcon = false) => {
    if (!rowData.driver || !rowData.driver.carrier) {
      return '';
    }

    let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_CARRIERS);
    let carrier = rowData.driver.carrier;

    return (
      <StyledAssetLink>

        {getCarrierAvatarUrl(carrier)}
        <React.Fragment>
          {hasPermission && <FRouterLink
            label={carrier.prettyName}
            disabled={!hasPermission}
            editIcon={editIcon}
            to={CONST.ROUTE_CARRIERS_URL + carrier.id}
          />}
          {!hasPermission && carrier.prettyName}
        </React.Fragment>
      </StyledAssetLink>
    );
  }

  getSelfDriverLink = (rowData, row) => {
    return this.getDriverLink(rowData, row, true)
  }


  getDriverLink = (rowData, row, editIcon = false) => {
    {

      let driver = this.getObjFromNestedDict(rowData, 'driver', 'expenseRawData')
      if (!driver) return ''
      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_DRIVERS)

      // const imageUrl = getDriverAvatarUrl(driver)

      return (
        <StyledAssetLink>
          {getDriverAvatarUrl(driver)}
          <React.Fragment>
            {hasPermission &&
              <FRouterLink
                label={driver.prettyName}
                disabled={!hasPermission}
                editIcon={editIcon}
                to={CONST.ROUTE_DRIVER_URL + driver.id} />}
            {!hasPermission && driver.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }

  // TODO Merge to same method
  getProfileDriverLink = (rowData, row, editIcon = false) => {
    {
      if (!rowData.driver) {
        return ''
      }
      if (!rowData.driver.id) {
        return ''
      }
      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_DRIVERS)

      let driver = rowData.driver;
      // const imageUrl = getDriverAvatarUrl(driver)

      return (
        <StyledAssetLink>
          {getDriverAvatarUrl(driver)}
          <React.Fragment>
            {hasPermission && <FRouterLink
              label={rowData.driver.prettyName}
              disabled={!hasPermission}
              editIcon={editIcon}
              to={CONST.ROUTE_DRIVER_URL + rowData.driver.id} />}

            {!hasPermission && rowData.driver.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }
  getProfileDriverLinkWithEditLink = (rowData, row, editIcon = true) => {
    {
      if (!rowData.driver) {
        return ''
      }
      if (!rowData.driver.id && !rowData.driver.prettyName) {
        return ''
      }
      if (!rowData.driver.id && rowData.driver.prettyName) {
        return rowData.driver.prettyName
      }
      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_DRIVERS)

      let driver = rowData.driver;
      // const imageUrl = getDriverAvatarUrl(driver)
      return (
        <StyledAssetLink>
          {getDriverAvatarUrl(driver)}
          <React.Fragment>
            {hasPermission && <FRouterLink
              editIcon={editIcon}
              label={rowData.driver.prettyName}
              disabled={!hasPermission}
              to={CONST.ROUTE_DRIVER_URL + rowData.driver.id} />}

            {!hasPermission && rowData.driver.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }

  getDriverInfoEditLink = (rowData, row, editIcon = true) => {
    {
      if (!rowData.driverInfo) {
        return ''
      }

      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_DRIVERS)

      const driverInfo = rowData.driverInfo;
      const { contact, driver, state } = rowData.driverInfo;
      const { firstName, middleName, lastName, email, phoneNumber, address, username } = contact || {};
      const fullName = `${firstName} ${middleName ? middleName + " " : ""}${lastName}`;
      const formattedAddress = address?.place
        ? `${address.place}, ${address.city}, ${address.state} ${address.zipCode}`
        : "No address provided";
      const statusConfig = CONST.STATUS_CONFIG[state] || CONST.STATUS_CONFIG.UNAVAILABLE;

     
      return (
        <FToolTip
          content={
            <div>
              <strong>Driver Details:</strong>
              <br />
              <strong>Driver State: </strong>
              <span
               style={{ backgroundColor: statusConfig.color, display: "inline-block", padding: "2px 5px", borderRadius: "3px" }}
               >
                {state}
              </span>
              <br />
              <strong>Name: </strong> {fullName} <br />
              <strong>Username: </strong> {username} <br />
              <strong>Phone: </strong> {phoneNumber} <br />
              <strong>Email: </strong> {email} <br />
              <strong>Address: </strong> {formattedAddress} <br />
            </div>
          }
        >
          <FAlert alertType={statusConfig.alertType}>
            <span> 
              <StyledAssetLink>
              {/* {state} <span>&nbsp;</span> */}
              <React.Fragment>
                {hasPermission && <FRouterLink
                  editIcon={editIcon}
                  label={driverInfo.driver.prettyName}
                  disabled={!hasPermission}
                  to={CONST.ROUTE_DRIVER_URL + driverInfo.driver.id} />}

                {!hasPermission && driverInfo.driver.prettyName}
              </React.Fragment>
            </StyledAssetLink> </span>
          </FAlert>

         
        </FToolTip>
      )
    }
  }




  getDriveProfilerLink = (rowData) => {
    {
      if (!rowData.driverProfile) {
        return ''
      }
      return this.getProfileDriverLink(rowData.driverProfile)
    }
  }

  getSelfVehicleLink = (rowData, row) => {
    return this.getVehicleLink(rowData, row, true)
  }

  getVehicleLink = (rowData, row, editIcon = false) => {
    {
      // if (!rowData.vehicle){
      //   return ''
      // }
      let vehicle = this.getObjFromNestedDict(rowData, 'vehicle', 'expenseRawData')

      if (!vehicle) {
        if (!rowData.vehicleVIN) return ''
        else vehicle = rowData
      }

      // const imageUrl = getTruckAvatarUrl(vehicle)

      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_VEHICLES)
      return (
        <StyledAssetLink>
          {getVehicleAvatarUrl(vehicle)}
          <React.Fragment>
            {hasPermission && <FRouterLink
              label={vehicle.prettyName}
              editIcon={editIcon}
              disabled={!hasPermission}
              to={CONST.ROUTE_VEHICLE_URL + vehicle.id} />}

            {!hasPermission && vehicle.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }



  getTrailerLink = (rowData, row, editIcon = false) => {
    {
      if (!rowData.trailer) {
        return ''
      }
      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_VEHICLES)

      let trailer = rowData.trailer;
      return (
        <StyledAssetLink>
          {getVehicleAvatarUrl(trailer)}
          <React.Fragment>
            {hasPermission && <FRouterLink
              label={rowData.trailer.prettyName}
              disabled={!hasPermission}
              editIcon={editIcon}
              to={CONST.ROUTE_VEHICLE_URL + rowData.trailer.id} />}

            {!hasPermission && rowData.trailer.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }

  getVehicleInfoEditLink = (vehicleInfo, row, editIcon = true) => {
    if (!vehicleInfo) {
      return '';
    }
  
    let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_VEHICLES);
  
    const {
      vehicleNumber,
      vehicleVIN,
      prettyName,
      vehicleCurrentOdometer,
      vehicleType,
      vehiclePicture,
      updated,
      state,
      id // Assuming the vehicle object has an 'id' property
    } = vehicleInfo;
  
    const contentStyle = {
      padding: '12px',
      maxWidth: '400px',
      fontSize: '14px',
      lineHeight: '1.5',
      color: '#333',
      whiteSpace: 'normal',
    };
    const statusConfig = CONST.STATUS_CONFIG[state] || CONST.STATUS_CONFIG.UNAVAILABLE;
  
    return (
      <FToolTip
        content={
          <div style={contentStyle}>
            <strong>Vehicle Details:</strong>
            <br />
            <strong>Vehicle State: </strong>
            <span style={{ backgroundColor: statusConfig.color, display: 'inline-block', padding: '2px 5px', borderRadius: '3px' }}>
              {state}
            </span>
            <br />
            <strong>Vehicle Type: </strong> {vehicleType} <br />
            <strong>Vehicle Number: </strong> {vehicleNumber} <br />
            <strong>VIN: </strong> {vehicleVIN} <br />
            <strong>Last Updated: </strong> {updated} <br />
            
            <strong>Odometer: </strong> {vehicleCurrentOdometer} <br />
            {vehiclePicture && (
              <>
                <br />
                <br />
                <img src={vehiclePicture} alt="Vehicle" style={{ maxWidth: '200px', maxHeight: '150px' }} />
              </>
            )}
          </div>
        }
      >
        <FAlert alertType={statusConfig.alertType}>
          <span>
            <StyledAssetLink>
              <React.Fragment>
                {hasPermission && (
                  <FRouterLink
                    editIcon={editIcon}
                    label={prettyName}
                    disabled={!hasPermission}
                    to={CONST.ROUTE_VEHICLE_URL + id}
                  />
                )}
                {!hasPermission && prettyName}
              </React.Fragment>
            </StyledAssetLink>
          </span>
        </FAlert>
      </FToolTip>
    );
  };

  getSelfDispatcherLink = (rowData, row) => {
    return this.getDispatcherLink(rowData, row, true)
  }



  getDispatcherLink = (rowData, row, editIcon = false) => {

    {
      if (!rowData.dispatcherAccessID && !rowData.dispatcher) {
        return ''
      }

      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_DISPATCHERS)

      let dispatcher = rowData.dispatcher || rowData;

      if (!dispatcher.id) {
        return ''
      }
      return (
        <StyledAssetLink>
          {getDispatcherAvatarUrl(dispatcher)}
          <React.Fragment>
            {hasPermission && <FRouterLink
              editIcon={editIcon}
              label={dispatcher.prettyName}
              disabled={!hasPermission}
              to={CONST.ROUTE_DISPATCHER_URL + dispatcher.id} />}

            {!hasPermission && dispatcher.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }

  getCustomerLink = (rowData) => {
    {
      if (!rowData.customer) {
        return ''
      }
      let hasPermission = has_link_permission(this.props.profile, CONST.FLEET_CUSTOMERS)

      return (
        <StyledAssetLink maxWidth="15em">
          <React.Fragment>
            {hasPermission && <FRouterLink
              label={rowData.customer.prettyName}
              disabled={!hasPermission}
              to={CONST.ROUTE_CUSTOMER_URL + rowData.customer.id} />}

            {!hasPermission && rowData.customer.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }

  getFleetLink = (rowData) => {
    {
      if (!rowData.fleet) {
        return ''
      }
      let hasPermission = has_link_permission(this.props.profile, CONST.FLEET_FLEETS)
      return (
        <StyledAssetLink>
          <React.Fragment>
            {hasPermission && <FRouterLink
              label={rowData.fleet.prettyName}
              disabled={!hasPermission}
              to={CONST.ROUTE_FLEET_URL + rowData.fleet.id} />}

            {!hasPermission && rowData.fleet.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }

  getCompanyLink = (rowData) => {
    {
      if (!rowData.company) {
        return ''
      }
      let hasPermission = has_link_permission(this.props.profile, CONST.ADMIN_CONTACTS)

      return (
        <React.Fragment>
          {hasPermission && <FRouterLink
            label={rowData.company.prettyName}
            disabled={!hasPermission}
            to={CONST.ROUTE_COMPANY_URL + rowData.company.id} />}

          {!hasPermission && rowData.company.prettyName}
        </React.Fragment>
      )
    }
  }
  getFormerEmploymentLink = (rowData) => {
    {
      if (!rowData.employmentHistory) {
        return ''
      }
      let hasPermission = has_link_permission(this.props.profile, CONST.SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS)
      return (
        <StyledAssetLink>
          <React.Fragment>
            {hasPermission && <FRouterLink
              label={rowData.employmentHistory.prettyName}
              disabled={!hasPermission}
              to={CONST.ROUTE_EMPLOYEMENT_HISTORY_URL + rowData.employmentHistory.id} />}

            {!hasPermission && rowData.company.prettyName}
          </React.Fragment>
        </StyledAssetLink>
      )
    }
  }
}


