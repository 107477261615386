import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { ROUTE_INTEGRATION_URL, API_API_INTEGRATION_URL, CHOICE_INTEGRATION, TAB_ADMIN, ADMIN_INTEGRATION } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { APIIntegrations }from '../widgets/FIintegrationButtons';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import { getAPIIntegrationStatusIcon } from '../../util/icon_util';

const styles= theme => ({
  root: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: 'x-large'
  },
});

class Integrations extends FListComponent {
  constructor(props) {
    super(props, API_API_INTEGRATION_URL, CHOICE_INTEGRATION)
    this.currentTitle = 'Integrations'
    this.currentTab = TAB_ADMIN
    this.routeUrl = ROUTE_INTEGRATION_URL 
    this.policyID = ADMIN_INTEGRATION
  }
  

  render() {
    const { classes } = this.props;
    return (
      <div>
        <APIIntegrations/>
        {/* <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            { title: 'Company ', field: 'companyName' },

            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            {
              title: 'Integration Status', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getAPIIntegrationStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            { title: 'Last Updated ', field: 'updated', render: rowData => this.getPrettyDate(rowData.updated)  },
           
          ]}
          // actions={
          //   [
          //     this.getAddNewModelActions()
          //   ]
          //  }
            options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Integrations"
          listErrCode={this.state.listErrCode}
        /> */}
      </div>
    );
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(Integrations))
