import React from 'react'
import { connect } from 'react-redux'

import { Grid } from "@material-ui/core";
import FButton from "../widgets/FButton";
import FDialog from '../widgets/FDialog';
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import FSelect from "../widgets/FSelect";
import * as CONST from "../../util/Const";
import FInput from '../widgets/FInput';
import { FListDetailView } from '../../util/FListDetailView';
import { isValid } from "../../util/Validator";
import FAutoComplete from '../widgets/FAutoComplete';

import { withStyles } from "@material-ui/core/styles";

import {VALIDATE_REQUIRED, VALIDATE_EMAIL, VALIDATE_PHONE_NUMBER, VALIDATE_CARRIER_REQUIRED, VALIDATE_STR_ADDRESS_LOCATION, VALIDATE_CARRIER_CUSTOMER_TYPE } from "../../util/ValidatorConst";
import { Labels } from '../../localizations/Resource';

const styles = theme => ({
  root: {
      // display: "flex",

  },
  padding: {
      marginTop: theme.spacing(4),
  },

  header: {
      background: '#f5f5f5'
  },
  button: {
      marginRight: theme.spacing(2),
  },
  dropDown: {
      
    },
});
const PROPS = {
  editMode: false,
  isNew: true,
  url: CONST.API_CUSTOMER_URL,
  errorData: {
      carrier: false,
      customerType: false,
      customerName: false,
      customerEmail: false,
      customerPhoneNumber: false,
      customerPhysicalAddress: false,
  },
  errorMessage: {
      carrier: 'Invalid Carrier',
      customerType: 'Invalid customer Type',
      customerName: 'Invalid customer Name',
      customerEmail: 'Invalid Email',
      customerContactPhoneNumber: 'Invalid Phone Number',
      customerPhysicalAddress: "Invalid Address",
  },
  touched: {
      companyCode: true,
      carrier: true,
      customerType: true,
      customerName: true,
      customerEmail:true,
      customerPhoneNumber:true,
      customerPhysicalAddress: true,
  },
  data: {
    carrier: {},
    customerType: {},
    customerName: '',
    customerEmail: '',
    customerPhoneNumber: '',
    customerPhysicalAddress: '',
  },
  validators: {
    carrier: [VALIDATE_CARRIER_REQUIRED],
    customerType: [ VALIDATE_CARRIER_CUSTOMER_TYPE],
    customerName: [VALIDATE_REQUIRED],
    customerEmail: [ VALIDATE_EMAIL], 
    customerPhoneNumber: [VALIDATE_PHONE_NUMBER],
    customerPhysicalAddress: [VALIDATE_STR_ADDRESS_LOCATION],
  }
}

class CustomerPopUpView extends FListDetailView {

      constructor(props) {
        // PROPS['data']['companyCode'] = props.companyCode || CONST.CompanyCode.SERVICE_PROVIDER
        super(props, PROPS)
      }


    componentDidMount = () => {};

    fetchListData = () => {};

    notifyDialogClose = () => {
        this.props.notifyDialogClose();
        this.resetErrorMessage();
      };
    
    onEdit = (rowData = null) => {
        if (rowData === null) {
          this.isNew = true;
          rowData = {};
        }
        else {
          this.isNew = false;
        }
        this.setState({ data: rowData });
        this.setState({ editMode: true });
      };

    // updateFormData = (formData) => {
    //     formData.set('carrier',  this.props.carrier.id)
    //     return formData
    //  }

     resetErrorMessage = () => {
        this.setState(PROPS)
        // this.setState({
        //   errorData: {},
        // });
      };
    
    getErrorData = () => {
        
        let errorData = {
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
            customerName: !isValid(this.state.data.customerName, this.state.validators.customerName),
            customerEmail: !isValid(this.state.data.customerEmail, this.state.validators.customerEmail),
          
            customerPhoneNumber: !isValid(this.state.data.customerPhoneNumber, this.state.validators.customerPhoneNumber),
            customerPhysicalAddress: !isValid(this.state.data.customerPhysicalAddress, this.state.validators.customerPhysicalAddress),
            customerType: !isValid(this.state.data.customerType, this.state.validators.customerType),
        }
        return errorData
    }

  render() {
    const title = this.props.title || 'Contacts'
    const { classes } = this.props;
    return (
          <FDialog
            maxWidth='xl'
            fullWidth={false}
            title={title}
            owner={this.props.owner}
            openDialog={this.props.dialogEditMode}
            notifyDialogClose={this.notifyDialogClose}>
              
                <Grid container spacing= {1}  direction='column'  >
                       <Grid item xs>
                                <FAutoComplete
                                            url={CONST.API_CARRIERS_URL}
                                            name='carrier'
                                            hasError={this.state.errorData.carrier}
                                            helpMessage={this.state.errorMessage.carrier}
                                            label={Labels.CARRIER} 
                                            // queryParam={'prettyName=true'}
                                            // queryParam='search'
                                            value={this.state.data.carrier}
                                            defaultValue={this.state.data.carrier}
                                            notifyParent={this.updateValues}
                                            disabled={!this.state.isNew}
                                            />
                            </Grid>
                            <Grid item xs>
                                {/* <FSelect
                                    name='companyType' required
                                    value={this.state.data.companyType}
                                    hasError={this.state.errorData.companyType}
                                    helpMessage={this.state.errorMessage.companyType}
                                    label='Contact Type *'
                                    data={this.props.choices}
                                    
                                    valueRef='prettyName'
                                    keyRef='id'
                                    notifyParent={this.updateValues}
                                /> */}
                                <FSelect
                                    name='customerType'
                                    value={this.state.data.customerType}
                                    hasError={this.state.errorData.customerType}
                                    helpMessage={this.state.errorMessage.customerType}
                                    label='Customer Type'
                                    data={this.props.customerTypeChoice}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                {/* <FInput name='companyName' required
                                    value={this.state.data.companyName}
                                    hasError={this.state.errorData.companyName}
                                    helpMessage={this.state.errorMessage.companyName}
                                    label="Name"
                                    notifyParent={this.updateValues}
                                /> */}
                                <FInput name='customerName' required
                                    value={this.state.data.customerName}
                                    hasError={this.state.errorData.customerName}
                                    helpMessage={this.state.errorMessage.customerName}
                                    label="Customer / Company Name"
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                        
                           
                    
                            <Grid item xs>
                                {/* <FInput name='companyEmail' required
                                    value={this.state.data.companyEmail}
                                    hasError={this.state.errorData.companyEmail}
                                    helpMessage={this.state.errorMessage.companyEmail}
                                    label="Email" notifyParent={this.updateValues}
                                /> */}
                                <FInput name='customerEmail' required
                                    value={this.state.data.customerEmail}
                                    hasError={this.state.errorData.customerEmail}
                                    helpMessage={this.state.errorMessage.customerEmail}
                                    label="Company Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                {/* <FInput name='companyPhoneNumber' required
                                    value={this.state.data.companyPhoneNumber}
                                    hasError={this.state.errorData.companyPhoneNumber}
                                    helpMessage={this.state.errorMessage.companyPhoneNumber}
                                    label="Phone Number" notifyParent={this.updateValues}
                                /> */}
                                <FInput name='customerPhoneNumber' required
                                    value={this.state.data.customerPhoneNumber}
                                    hasError={this.state.errorData.customerPhoneNumber}
                                    helpMessage={this.state.errorMessage.customerPhoneNumber}
                                    label="Company Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>
                    
                       <Grid item xs>
                                {/* <FAddress
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        place={false}
                                        city={true}
                                        state={true}
                                        zipCode={false}
                                /> */}
                                <FInput name='customerPhysicalAddress' 
                                    xs
                                    value={this.state.data.customerPhysicalAddress}
                                    hasError={this.state.errorData.customerPhysicalAddress}
                                    helpMessage={this.state.errorMessage.customerPhysicalAddress}
                                    label="Mailing Address (address, city, State, zipcode) " notifyParent={this.updateValues}
                                    notifyParent={this.updateValues}
                                    autoComplete={'off'}
                                />
                        </Grid>
                    
                            <Grid item xs>
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                       
                    <Grid container alignItems='stretch' spacing={2} >
                        <Grid item xs={3}>
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                        </Grid>
                        <Grid item  xs={3}>
                            <FButton 
                                color='primary'
                                label={'Cancel'}
                                onClick={this.notifyDialogClose}
                                color='secondary'
                                // className={'backButton'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            
          </FDialog>
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(CustomerPopUpView))
