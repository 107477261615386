import React from 'react';
import { ROUTE_INVOICE_URL, API_INVOICE_URL, TAB_ACCOUNTING, FINANCE_INVOICE, CHOICE_INVOICE, getModuleTitle } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal } from '../../util';
import FMaterialTableNew from '../widgets/FMaterialTableNew';
import { getFleetInvoiceReport,  getInvoiceTooltip } from '../../util/icon_util';
import BlockIcon from '@material-ui/icons/Block';
import DateDeliverBadge from '../widgets/FVerifcationBadge';
import FTableActionNew from '../widgets/FTableActionNew';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import InvoicePopUp from './InvoicePopUp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FToolTip from '../widgets/FTooltip';



class Invoices extends FListComponent {

  constructor(props) {
    super(props, API_INVOICE_URL, CHOICE_INVOICE)
    this.currentTitle = 'Invoices'
    this.currentTab = TAB_ACCOUNTING
    this.routeUrl = ROUTE_INVOICE_URL
    this.policyID = FINANCE_INVOICE
    this.includeStateAction = false
  }

  onTableAction = (actionId, rowData) => {
    let data = { id: rowData.id };
    let url = this.getListURL() + rowData.id + '/event/';
    const autoInvoiceWorkflowID = rowData.invoiceInfo?.autoInvoiceWorkflowID;

    switch (actionId) {
      case "ACTION_INVOICE_MARK_INVOICED":
        this.updateRow(rowData, { invoiceStatus: "INVOICED" });
        break;
      case "ACTION_INVOICE_MARK_FULLY_PAID":
        this.updateRow(rowData, { invoiceStatus: "PAID" });
        break;
      case "ACTION_INVOICE_MARK_CANCELED":
        this.updateRow(rowData, { invoiceStatus: "CANCELED" });
        break;
      case "ACTION_DELETE":
        this.deleteRow(rowData);
        break;
      case "REQUEST_INVOICE_TO_APEX":
      case "REQUEST_INVOICE_TO_TRIUMPH_BUSINESS_CAPITAL":
        data = { ...data, eventType: autoInvoiceWorkflowID };
        this.actionHandler(url, { ...data, eventType: autoInvoiceWorkflowID });
        break;
      case "REFRESH_TABLE":
        this.tableRef.current.onQueryChange();
        break;
      default:
        console.warn(`Unhandled action: ${actionId}`);
    }
  };

  onEdit = (rowData) => {
    this.setState({
      currentRow: rowData,  // Stores the invoice being edited
      dialogEditMode: true, // Opens the popup
    });
  };

  onSuccess = (success) => {
    this.tableRef.current.onQueryChange();
    this.setState({ dialogEditMode: false });
  };


  render() {

    return (
      <div>
        <div>
          <InvoicePopUp
            open={this.state.dialogEditMode}
            onClose={() => this.setState({ dialogEditMode: false })}
            onSuccess={this.onSuccess}
            url={`${API_INVOICE_URL}${this.state.currentRow?.id}`}
            fundingTimeChoices={this.props.fundingTimeChoices}
            fundingMethodChoices={this.props.fundingMethodChoices}
            data={this.state.currentRow}
            id={this.state.currentRow?.payCheckID}
            canEditFunding={["CREATED", "CANCELLED", "OVERDUE"].includes(this.state.currentRow?.invoiceStatus)}
            supportsSettingFundingTime={this.state.currentRow?.invoiceInfo?.factory?.supportsSettingFundingTime || false}
            supportsSettingFundingMethod={this.state.currentRow?.invoiceInfo?.factory?.supportsSettingFundingMethod || false}
          />
        </div>
        <FMaterialTableNew
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Invoice Details',
              field: 'payCheckID',
              render: rowData => {
                const factory = rowData.invoiceInfo && rowData.invoiceInfo.factory
                const isEditable = true; //factory.supportsFundingEdit;
                const supportsSettingFundingTime = factory.supportsSettingFundingTime;
                const supportsSettingFundingMethod = factory.supportsSettingFundingMethod;

                return (
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                    {
                      (["CREATED", "CANCELLED", "OVERDUE"].indexOf(rowData.invoiceInfo?.invoiceStatus) !== -1 && rowData.invoiceInfo?.fleetMaybeOutOfSync === true) ||
                        rowData.desc ? (
                        <FToolTip content={getInvoiceTooltip(rowData)}>
                          <ErrorOutlineIcon style={{ padding: "5px", color: "red", fontSize: "1.8em" }} />
                        </FToolTip>
                      ) : null
                    }

                    {isEditable ? (
                      <Button color="primary" onClick={() => this.onEdit(rowData)}>
                        {rowData.payCheckID} <EditIcon style={{ fontSize: "1.2rem", paddingLeft: ".3" }} />
                      </Button>
                    ) : (
                      <span style={{ color: "#333" }}>
                        {rowData.payCheckID}
                      </span>
                    )}

                    {/* Show dollar sign with Funding Time if supported and exists */}
                    {supportsSettingFundingTime && rowData.fundingTime && (
                      <span style={{ fontSize: "12px", color: "#555", paddingLeft: "10px" }}>
                        <span style={{ fontSize: "12px", marginRight: "5px" }}>$</span>
                        {rowData.fundingTime}
                      </span>
                    )}

                    {/* Show dollar sign with Funding Method if supported and exists */}
                    {supportsSettingFundingMethod && rowData.fundingMethod && (
                      <span style={{ fontSize: "12px", color: "#555", paddingLeft: "10px" }}>
                        <span style={{ fontSize: "12px", marginRight: "5px" }}>$</span>
                        {rowData.fundingMethod}
                      </span>
                    )}
                  </div>

                );
              }
            },
            {
              title: 'Fleet',
              render: this.getFleetLink
            },
            {
              title: 'Customer',
              render: this.getCustomerLink
            },
            { title: 'Load Fee', render: rowData => roundDecimal(rowData.payCheckFleetGrossPayment) },
            { title: 'Other Fees', render: rowData => roundDecimal(rowData.payCheckFleetTotalExpense) },

            {
              title: 'Trip Date', render: rowData => {
                return (<b>{this.getPrettyDate(rowData.invoiceInfo?.fleetPickUpDate)}</b>)
              }
            },
            {
              title: 'Trip Information',
              render: rowData => {
                const pickupLocation = rowData.fleet?.fleetPickupLocation;
                const deliveryLocation = rowData.fleet?.fleetDeliveryLocation;
                const fleetPickUpDate = rowData.invoiceInfo?.fleetPickUpDate;

                return (
                  <p style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Arial, sans-serif',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  {pickupLocation}<b>&nbsp;{"==>"}&nbsp;</b>{deliveryLocation}
                  </p>
                );
              }
            },
            
            {
              title: 'Total Payout', render: rowData => {
                return (<b>${roundDecimal(rowData.payCheckNet)}</b>)
              }
            },
            {
              title: 'Invoiced Date',
              render: rowData => (
                <DateDeliverBadge verification={rowData.invoiceDeliveredVerification}
                  date={this.getPrettyDate(rowData.invoicedDate)} />
              ),
            },
            {
              title: 'Status',
              fields: 'invoiceStatus',
              render: rowData => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
                    <span style={{ fontWeight: 'bold', color: '#333', marginRight: 16 }}>
                      {getFleetInvoiceReport(rowData)}
                    </span>
                  </div>
                );
              }
            },
            
            {
              title: 'Actions',
              render: rowData => {
                const actions = rowData.workflowActionItems || [];

                return (
                  <React.Fragment>
                    {!!actions.length ? (
                      <FTableActionNew
                        actions={actions}
                        onTableAction={this.onTableAction}
                        rowData={rowData}
                      />
                    ) : (
                      <BlockIcon color="disabled" titleAccess="No Actions Available" />
                    )}
                  </React.Fragment>
                );
              }
            },
          ]}
          isLoading={this.state.isLoading}
          options={{
            pageSize: this.state.pageSize,
            pageSizeOptions: this.state.pageSizeOptions,
            actionsColumnIndex: -1,
          }}
          data={this.fetchData}
          title="Invoices"
          listErrCode={this.state.listErrCode}
        />


      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Invoices)
