import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FSelect from "../widgets/FSelect";
import FButton from "../widgets/FButton";
import FDialog from "../widgets/FDialog";
import { UPDATE } from "../../util/FAPI";
import { isValid } from "../../util/Validator";
import { VALIDATE_REQUIRED } from "../../util/ValidatorConst";
import { showSuccessNotification, showErroNotification } from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import FMemo from "../widgets/FMemo";

const styles = (theme) => ({
    buttonContainer: {
        marginTop: theme.spacing(3),
    },
});

const InvoicePopUp = ({ open, onClose, onSuccess, url, fundingTimeChoices, id, canEditFunding,
    fundingMethodChoices, supportsSettingFundingTime, supportsSettingFundingMethod, data = {}, classes }) => {

    const [formData, setFormData] = useState({ fundingTime: "", fundingMethod: "", desc: "" });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // **Reset Form When Dialog Opens**
    useEffect(() => {
        if (open) {
            setFormData({
                desc: data.desc || "",
                fundingTime: data.fundingTime || "",
                fundingMethod: data.fundingMethod || ""
            });
            setErrors({});
        }
    }, [open, data]);

    // **Reusing Update Logic**
    const updateValues = (name, obj) => {
        setFormData((prev) => ({
            ...prev,
            ...obj,
        }));
        validateSingle(name, obj[name]);
    };

    const getFormData = (formDataState, excludeKeys = []) => {
        let formData = new FormData();
        Object.keys(formDataState).forEach((key) => {
            if (!excludeKeys.includes(key)) {
                let value = formDataState[key];
                if (value && typeof value === "object" && value.hasOwnProperty("id")) {
                    value = value.id;
                }
                if (value !== undefined && value !== null) {
                    formData.append(key, value);
                }
            }
        });

        return formData;
    };

    // **Validate Single Field**
    const validateSingle = (name, value) => {
        setErrors((prev) => ({
            ...prev,
            [name]: supportsSettingFundingTime && name === "fundingTime" ? !isValid(value, [VALIDATE_REQUIRED]) :
                supportsSettingFundingMethod && name === "fundingMethod" ? !isValid(value, [VALIDATE_REQUIRED]) :
                    false, // No validation for desc (optional)
        }));
    };

    // **Validate Full Form**
    const validateForm = () => {
        const newErrors = {
            fundingTime: supportsSettingFundingTime ? !isValid(formData.fundingTime, [VALIDATE_REQUIRED]) : false,
            fundingMethod: supportsSettingFundingMethod ? !isValid(formData.fundingMethod, [VALIDATE_REQUIRED]) : false,
        };
        setErrors(newErrors);
        return !Object.values(newErrors).includes(true);
    };

    const handleSave = async () => {
        if (!validateForm()) return;
        setLoading(true);

        try {
            const formDataToSend = getFormData(formData);
            const response = await UPDATE(url, "patch", formDataToSend);

            if (response && response.status >= 200 && response.status < 300) {
                showSuccessNotification("Invoice updated successfully!");
                onSuccess();
                onClose();
            } else {
                throw new Error("Unexpected API response");
            }
        } catch (error) {
            console.log(error);
            showErroNotification(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <FDialog maxWidth="xl" fullWidth={false} title={`Edit Invoice#  ${id}`} openDialog={open}  notifyDialogClose={onClose}>
            <Grid container spacing={2}>
                {/* Description Field */}
                <Grid item xs={12}>
                    <FMemo
                        name='desc'
                        value={formData.desc}
                        multiline={true}
                        elevation={0}
                        hasError={errors.desc}
                        helpMessage={errors.desc ? "Invalid Description" : ""}
                        // label="Notes"
                        rows={3}
                        notifyParent={(name, obj) => updateValues(name, obj)}
                    />
                </Grid>

                {/* Funding Time */}
                <Grid item xs={12}>
                    {canEditFunding &&  supportsSettingFundingTime && (
                        <FSelect
                            name="fundingTime"
                            value={formData.fundingTime}
                            hasError={errors.fundingTime}
                            helpMessage="Invalid Type"
                            label="Funding Time"
                            data={fundingTimeChoices}
                            valueRef="prettyName"
                            keyRef="id"
                            notifyParent={(name, obj) => updateValues(name, obj)}
                        />
                    )}
                </Grid>

                {/* Funding Method */}
                <Grid item xs={12}>
                    {canEditFunding &&  supportsSettingFundingMethod && (
                        <FSelect
                            name="fundingMethod"
                            value={formData.fundingMethod}
                            hasError={errors.fundingMethod}
                            helpMessage="Invalid Type"
                            label="Funding Method"
                            data={fundingMethodChoices}
                            valueRef="prettyName"
                            keyRef="id"
                            notifyParent={(name, obj) => updateValues(name, obj)}
                        />
                    )}
                </Grid>


                {/* Buttons */}
                <Grid item xs={12} container justifyContent="space-between" className={classes.buttonContainer}>
                    <Grid item xs={5}>
                        <FButton color="primary" label="Save" loading={loading} onClick={handleSave} fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <FButton label="Cancel" onClick={onClose} color="secondary" fullWidth />
                    </Grid>
                </Grid>
            </Grid>
        </FDialog>
    );
};

export default withStyles(styles)(InvoicePopUp);
