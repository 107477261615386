// export const BASE_API_URL = 'http://127.0.0.1:8000';
// export const SQUARE_APPLICATION_ID = 'sq0idp-o81fzn6zfJpI18vxBdQsSQ'
// export const SQUARE_APPLICATION_ID = 'sandbox-sq0idb-nHdXkd-rwqDswZm0358dWw'

export const ZTRUCKING_WEBSITE = 'https://www.ztrucking.com'
export const BASE_API_URL = process.env.REACT_APP_BASE_API
// export const BASE_API_URL = 'https://admin.ztrucking.com';

// Production the medial url gives full aws url -- normally would be https://ztrucking-doc-center.s3.amazonaws.com/
export const BASE_DOCUMENT_URL = process.env.REACT_DOCUMENT_URL

export const SQUARE_APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID
export const SQUARE_LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID

export const IS_DEBUG_MODE = !!process.env.REACT_IS_DEBUG_MODE
export const GOOGLE_ANYTICS_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; 
export const ENV_PROD = 'PRODUCTION'
export const ENV_LOCAL = 'LOCAL'
export const ENV_DEV = 'DEV'
export const ZT_ADMIN_UI_VERSION_NAME = 'ztadminuiversion'


export const isProdEnv = function() {
   const envType = process.env.REACT_APP_ENV
   if (envType == 'production') return true
   return false
}


export const CompanyCode = {
   BROKER: 1,
   CARRIER: 2,
   SHIPPER: 3,
   SERVICE_PROVIDER: 4,
   FORMER_EMPLOYER: 5
}

export const RECAPCH_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY

export const AUTH_TOKEN = `authorization`
export const PROFILE = 'PROFILE'

export const API_HEALTH_CHECK = `${BASE_API_URL}/api/htc/health_status_check/`

export const API_PROFILE_URL = `${BASE_API_URL}/api/profile/`
export const API_USER_URL = `${BASE_API_URL}/api/profile/`
export const API_PERMISSION_URL = `${BASE_API_URL}/api/user_profile/update_permission/`
export const API_DRIVER_PROFILE_URL = `${BASE_API_URL}/api/profile/driver_profile/`



export const API_AUTH_SIGN_UP = `${BASE_API_URL}/api/user_profile/signup/`
export const API_AUTH_SIGNUP_CARRIER_OWNERS = `${BASE_API_URL}/api/user_profile/signup_carrier_owner/`
export const API_AUTH_FORGOT_CRED = `${BASE_API_URL}/api/user_profile/forgot_cred/`

export const API_AUTH_SIGNED_IN = `${BASE_API_URL}/api/user_profile/login/`
export const API_AUTH_SIGNED_OUT = `${BASE_API_URL}/api/user_profile/logout/`
export const API_AUTH_CHANGE_PASSWORD = `${BASE_API_URL}/api/user_profile/change_password/`

export const FETCH_PROFILE_STATE = `${BASE_API_URL}/api/user_profile/fetch_updates/`


export const FETCH_PAYMENT_HISTORY = `${BASE_API_URL}/api/profile_payment/transactions/payment_history/`
export const PROCESS_PAYMENT_SQUARE = `${BASE_API_URL}/api/profile_payment/transactions/process_payment/`
export const API_BANK_CARDS_URL = `${BASE_API_URL}/api/profile_payment/cards/bank_cards/`


export const API_FLEET_URL = `${BASE_API_URL}/api/fleet/`
export const API_FLEET_PAYCHECK_URL = `${BASE_API_URL}/api/fleet/paycheck`



export const API_PAY_CHECK_URL = `${BASE_API_URL}/api/paycheck/`
export const API_PAYCHECK_FILTER_URL = `${BASE_API_URL}/api/paycheck/filter`

export const API_INVOICE_URL = `${BASE_API_URL}/api/invoice/`
export const API_INVOICE_FILTER_URL = `${BASE_API_URL}/api/invoice/filter`


export const API_FUEL_IMPORT_TRANSACTIONS_URL = `${BASE_API_URL}/api/fuel_expense/`
export const API_FUEL_IMPORT_URL = `${BASE_API_URL}/api/data_import/`
export const API_FUEL_IMPORT_UPLOAD_URL = `${BASE_API_URL}/api/data_import/upload`
export const API_FUEL_IMPORT_SYNC = `${BASE_API_URL}/api/data_import/sync`


export const API_MILEAGE_IMPORT_TIRPS_URL = `${BASE_API_URL}/api/mileage_trips/`
export const API_MILEAGE_IMPORT_URL = `${BASE_API_URL}/api/data_import/`
export const API_MILEAGE_IMPORT_UPLOAD_URL = `${BASE_API_URL}/api/data_import/upload`
export const API_MILEAGE_IMPORT_SYNC = `${BASE_API_URL}/api/data_import/sync`


export const API_EXPENSE_URL = `${BASE_API_URL}/api/expense/`
export const API_REC_EXPENSE_URL = `${BASE_API_URL}/api/expense/recurring/`

export const API_EXPENSE_PAYCHECK_URL = `${BASE_API_URL}/api/expense/paycheck`
export const API_VEHICLE_MAINTENANCE_URL = `${BASE_API_URL}/api/vehicle_maintenances/`


export const API_CARRIERS_URL = `${BASE_API_URL}/api/carrier/`
export const API_CUSTOMER_URL = `${BASE_API_URL}/api/customer/`

export const API_API_INTEGRATION_URL = `${BASE_API_URL}/api/apiintegration/`


export const API_DRIVERS_URL = `${BASE_API_URL}/api/driver/`
export const API_FUEL_CARDS_URL = `${BASE_API_URL}/api/fuel_card/`
export const API_TRIPS_URL = `${BASE_API_URL}/api/trip/`


export const API_DISPATCHERS_URL = `${BASE_API_URL}/api/dispatcher/`

export const API_VEHICLES_URL = `${BASE_API_URL}/api/vehicle/`
export const API_DOCUMENTS_URL = `${BASE_API_URL}/api/document/`
export const API_CHOICE_URL = `${BASE_API_URL}/api/choice/`



export const API_LOCATION_URL = `${BASE_API_URL}/dapi/location/`
// export const API_CARRIERS_URL = `${BASE_API_URL}/dapi/carrier/`
export const API_VEHICLES_URLL = `${BASE_API_URL}/dapi/vehicle/`



//Dashboard preview urls
export const API_DASHBOARD_SUMMARY = `${BASE_API_URL}/api/dashboard/dashBoardSummary/`
export const API_DASHBOARD_CARRIER_SUMMARY = `${BASE_API_URL}/api/dashboard/carrierSummary/`
export const API_DASHBOARD_DISPATCHER_SUMMARY = `${BASE_API_URL}/api/dashboard/dispatcherSummary/`



export const API_DASHBOARD_EMPLOYMENT_COMPLIANCE_PREVIEW = `${BASE_API_URL}/api/dashboard/employmentComplianceSummary`
export const API_DASHBOARD_INSPECTION_COMPLIANCE_PREVIEW = `${BASE_API_URL}/api/dashboard/inspectionComplianceSummary`
export const API_DASHBOARD_TRAFFIC_VIOLATION_COMPLIANCE_PREVIEW = `${BASE_API_URL}/api/dashboard/trafficViolationComplianceSummary`
export const API_DASHBOARD_ACCIDENTS_COMPLIANCE_PREVIEW = `${BASE_API_URL}/api/dashboard/accidentComplianceSummary`

export const API_SERVICE_PROVIDER = `${BASE_API_URL}/api/company/`
export const API_COMPANY = `${BASE_API_URL}/api/company/`

export const API_FORMER_EMPLOYER = `${BASE_API_URL}/api/company/`


export const API_DRUG_TEST = `${BASE_API_URL}/api/drug_test/`
export const API_ACCIDENT_REGISTER = `${BASE_API_URL}/api/accident_or_violation_register/`
export const API_VEHICLE_INSPECTION = `${BASE_API_URL}/api/vehicle_inspection/`
export const API_EMPLOYEMENT_HISTORY = `${BASE_API_URL}/api/employment_history/`
export const API_RESIDENT_HISTORY = `${BASE_API_URL}/api/resident_history/`


export const API_VEHICLE_MAINTENANCE_SCHEDULE_URL = `${BASE_API_URL}/api/schedule/`
export const API_VEHICLE_MAINTENANCE_SCHEDULE_JOB_URL = `${BASE_API_URL}/api/schedule/scheduled_jobs/`


export const API_EXPIRING_DOCUMENT = `${BASE_API_URL}/api/compliance/expiring_documents/`
export const API_EXPIRED_DOCUMENT = `${BASE_API_URL}/api/compliance/expired_documents/`

export const API_NON_COMPLIANCE = `${BASE_API_URL}/api/compliance/non_compliance/`

export const API_REPORT_DRIVER = `${BASE_API_URL}/api/report/drivers/`
export const API_REPORT_VEHICLE = `${BASE_API_URL}/api/report/vehicles/`

export const API_REPORT_DOCUMENT = `${BASE_API_URL}/api/report/documents/`
export const API_REPORT_FLEET = `${BASE_API_URL}/api/report/fleets/`
export const API_REPORT_EXPENSE = `${BASE_API_URL}/api/report/expenses/`
export const API_REPORT_MAINTENANCE_RECORD = `${BASE_API_URL}/api/report/expense_maintenance_records/`
export const API_MAINTENANCE_SCHEDULE_REPORT_URL = `${BASE_API_URL}/api/report/maintenance_schedules/`
export const API_DRIVER_DISCIPLINARY_EVENT_REPORT_URL = `${BASE_API_URL}/api/report/disciplinary_event/`


export const API_REPORT_INVOICE = `${BASE_API_URL}/api/report/invoices/`
export const API_REPORT_IFTA_SUMMARY = `${BASE_API_URL}/api/report/ifta_summarys/`


export const API_DRIVER_DISCIPLINARY_EVENT_API = `${BASE_API_URL}/api/event/`

// TODO(Estifanos) Not emplemented yet
export const API_DRIVER_EMPLOYMENT_APPLICATION_URL = `${BASE_API_URL}/api/driver_employment_application/`
export const API_DRIVER_UPLOAD_DOCUMENT_URL = `${BASE_API_URL}/api/driver_app_upload/`


// Profile Tab const 
export const TAB_DASHBOARD = 'dashboard'
export const TAB_REPORT = 'report'
export const TAB_ACCOUNTING = 'accounting'
export const TAB_SAFETY_AND_COMPLIANCE = 'safety_and_compliance'
export const TAB_DRIVER_APP = 'driver_app'

export const TAB_EXPENSE = 'expensie'
export const TAB_FLEET = 'fleet'
export const TAB_ADMIN = 'admin'
export const TAB_PROFILE = 'profile'



// IDS ACL / Menus
export const DASHBOARD_COMPLINANCE_OVERVIEW = '*'
export const DASHBOARD_FINANCIAL_OVERVIEW = '6000'

export const REPORT_DRIVERS = '9000'
export const REPORT_VEHICLES = '9000'
export const REPORT_FLEETS = '10000'
export const REPORT_EXPENSES = '11000'
export const REPORT_INVOICES= '6000'
export const REPORT_MAINTENANCE_RECORD = '12000'
export const REPORT_MAINTENANCE_SCHEDULE = '12000'
export const REPORT_DRIVER_DISCIPLINARY= '12000'
export const REPORT_IFTA_SUMMARY= '8000'

export const FINANCE_PAYCHECK= '6000'
export const FINANCE_INVOICE= '6000'


export const SAFETY_AND_COMPLINACE_EXPIRING_DOCS= '7000'
export const SAFETY_AND_COMPLINACE_EXPIRED_DOCS= '7000'
export const SAFETY_AND_COMPLINACE_MISSING_DOCS= '7000'
export const SAFETY_AND_COMPLINACE_DRUG_TESTS= '7000'
export const SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS= '7000'
export const SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS= '7000'

export const SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS= '7000'
export const SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES = '7000'
export const SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS = '7000'
export const SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS = '7000'
export const SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS = '7000'
export const SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES = '7000'

export const EXPENSE_EXPNESES = '4000'
export const EXPENSE_FUEL_IMPORTS = '4000'

export const FLEET_FLEETS = '3000'
export const FLEET_CUSTOMERS = '3000'
export const FLEET_TRIPS = '3000'
export const FLEET_MILEAGE_IMPORTS = '3000'


export const ADMIN_CARRIERS = '2000'
export const ADMIN_INTEGRATION = '2000'
export const ADMIN_FUEL_CARDS = '2000'
export const ADMIN_VEHICLES = '2000'
export const ADMIN_DISPATCHERS = '2000'

export const ADMIN_CONTACTS = '13000'
export const ADMIN_COMPANYS = '13000'

export const ADMIN_DRIVERS = '1100'
export const DRIVER_APP_DRIVER_PROFILES = '1100'
export const DRIVER_APP_EMPLOYMENT_APPLICATION = SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS
export const DRIVER_APP_DOCUMENT_UPLOADS = '1100'

//Profile is not bounded to permission
export const PROFILE_MY_PROFILE = null
export const PROFILE_USERS_AND_PERMISSIONS = '1000'
export const PROFILE_PAYMENT = null
export const PROFILE_LOGOUT = null




// Browser route url
export const ROUTE_BASE_URL = `/UI`
export const ROUTE_OVERVIEW_DASHBOARD_URL = `${ROUTE_BASE_URL}/${TAB_DASHBOARD}/compliance_dashboard/`
export const ROUTE_FINANCIAL_DASHBOARD_URL = `${ROUTE_BASE_URL}/${TAB_DASHBOARD}/financial_overview/`

export const ROUTE_MY_ACCOUNT_URL = `${ROUTE_BASE_URL}/${TAB_PROFILE}/my_account/`
export const ROUTE_USERS_URL = `${ROUTE_BASE_URL}/${TAB_PROFILE}/users/`
export const ROUTE_HELP_URL = `${ROUTE_BASE_URL}/${TAB_PROFILE}/help/`


export const ROUTE_PAYMENT_URL = `${ROUTE_BASE_URL}/${TAB_PROFILE}/payment/`

export const ROUTE_CARRIERS_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/carriers/`
export const ROUTE_INTEGRATION_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/integrations/`

export const ROUTE_FUEL_CARD_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/fuel_cards/`
export const ROUTE_VEHICLE_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/vehicles/`
export const ROUTE_DISPATCHER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/dispatchers/`
//todo(estifo) Remove company replace with contact
export const ROUTE_COMPANY_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_CONTACT_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_FUEL_CARD_PROVIDER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_ELD_PROVIDER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_DRUG_TEST_PROVIDER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_AUTO_SERVICE_PROVIDER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_FORMER_EMPLOYER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_GENERIC_SERVICE_PROVIDER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`
export const ROUTE_FREELANCER_AND_CONTRACTOR_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`


export const ROUTE_EXPENSE_URL = `${ROUTE_BASE_URL}/${TAB_EXPENSE}/expenses/`
export const ROUTE_REC_EXPENSE_URL = `${ROUTE_BASE_URL}/${TAB_EXPENSE}/rec_expenses/`

export const ROUTE_FUEL_IMPORT_URL = `${ROUTE_BASE_URL}/${TAB_EXPENSE}/fuel_imports/`

export const ROUTE_DRIVER_URL = `${ROUTE_BASE_URL}/${TAB_DRIVER_APP}/driver_profiles/`
export const ROUTE_DRIVER_APP_DRIVER_PROFILE_URL = `${ROUTE_BASE_URL}/${TAB_DRIVER_APP}/driver_profiles/`
export const ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL = `${ROUTE_BASE_URL}/${TAB_DRIVER_APP}/driver_profiles/register/`


export const ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL = `${ROUTE_BASE_URL}/${TAB_DRIVER_APP}/employment_applications/`
export const ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL = `${ROUTE_BASE_URL}/${TAB_DRIVER_APP}/driver_document_uploads/`

export const ROUTE_FLEET_URL = `${ROUTE_BASE_URL}/${TAB_FLEET}/fleets/`
export const ROUTE_CUSTOMER_URL = `${ROUTE_BASE_URL}/${TAB_FLEET}/customers/`
export const ROUTE_TRIP_URL = `${ROUTE_BASE_URL}/${TAB_FLEET}/trips/`
export const ROUTE_MILEAGE_IMPORT_URL = `${ROUTE_BASE_URL}/${TAB_FLEET}/mileage_imports/`


export const ROUTE_MAINTENANCE_URL = `${ROUTE_BASE_URL}/${TAB_FLEET}/maintenances/`

export const ROUTE_PAY_CHECK_URL = `${ROUTE_BASE_URL}/${TAB_ACCOUNTING}/paychecks/`
export const ROUTE_INVOICE_URL = `${ROUTE_BASE_URL}/${TAB_ACCOUNTING}/invoices/`
export const ROUTE_INVOICE_EDIT_URL = `${ROUTE_BASE_URL}/${TAB_ACCOUNTING}/invoices/edit/`



export const ROUTER_FORMER_EMPLOYER_URL = `${ROUTE_BASE_URL}/${TAB_ADMIN}/contacts/`


export const ROUTER_EXPIRING_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/expiring_documents/`
export const ROUTER_EXPIRED_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/expired_documents/`
export const ROUTER_MISSING_DOCUMENTS_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/non_compliance/`
export const ROUTE_DRUG_TEST_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/drug_tests/`
export const ROUTE_EMPLOYEMENT_HISTORY_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/employment_histories/`
export const ROUTE_RESIDENT_HISTORY_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/resident_histories/`

export const ROUTE_VEHICLE_INSPECTION_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/vehicle_inspections/`
export const ROUTE_VEHICLE_MAINTENANCE_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/vehicle_maintenances/`
export const ROUTE_TRAFFIC_VIOLATION_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/traffic_violations/`
export const ROUTE_ACCIDENT_REGISTER_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/accident_registers/`
export const ROUTE_DRIVER_DISCIPLINARY_EVENT_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/event/`
export const ROUTE_MAINTENANCE_SCHEDULE_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/vehicle_maintenance/`

export const ROUTE_DRIVER_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/drivers/`
export const ROUTE_VEHICLE_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/vehicles/`
export const ROUTE_FLEET_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/fleets/`
export const ROUTE_EXPENSE_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/expenses/`
export const ROUTE_INVOICE_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/invoices/`
export const ROUTE_MAINTENANCE_RECORD_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/maintenance_records/`
export const ROUTE_MAINTENANCE_SCHEDULE_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/maintenance_schedules/`
export const ROUTE_DOCUMENT_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/documents/`
export const ROUTER_DRIVER_DISCIPLINARY_EVENT_URL = `${ROUTE_BASE_URL}/${TAB_SAFETY_AND_COMPLIANCE}/event/`
export const ROUTER_DRIVER_DISCIPLINARY_EVENT_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/disciplinary_event_report/`
export const ROUTE_IFTA_SUMMARY_REPORT_URL = `${ROUTE_BASE_URL}/${TAB_REPORT}/ifta_summary/`



export const ROUTE_DASHBOARD_URL = ROUTE_OVERVIEW_DASHBOARD_URL
export const ROUTE_PROFILE_URL = ROUTE_MY_ACCOUNT_URL
export const ROUTE_ADMIN_URL = ROUTE_CARRIERS_URL
export const ROUTE_FLEET_TAB_URL = ROUTE_FLEET_URL
export const ROUTE_ACCOUNTING_URL = ROUTE_PAY_CHECK_URL
export const ROUTE_SAFETY_AND_COMPLIANCE_URL = ROUTER_EXPIRING_URL
export const ROUTE_EXPENSE_TAB_URL = ROUTE_EXPENSE_URL
export const ROUTE_REPORT_URL = ROUTE_DRIVER_REPORT_URL

export const ROUTE_DRIVER_APP_URL = ROUTE_DRIVER_APP_DRIVER_PROFILE_URL

/**
 * @Important - Sequence of the objets matter for default behaviour e.g. on [TAB_DASHBOARD] value[DASHBOARD_COMPLINANCE_OVERVIEW] is default for the tab
 *  Sequenctial tab occurence either empty or returns one of the list of permissiable navlist.
 * On any occasion of tab update this list needs to be updated.
 * Order reversed.
 */
export const TAB_ROUTER_POLICY_LIST = {
   [TAB_DASHBOARD]: [
                     {policy_id: DASHBOARD_FINANCIAL_OVERVIEW, urlRoute: ROUTE_FINANCIAL_DASHBOARD_URL},
                     {policy_id: DASHBOARD_COMPLINANCE_OVERVIEW, urlRoute: ROUTE_OVERVIEW_DASHBOARD_URL },
                     
                    ],
   [TAB_REPORT]: [
                  {policy_id: REPORT_IFTA_SUMMARY, urlRoute: ROUTE_IFTA_SUMMARY_REPORT_URL},
                  {policy_id: REPORT_DRIVER_DISCIPLINARY, urlRoute: ROUTER_DRIVER_DISCIPLINARY_EVENT_REPORT_URL},
                  {policy_id: REPORT_MAINTENANCE_SCHEDULE, urlRoute: ROUTE_MAINTENANCE_SCHEDULE_REPORT_URL},
                  {policy_id: REPORT_MAINTENANCE_RECORD, urlRoute: ROUTE_MAINTENANCE_RECORD_REPORT_URL},
                  {policy_id: REPORT_INVOICES, urlRoute: ROUTE_INVOICE_REPORT_URL},
                  {policy_id: REPORT_EXPENSES, urlRoute: ROUTE_EXPENSE_REPORT_URL},
                  {policy_id: REPORT_FLEETS, urlRoute: ROUTE_FLEET_REPORT_URL},
                  {policy_id: REPORT_VEHICLES, urlRoute: ROUTE_VEHICLE_REPORT_URL},
                  {policy_id: REPORT_DRIVERS, urlRoute: ROUTE_DRIVER_REPORT_URL}, 
                  ],
   [TAB_ACCOUNTING]: [
                     {policy_id: FINANCE_INVOICE, urlRoute: ROUTE_INVOICE_URL},
                     {policy_id: FINANCE_PAYCHECK, urlRoute: ROUTE_PAY_CHECK_URL},
                      ],
   [TAB_SAFETY_AND_COMPLIANCE]: [
                  {policy_id: SAFETY_AND_COMPLINACE_MAINTENANCE_SCHEDULES, urlRoute: ROUTE_MAINTENANCE_SCHEDULE_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_DISCIPLINARY_EVENTS, urlRoute: ROUTE_DRIVER_DISCIPLINARY_EVENT_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS, urlRoute: ROUTE_ACCIDENT_REGISTER_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS, urlRoute: ROUTE_TRAFFIC_VIOLATION_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES, urlRoute: ROUTE_VEHICLE_MAINTENANCE_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS, urlRoute: ROUTE_VEHICLE_INSPECTION_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS, urlRoute: ROUTE_EMPLOYEMENT_HISTORY_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_DRUG_TESTS, urlRoute: ROUTE_DRUG_TEST_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_MISSING_DOCS, urlRoute: ROUTER_MISSING_DOCUMENTS_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_EXPIRED_DOCS, urlRoute: ROUTER_EXPIRED_URL},
                  {policy_id: SAFETY_AND_COMPLINACE_EXPIRING_DOCS, urlRoute: ROUTER_EXPIRING_URL}, 
                  ], 
   [TAB_EXPENSE]: [
                  {policy_id: EXPENSE_FUEL_IMPORTS, urlRoute: ROUTE_FUEL_IMPORT_URL},
                  {policy_id: EXPENSE_EXPNESES, urlRoute: ROUTE_EXPENSE_URL }, ], 
   [TAB_FLEET]:   [
                  {policy_id: FLEET_MILEAGE_IMPORTS, urlRoute: ROUTE_MILEAGE_IMPORT_URL},
                  {policy_id: FLEET_TRIPS, urlRoute: ROUTE_TRIP_URL},
                  {policy_id: FLEET_CUSTOMERS, urlRoute: ROUTE_CUSTOMER_URL }, 
                  {policy_id: FLEET_FLEETS, urlRoute: ROUTE_FLEET_URL }, ], 
   [TAB_DRIVER_APP]: [
                     {policy_id: DRIVER_APP_DOCUMENT_UPLOADS, urlRoute: ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL},
                     {policy_id: DRIVER_APP_EMPLOYMENT_APPLICATION, urlRoute: ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL }, 
                     {policy_id: DRIVER_APP_DRIVER_PROFILES, urlRoute: ROUTE_DRIVER_APP_DRIVER_PROFILE_URL}, ],
   [TAB_ADMIN]: [
                  {policy_id: ADMIN_INTEGRATION, urlRoute: ROUTE_INTEGRATION_URL }, 
                  {policy_id: ADMIN_CONTACTS, urlRoute: ROUTE_CONTACT_URL},
                  {policy_id: ADMIN_DISPATCHERS, urlRoute: ROUTE_DISPATCHER_URL }, 
                  {policy_id: ADMIN_VEHICLES, urlRoute: ROUTE_VEHICLE_URL},
                  {policy_id: ADMIN_FUEL_CARDS, urlRoute: ROUTE_FUEL_CARD_URL},
                  // {policy_id: ADMIN_DRIVERS, urlRoute: ROUTE_DRIVER_URL }, 
                  {policy_id: ADMIN_CARRIERS, urlRoute: ROUTE_CARRIERS_URL },
                  ], 
   [TAB_PROFILE]: []
}

export const getURLDocumentOwner = function (owner) {
   switch (owner) {
      case 'carrier':
         return {urlRoute: ROUTE_CARRIERS_URL, policy_id: ADMIN_CARRIERS}
      case 'vehicle':
         return {urlRoute: ROUTE_VEHICLE_URL, policy_id: ADMIN_VEHICLES}
      case 'driver':
         return {urlRoute: ROUTE_DRIVER_URL, policy_id: ADMIN_DRIVERS}
      case 'fuel_card':
         return  {urlRoute: ROUTE_FUEL_CARD_URL, policy_id: ADMIN_FUEL_CARDS}
      case 'trip':
         return {urlRoute: ROUTE_TRIP_URL, policy_id: FLEET_TRIPS}
      case 'vehicle_inspection':
         return {urlRoute: ROUTE_VEHICLE_INSPECTION_URL, policy_id: SAFETY_AND_COMPLINACE_VEHICLE_INSPECTIONS}
      case 'drug_test':
         return {urlRoute: ROUTE_DRUG_TEST_URL, policy_id: SAFETY_AND_COMPLINACE_DRUG_TESTS}
      case 'employment_history':
         return {urlRoute: ROUTE_EMPLOYEMENT_HISTORY_URL, policy_id: SAFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS}
      case 'accident_register':
         return {urlRoute: ROUTE_ACCIDENT_REGISTER_URL, policy_id: SAFETY_AND_COMPLINACE_ACCIDENT_REGISTERS}
      case 'traffic_violation':
         return {urlRoute: ROUTE_TRAFFIC_VIOLATION_URL, policy_id: SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS}
   }
}

// Compliance action - documents
export const ACTION_ADD_DOCUMENT = 'ADD_DOCUMENT'
export const ACTION_EDIT_DOCUMENT = 'EDIT_DOCUMENT'
export const ACTION_DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const ACTION_ARCHIVE_DOCUMENT = 'ARCHIVE_DOCUMENT'


//CHOICE
export const CHOICE_CARRIER = 'carrier'
export const CHOICE_VEHICLE = 'vehicle'
export const CHOICE_TRAILER = 'trailer' // We use vehicle for any logic this is just a place holder

export const CHOICE_DRIVER = 'driver'
export const CHOICE_FUEL_CARD = 'fuel_card'
export const SCHEDULE = 'schedule'
export const CHOICE_USER = 'profile'

export const CHOICE_TRIP = 'trip'

export const CHOICE_FUEL_IMPORT = 'fuel_import'
export const CHOICE_MILEAGE_IMPORT = 'trip'

export const CHOICE_PAY_CHECK = 'paycheck'
export const CHOICE_INVOICE = 'invoice'


export const CHOICE_DISPATCHER = 'dispatcher'
export const CHOICE_CUSTOMER = 'customer'
export const CHOICE_DOCUMENT = 'document'
export const CHOICE_FLEET = 'fleet'

export const CHOICE_EXPENSE = 'expense'
export const CHOICE_PROFILE = 'profile'
export const CHOICE_PERSONNEL = 'personnel'
export const CHOICE_COMPANY = 'company'
export const CHOICE_DRIVER_SAFETY_VIOLATION = 'event'
export const CHOICE_DRUG_TEST = 'drug_test'
export const CHOICE_TRAFFIC_VIOLATION = 'accident_or_violation_register'
export const CHOICE_ACCIDENT_REGISTER = 'accident_or_violation_register'
export const CHOICE_VEHICLE_INSPECTION = 'vehicle_inspection'
export const CHOICE_EMPLOYMENT_HISTORY = 'employment_history'
export const CHOICE_RESIDENT_HISTORY = 'resident_history'
export const CHOICE_DRIVER_EMPLOYMENT_APPLICATION = 'driver_employment_application'
export const CHOICE_DRIVER_UPLOAD_DOCUMENT = 'driver_upload_document'
export const CHOICE_DRIVER_DISCIPLINARY_EVENT = 'driver_disciplinary_event'
export const CHOICE_MAINTENANCE_RECORD = 'maintenance_recrods'
export const CHOICE_MAINTENANCE_SCHEDULE = 'maintenance_schedule'
export const CHOICE_MAINTENANCE_SCHEDULE_JOB = 'maintenance_schedule_job'
export const CHOICE_INTEGRATION = 'integration'



// Paycheck type
export const PAYCHECK_TYPE_INVOICE = 'INVOICE'
export const PAYCHECK_TYPE_PAYMENT = 'PAYMENT'


//Images
export const FLEET_IMG = 'https://d33wubrfki0l68.cloudfront.net/c0424a7841ee60bb9701a66e076420ef6518f086/d8257/assets/images/blog/lease-buy-cover.jpg'
export const EXPENSE_IMG = 'http://nwaba.org/wp-content/uploads/2017/06/red-dollar-sign-icon-1024x1024.png'
export const DRIVER_IMG = 'http://www.personalbrandingblog.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png'
export const VEHICLE_IMG = 'https://image.shutterstock.com/image-vector/semitrailer-truck-black-white-illustration-260nw-608865062.jpg'
export const DOCUMNET_NON_COMPLIANCE_IMG = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv819XFooI7-qNACnTuXixxYufSCM5WcR7n3j3N3at7_K22WmFiA'

export const AUTH_SIGN_IN = 'AUTH_SIGN_IN'
export const AUTH_SIGNED_IN = 'AUTH_SIGNED_IN'
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP'
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT'

// Updateable modules
export const PROFILE_UPDATE_MODULES = [CHOICE_CARRIER, CHOICE_VEHICLE,
   CHOICE_DRIVER, CHOICE_DISPATCHER, CHOICE_CUSTOMER, CHOICE_ACCIDENT_REGISTER
]

export const PROFILE_TYPE = {
   ADMIN: 'ADMIN',
   DISPATCHER: 'DISPATCHER',
   CARRIER: 'CARRIER'
}

export const CARRIER_TYPE = {
   ADMIN: 'ADMIN',
   LEASED: 'LEASED'
}



export const INVOICE_STATE = {
   CREATED: 'CREATED',
   INVOICED: 'INVOICED',
   PARTIAL_PAID: 'PARTIAL_PAID',
   PAID: 'PAID',
   OVERDUE: 'OVERDUE',
   CANCELED: 'CANCELED',
   ACTIVE: 'ACTIVE',
   INACTIVE: 'INACTIVE',
   DELETED: 'DELETED',
   ARCHIVED: 'ARCHIVED',
   STATE_COMPLETE:'COMPLETE',
   STATE_IN_PROGRESS:'IN_PROGRESS',
   IN_PROGRESS_PAYCHECK:'IN_PROGRESS'
}

export const ACTION_TABLE_DELETE_ROW = 'Delete'
export const ACTION_TABLE_ACTIVATE = 'Activate'
export const ACTION_TABLE_DEACTIVATE = 'Deactivate'
export const ACTION_TABLE_COMPLETE = 'Complete'

export const ACTION_TABLE_INCOMPLETE = 'In complete'

export const ACTION_TABLE_INPROGRESS = 'In progress'

export const ACTION_TABLE_INVOICE_CREATED = 'Created'
export const ACTION_TABLE_INVOICE_INVOICED = 'Invoiced'
export const ACTION_TABLE_INVOICE_OVERDUE = 'Overdue'

export const ACTION_TABLE_INVOICE_PARTIALLY_PAID = 'Partially Paid'
export const ACTION_TABLE_INVOICE_FULLY_PAID = 'Paid'
export const ACTION_TABLE_INVOICE_CANCELED = 'Canceled'
export const ACTION_TABLE_EMAIL_INVOICE = 'Email Invoice'
export const ACTION_TABLE_EXPENSIFY_PAYCHECK = 'Include with next paycheck'



export const ALL_ACTION_TABLES = [ACTION_TABLE_DELETE_ROW, ACTION_TABLE_ACTIVATE, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_COMPLETE,
   ACTION_TABLE_INPROGRESS, ACTION_TABLE_INVOICE_CREATED, ACTION_TABLE_INVOICE_INVOICED, ACTION_TABLE_INVOICE_OVERDUE,
   ACTION_TABLE_INVOICE_PARTIALLY_PAID, ACTION_TABLE_INVOICE_FULLY_PAID, ACTION_TABLE_INVOICE_CANCELED, ACTION_TABLE_EMAIL_INVOICE,
   ACTION_TABLE_EXPENSIFY_PAYCHECK]

// Rename INVOICE_STATE_MACHINE to universal state machine

export const INVOICE_STATE_MACHINE = {
   [INVOICE_STATE.CREATED]: [ACTION_TABLE_INVOICE_INVOICED, ACTION_TABLE_INVOICE_FULLY_PAID, ACTION_TABLE_INVOICE_CANCELED],
   [INVOICE_STATE.INVOICED]: [ACTION_TABLE_INVOICE_FULLY_PAID, ACTION_TABLE_INVOICE_CANCELED],
   [INVOICE_STATE.OVERDUE]: [INVOICE_STATE.CANCELED, INVOICE_STATE.PAID],
   [INVOICE_STATE.PAID]: [ACTION_TABLE_INVOICE_INVOICED],
   [INVOICE_STATE.CANCELED]: [ACTION_TABLE_INVOICE_INVOICED, ACTION_TABLE_INVOICE_FULLY_PAID],
   [INVOICE_STATE.ACTIVE]: [ACTION_TABLE_DEACTIVATE],
   [INVOICE_STATE.INACTIVE]: [ACTION_TABLE_ACTIVATE],
   [INVOICE_STATE.DELETED]: [],
   [INVOICE_STATE.ARCHIVED]: [ACTION_TABLE_ACTIVATE],
   [INVOICE_STATE.STATE_IN_PROGRESS]: [ACTION_TABLE_COMPLETE, ACTION_TABLE_INCOMPLETE],
   [INVOICE_STATE.IN_PROGRESS_PAYCHECK]: [ACTION_TABLE_COMPLETE],
}

export const INVOICE_STATE_MACHINE_SELECT = {
   [INVOICE_STATE.CREATED]: [{
         prettyName: ACTION_TABLE_INVOICE_CREATED,
         id: INVOICE_STATE.CREATED
      },
      {
         prettyName: ACTION_TABLE_INVOICE_INVOICED,
         id: INVOICE_STATE.INVOICED
      },
      {
         prettyName: ACTION_TABLE_INVOICE_FULLY_PAID,
         id: INVOICE_STATE.PAID
      },
      {
         prettyName: ACTION_TABLE_INVOICE_CANCELED,
         id: INVOICE_STATE.CANCELED
      }
   ],
   [INVOICE_STATE.INVOICED]: [{
         prettyName: ACTION_TABLE_INVOICE_INVOICED,
         id: INVOICE_STATE.INVOICED
      },
      {
         prettyName: ACTION_TABLE_INVOICE_FULLY_PAID,
         id: INVOICE_STATE.PAID
      },
      {
         prettyName: ACTION_TABLE_INVOICE_CANCELED,
         id: INVOICE_STATE.CANCELED
      }
   ],
   [INVOICE_STATE.OVERDUE]: [{
         prettyName: INVOICE_STATE.ACTION_TABLE_INVOICE_OVERDUE,
         id: INVOICE_STATE.OVERDUE
      },
      {
         prettyName: INVOICE_STATE.CANCELED,
         id: INVOICE_STATE.CANCELED
      },
      {
         prettyName: INVOICE_STATE.PAID,
         id: INVOICE_STATE.PAID
      }
   ],
   [INVOICE_STATE.PAID]: [{
         prettyName: ACTION_TABLE_INVOICE_FULLY_PAID,
         id: INVOICE_STATE.PAID
      },
      {
         prettyName: ACTION_TABLE_INVOICE_INVOICED,
         id: INVOICE_STATE.INVOICED
      }
   ],
   [INVOICE_STATE.CANCELED]: [{
         prettyName: ACTION_TABLE_INVOICE_CANCELED,
         id: INVOICE_STATE.CANCELED
      },
      {
         prettyName: ACTION_TABLE_INVOICE_INVOICED,
         id: INVOICE_STATE.INVOICED
      },
      {
         prettyName: ACTION_TABLE_INVOICE_FULLY_PAID,
         id: INVOICE_STATE.PAID
      }
   ],
   [INVOICE_STATE.ACTIVE]: [{
         prettyName: ACTION_TABLE_ACTIVATE,
         id: INVOICE_STATE.ACTIVE
      },
      {
         prettyName: ACTION_TABLE_DEACTIVATE,
         id: INVOICE_STATE.INACTIVE
      }
   ],
   [INVOICE_STATE.INACTIVE]: [{
         prettyName: ACTION_TABLE_DEACTIVATE,
         id: INVOICE_STATE.INACTIVE
      },
      {
         prettyName: ACTION_TABLE_ACTIVATE,
         id: INVOICE_STATE.ACTIVE
      },
   ]
}


export const SCHEDULE_TYPE = {
   RECURRING_EXPENSE: 'RECURRING_EXPENSE',
   PREVENTIVE_MAINTENANCE: 'PREVENTIVE_MAINTENANCE',
   PERIODIC_INSPECTION: 'PERIODIC_INSPECTION'
}



export const SCHEDULE_FREQUENCY_STRATEGY_TYPE = {
   DAYS: 'DAYS',
   WEEKLY: 'WEEKLY',
   BI_WEEKLY: 'BI_WEEKLY',
   FIRST_DAY_OF_THE_MONTH: 'FIRST_DAY_OF_THE_MONTH',
   LAST_DAY_OF_THE_MONTH: 'LAST_DAY_OF_THE_MONTH',
   MILEAGE: 'MILEAGE'
}

// Every 24 hours
export const PROFILE_DATA_REFRESH_INTERVAL = 10000

// Every Minute. Other calls might refresh data e.g adding dispatcher .. 
export const PROFILE_STATE_REFRESH_INTERVAL = 1* 60 *1000

export const HEALTH_CHECK_RECOVERY_CHECK_INTERVAL = 15 * 60 *1000


// Every 3 seconds
// Multiple account may be in sync 
export const ACCOUNT_INSYNC_PROFILE_REFRESH_INTERVAL = 3000 // P


// const PAGE_SIZE_OPTIONS = [6, 12, 18]; // Add more options for larger screens

export const getPageSize = function() {
   // Get the actual screen width and inner width
   const screenWidth = window.screen.width; // Total screen width
 
   // Define page size based on width
   if (screenWidth >= 2000) { // Desktops (large screens)
     return 18; // Return larger page size
   } else if (screenWidth >= 1200) { // Regular laptops (medium screens)
     return 12; // Return medium page size
   } else { // Small screens (tablets and smaller laptops)
     return 6; // Default page size
   }
 }
 

export const getPageSizeOptions = function () {
  return [getPageSize()];
}


export const SMALL_PAGE_SIZE = 5
export const SMALL_PAGE_SIZE_OPTIONS = [5]

//ACL ID
export const FMAX_FILE_ALLOWED = 10000000

export const CARRIER_CUSTOMER_TYPES = [{prettyName: 'BROKER'}, {prettyName: 'SHIPPER'}, {prettyName: 'CARRIER'}]

export const STG_DATE = ['DAYS', 'WEEKLY', 'BI_WEEKLY', 'FIRST_DAY_OF_THE_MONTH', 'LAST_DAY_OF_THE_MONTH']
export const STG_MILEAGE = ['MILEAGE']



// Message Constants 
export const DELETE_DOCUMENT_RECORD = "Delete Document Record"
export const SAVE_FUEL_IMPORT = "Upload Fuel Transactions"
export const CREATE_PAYCHEK_MSG = "Create Paycheck"
export const CREATE_INVOICE_MSG = "Create Invoice"

export const SAVE_MILEAGE_IMPORT_MSG = "Upload Trips & Mileages"
export const DELETE_MAINTENANCE_SCHEDULE_JOBS_MSG = "Delete Maintenance Schedules"

//methods DELETE, SAVE
export const getModuleTitle = function(module, method) {
  let title = `${method || ""} ${module || ""}`.trim();
  return title

}

export const ALL_COLORS1  = [ "#17a2b8", "#28a745", "#fd7e14", "#fd7e14", "#20c997",];

export const ALL_COLORS = ["#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411"]

export const STATUS_COLORS = [ "#28a745", "#dc3912"]
export const INCOME_COLORS = [ "#28a745"]
export const EXPENSE_COLORS = ["#ff9900"]
// export const EXPENSE_COLORS = [ "#fcb900", "#fcb901", '#f27b35', '#d96704']

export const MAINTENANCE_COLORS = ["#fcb900"]
// export const MAINTENANCE_COLORS = ["#ff9900","#fcb900", "#fcb901", '#f27b35', '#d96704']
export const INVOICE_COLORS = ["#28a745", "#307D7E", "#6abbf8", "#dc3912"]
export const EXPENSE_FEE_COLORS= ["#ea4c89"]

export const CARRIER_COLORS = ["#4CAF50", "#388E3C", "#2E7D32", "#1B5E20", "#66BB6A", "#43A047", "#00701A", "#33691E", "#558B2F", "#9CCC65", "#76FF03", "#64DD17", "#2E7D32", "#1B5E20", "#43A047"];

export const DRIVER_COLORS = ["#2196F3", "#03A9F4", "#0277BD", "#01579B", "#1E88E5", "#0D47A1", "#1976D2", "#42A5F5", "#29B6F6", "#039BE5", "#0288D1", "#1E88E5", "#0D47A1", "#1976D2", "#2196F3", "#0277BD", "#42A5F5", "#01579B", "#03A9F4", "#1E88E5"];

export const TRUCK_COLORS = ["#9E9E9E", "#424242", "#212121", "#757575", "#616161", "#7A7A7A", "#555555", "#3B3B3B", "#C0C0C0", "#ABABAB", "#868686", "#6C6C6C", "#525252", "#383838", "#1E1E1E", "#9E9E9E", "#555555", "#212121", "#616161", "#424242"];

export const TRAILER_COLORS = ["#FF5722", "#E65100", "#EF6C00", "#FF9800", "#F57C00", "#FFA726", "#FFAB00", "#FFC107", "#F57F17", "#BF360C", "#FF5722", "#F57C00", "#FF9800", "#E65100", "#EF6C00", "#FF5722", "#F57C00", "#FFA726", "#BF360C", "#FFC107"];

export const DISPATCHER_COLORS = ["#673AB7", "#7E57C2", "#5E35B1", "#512DA8", "#4527A0", "#9575CD", "#B39DDB", "#BA68C8", "#AB47BC", "#7E57C2"];


export const PROIFE_SUBSCRIPTION_CHOICES = [
   {prettyName: 'Owner Operator ( 1 Truck )', id: 'PRICING_OOP'}, 
   {prettyName: 'Small Feet ( 2 - 5 Trucks )', id: 'PRICING_SMALL_FLEET'}, 
   {prettyName: 'Small-Medium Fleet ( 6 - 12 Trucks )', id: 'PRICING_SMALL_MEDIUM_FLEET'}, 
   {prettyName: 'Medium Fleet ( 13 - 20 Trucks )', id: 'PRICING_MEDIUM_FLEET'},
   {prettyName: 'Medium-Large Fleet ( 21 - 30 Trucks )', id: 'PRICING_MEDIUM_LARGE_FLEET'}, 
   {prettyName: 'Large Fleet (31+ Trucks )', id: 'PRICING_LARGE_FLEET'}, 
]


export const getPricing = function(key) {
   return PROIFE_SUBSCRIPTION_CHOICES[key]
}

export const EmploymentVerificationStatus = {
   DRAFT: 'DRAFT',
   PENDING_DRIVER_CONSENT: 'PENDING_DRIVER_CONSENT',
   PENDING_VERIFICATION: 'PENDING_VERIFICATION',
   VERIFIED: 'VERIFIED',
   VERIFICATION_NOT_RESPONDED: 'VERIFICATION_NOT_RESPONDED',
   UN_VERIFIABLE: 'UN_VERIFIABLE',
   DENIED_OR_REJECTED: 'DENIED_OR_REJECTED'
}


export const INFO_COLOR = "#E8F4FD"; // Light blue for information (Alert info)
export const ERROR_COLOR = "#FDECEA"; // Light red for errors (Alert error)
export const WARNING_COLOR = "#FFF4E5"; // Light orange for warnings (Alert warning)
export const SUCCESS_COLOR = "#EDF7ED"; // Light green for success (Alert success)
export const ATTENTION_REQUIRED_COLOR = "#FFFDE7"; // Soft yellow for attention required
export const NEUTRAL_COLOR = "#F5F5F5"; // Neutral gray for less prominent alerts
export const PRIMARY_COLOR = "#ECEFF1"; // Light gray-blue for primary (optional)


export const ALERT_INFO = "info";
export const ALERT_ERROR = "error"; // Light red for errors (Alert error)
export const ALERT_WARNING = "warning"; // Light orange for warnings (Alert warning)
export const ALERT_SUCCESS = "success"; // Light green for success (Alert success)
export const ALERT_ATTENTION = 'attention'


export const STATUS_CONFIG = {
   ACTIVE: { label: "Active", color: SUCCESS_COLOR, alertType: ALERT_SUCCESS },
   INACTIVE: { label: "Inactive", color: ERROR_COLOR, alertType: ALERT_ERROR },
   ARCHIVED: { label: "Archived", color: INFO_COLOR, alertType: ALERT_INFO },
 };