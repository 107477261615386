import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FInput from "../widgets/FInput";
import FDate from "../widgets/FDate";
import FFileUpload from "../widgets/FFileUpload";

import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { VALIDATE_POSITIVE, VALIDATE_REQUIRED, VALIDATE_DATE, VALIDATE_FEE, VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import { API_FLEET_URL, CHOICE_FLEET, API_DOCUMENTS_URL, API_CUSTOMER_URL, API_CARRIERS_URL, API_DRIVERS_URL, API_DISPATCHERS_URL, API_VEHICLES_URL, TAB_FLEET, FLEET_FLEETS, CompanyCode } from "../../util/Const";
import FMessage from "../widgets/FMessage";
import FCheckBox from "../widgets/FCheckBox";
import DocumentView from "../document"
import FAutoComplete from "../widgets/FAutoComplete";
import CustomerPopUpView from "../customer/CustomerPopUp";


const styles = theme => ({

    padding: {
        marginTop: theme.spacing(4),
    },
    root1: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
});

const PROPS = {
    data: {
        fleetLoadNumber: '',
        fleetOtherReferenceNumber: '',
        status: 'IN_PROGRESS',
        fleetPickUpDate: '',
        fleetPickupLocation: '',
        fleetDistance: 0,
        fleetWeight: 0,
        // fleetCustomer: '',
        fleetDeliveryDate: '',
        fleetDeliveryLocation: '',
        vehicle: {},
        driver: {},
        dispatcher: {},
        customer: {},
        carrier:{},
        fleetLoadFee: 0,
        fleetLumperFee: 0,
        fleetDetantionFee: 0,
        fleetNoServiceFee: 0,
        fleetMiscFee: 0,
        fleetConfirmation: '',
        fleetBOL: '',
        fleetPayCheckProcessingDate: '',
        // fleetInvoiceProcessingDate: '',
        fleetPaymentTransactionDate: '',

        disc: ''
    },
    errorData: {
        fleetLoadNumber: false,
        fleetOtherReferenceNumber: false,
        status: '',
        fleetPickUpDate: false,
        fleetPickupLocation: false,
        fleetWeight: false,
        fleetDistance: false,
        // fleetCustomer: false,
        fleetDeliveryDate: false,
        fleetDeliveryLocation: false,
        vehicle: false,
        driver: false,
        carrier: false,
        dispatcher: false,
        customer: false,
        fleetLoadFee: false,

        // fleetLumperIsDriver: false,
        fleetLumperFee: false,
        fleetDetantionFee: false,
        fleetNoServiceFee: false,
        fleetMiscFee: false,

        fleetConfirmation: false,
        fleetBOL: false,
        fleetPayCheckProcessingDate: false,
        // fleetInvoiceProcessingDate: false,
        fleetPaymentTransactionDate: false,
        disc: false
    },
    errorMessage: {
        fleetLoadNumber: 'Invalid Reference Number',
        status: ['Invalid Fleet status, bol is required to mark complete'],
        fleetOtherReferenceNumber: 'Invalid Reference Number',
        fleetPickUpDate: 'Invalid Date',
        fleetPickupLocation: 'Invalid Location Name, City, State',
        fleetDistance: 'Invalid Distance/Mileage',
        fleetWeight: 'Invalid Distance/Mileage',
        // fleetCustomer: 'Invalid customer',
        fleetDeliveryDate: 'Invalid date',
        fleetDeliveryLocation: 'Invalid Location Name, City, State',
        vehicle: 'No Vehicle Selected/Found  Under Carrier',
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',
        dispatcher: 'No Dispatcher Selected/Found  Under Carrier',
        customer: 'No Customer Selected/Found  Under Carrier',
        fleetLoadFee: 'Invalid Load Fee',
        // fleetLumperIsDriver: 'Invalid Lumper',
        fleetLumperFee: 'Invalid Lumper Fee',
        fleetDetantionFee: 'Invalid Detantion Fee',
        fleetNoServiceFee: 'Invalid No Service Fee ( No Truck Used Fee)',
        fleetMiscFee: 'Invalid Miscellaneous Fee ',

        fleetConfirmation: 'Invalid Confirmation file',
        fleetPayCheckProcessingDate: 'Invalid Paycheck Transaction Date',
        fleetPaymentTransactionDate: 'Invalid Payment Transaction Date',
        fleetBOL: 'Invalid BOL',
        disc: 'Invalid Driver'
    },
    touched: {
        // customer: true
    },
    validators: {
        fleetLoadNumber: [VALIDATE_REQUIRED],
        fleetOtherReferenceNumber: [],
        status: [VALIDATE_REQUIRED],
        fleetPickUpDate: [VALIDATE_DATE],
        fleetPickupLocation: [VALIDATE_REQUIRED],
        fleetDistance: [],
        fleetWeight: [],
        // fleetCustomer: [VALIDATE_REQUIRED],
        fleetDeliveryDate: [VALIDATE_REQUIRED],
        fleetDeliveryLocation: [VALIDATE_REQUIRED],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        customer: [VALIDATE_OBJECT_REQUIRED],
        dispatcher: [VALIDATE_OBJECT_REQUIRED],
        fleetLoadFee: [VALIDATE_FEE],
        // fleetLumperIsDriver: [],
        fleetLumperFee: [VALIDATE_FEE],
        fleetDetantionFee: [VALIDATE_FEE],
        fleetNoServiceFee: [VALIDATE_FEE],
        fleetMiscFee: [VALIDATE_FEE],
        fleetConfirmation: [VALIDATE_REQUIRED],
        fleetPayCheckProcessingDate: [],
        // fleetInvoiceProcessingDate: [],
        fleetPaymentTransactionDate: [],
        fleetBOL: [],
        disc: []
    }
}

class FleetDetial extends FDetailComponent {
    constructor(props) {
        super(props, API_FLEET_URL, CHOICE_FLEET, PROPS)
    }

    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('vehicle', {vehicle: ''})
        this.updateValues('customer', {customer: ''})
        this.updateValues('dispatcher', {dispatcher: ''})
    }
    
    getCurrentTitle = () => {
        let title = 'Fleet ' + this.state.data.fleetLoadNumber
        this.props.updateCurrentTab(TAB_FLEET)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    render() {
        const { classes } = this.props;
        this.getCurrentTitle()

        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())

        return (
            <Grid container direction='column' alignItems='stretch'  spacing={2} className={classes.root1}>
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                  
                    <Grid container  spacing={2}  xs={8}>
                        <Grid item xs>
                            <FAutoComplete
                                url={API_CARRIERS_URL}
                                name='carrier'
                                hasError={this.state.errorData.carrier}
                                helpMessage={this.state.errorMessage.carrier}
                                label='Authority'
                                // queryParam='search'
                                value={this.state.data.carrier}
                                defaultValue={this.state.data.carrier}
                                notifyParent={this.updateValues}
                                disabled={!this.state.isNew}
                                notifyDependency={this.onCarrierChange}

                                />
                        
                        </Grid>
                        <Grid item xs>
                            <FInput name='fleetLoadNumber'
                                value={this.state.data.fleetLoadNumber}
                                hasError={this.state.errorData.fleetLoadNumber}
                                helpMessage={this.state.errorMessage.fleetLoadNumber}
                                label="Load/PO/Reference Number" notifyParent={this.updateValues}
                            />
                        </Grid>

                        </Grid>
                    <Grid container  spacing={3} justify="space-evenly" xs={12}>

                      
                        <Grid item xs>
                            <FAutoComplete
                                url={API_VEHICLES_URL}
                                queryParam={'vehicleType=TRUCK'}
                                hasCarrier={true}
                                carrier = {this.state
                                    .data.carrier}
                                name='vehicle'
                                hasError={this.state.errorData.vehicle}
                                helpMessage={this.state.errorMessage.vehicle}
                                label='Truck'
                                // queryParam='search'
                                value={this.state.data.vehicle}
                                defaultValue={this.state.data.vehicle}
                                notifyParent={this.updateValues}
                                // disabled={!this.state.isNew}
                                />
                            
                        </Grid>
                        <Grid item xs>
                          <FAutoComplete
                                url={API_DRIVERS_URL}
                                // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                hasCarrier={true}
                                carrier={this.state.data.carrier}
                                name='driver'
                                hasError={this.state.errorData.driver}
                                helpMessage={this.state.errorMessage.driver}
                                label='Driver'
                                // queryParam='search'
                                value={this.state.data.driver}
                                defaultValue={this.state.data.driver}
                                notifyParent={this.updateValues}
                                // disabled={!this.state.isNew}
                                />
                            
                        </Grid>
                        <Grid item xs>
                         <FAutoComplete
                                url={API_DISPATCHERS_URL}
                                // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                hasCarrier={true}
                                carrier={this.state.data.carrier}
                                name='dispatcher'
                                hasError={this.state.errorData.dispatcher}
                                helpMessage={this.state.errorMessage.dispatcher}
                                label='Dispatcher'
                                // queryParam='search'
                                value={this.state.data.dispatcher}
                                defaultValue={this.state.data.dispatcher}
                                notifyParent={this.updateValues}
                                // disabled={!this.state.isNew}
                                />
                        </Grid>
                        <Grid item xs>
                            <Grid container>
                                    <Grid item  xs>
                                            <FAutoComplete
                                                    url={API_CUSTOMER_URL}
                                                    // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                                    hasCarrier={true}
                                                    carrier={this.state.data.carrier}
                                                    name='customer'
                                                    hasError={this.state.errorData.customer}
                                                    helpMessage={this.state.errorMessage.customer}
                                                    label='Billing Customer'
                                                    // queryParam='search'
                                                    value={this.state.data.customer}
                                                    defaultValue={this.state.data.customer}
                                                    notifyParent={this.updateValues}
                                                    // disabled={!this.state.isNew}
                                                    showAdd={true} 
                                                    // Onclick the add button 
                                                    onEdit={this.onShowAddCustomer}
                                                    />
                                        </Grid>
                                        <Grid item  xs>
                                                <CustomerPopUpView
                                                    dialogEditMode={this.state.showAddCustomer}
                                                    companyCode = {CompanyCode.SERVICE_PROVIDER}
                                                    notifyDialogClose = {() => this.setState({showAddCustomer: false})}
                                                    choices={this.props.customerTypeChoice}
                                                    title={'Customer Info'}
                                                    carrier = {this.state.data.carrier}
                                                />
                                        </Grid>
                            </Grid>
                        </Grid>
                       
                    </Grid>
                   
                    <Grid container  spacing={3} xs={12}>
                        {/*
                         TODO (Estifanos) once figured out timezone issue use this timedate.
                        <Grid item xs>
                          
                         <FDateTime
                            name='fleetPickUpDate' 
                            required={true}
                            defaultValue={this.state.data.fleetPickUpDate}
                            hasError={this.state.errorData.fleetPickUpDate}
                            helpMessage={this.state.errorMessage.fleetPickUpDate}
                            picker='year'
                            label="Pickup Datee "
                            notifyParent={this.updateValues}
                        />        
                        </Grid> */}
                        <Grid item xs>
                            <FDate
                                name='fleetPickUpDate'
                                required={true}
                                defaultValue={this.state.data.fleetPickUpDate}
                                hasError={this.state.errorData.fleetPickUpDate}
                                helpMessage={this.state.errorMessage.fleetPickUpDate}
                                label="Pickup Date "
                                notifyParent={this.updateValues} />
                        </Grid>

                        <Grid item xs>
                            <FInput name='fleetPickupLocation'
                                value={this.state.data.fleetPickupLocation}
                                hasError={this.state.errorData.fleetPickupLocation}
                                helpMessage={this.state.errorMessage.fleetPickupLocation}
                                label="Pickup Location" notifyParent={this.updateValues}
                            />
                        </Grid>
                        <Grid item xs>
                            <FDate
                                name='fleetDeliveryDate'
                                required={true}
                                defaultValue={this.state.data.fleetDeliveryDate}
                                hasError={this.state.errorData.fleetDeliveryDate}
                                helpMessage={this.state.errorMessage.fleetDeliveryDate}
                                label="Delivery Date "
                                notifyParent={this.updateValues} />
                        </Grid>

                        <Grid item xs>
                            <FInput name='fleetDeliveryLocation'
                                value={this.state.data.fleetDeliveryLocation}
                                hasError={this.state.errorData.fleetDeliveryLocation}
                                helpMessage={this.state.errorMessage.fleetDeliveryLocation}
                                label="Delivery Location" notifyParent={this.updateValues}
                            />
                        </Grid>
                        <Grid item xs>
                            <FInput name='fleetLoadFee'
                                value={this.state.data.fleetLoadFee}
                                hasError={this.state.errorData.fleetLoadFee}
                                helpMessage={this.state.errorMessage.fleetLoadFee}
                                label="Load Fee" notifyParent={this.updateValues}
                            />
                        </Grid>
                        
                    </Grid>
                  
                    <Grid container  spacing={3} xs={12}>
                    <Grid item  xs>
                            <FInput name='fleetWeight' type='number'
                                value={this.state.data.fleetWeight}
                                hasError={this.state.errorData.fleetWeight}
                                helpMessage={this.state.errorMessage.fleetWeight}
                                label="Load Weight" notifyParent={this.updateValues}
                            />
                        </Grid>
                    <Grid item xs>
                            <FInput name='fleetDistance' type='number'
                                value={this.state.data.fleetDistance}
                                hasError={this.state.errorData.fleetDistance}
                                helpMessage={this.state.errorMessage.fleetDistance}
                                label="Distance" notifyParent={this.updateValues}
                            />
                        </Grid>

                        <Grid item xs>
                            <FFileUpload
                                value={this.state.data.fleetConfirmation}
                                name='fleetConfirmation'
                                hasError={this.state.errorData.fleetConfirmation}
                                helpMessage={this.state.errorMessage.fleetConfirmation}
                                label="Upload Confirmation"
                                notifyParent={this.updateValues}
                            />
                        </Grid>
                        <Grid item xs>
                            <FFileUpload
                                value={this.state.data.fleetBOL}
                                name='fleetBOL'
                                hasError={this.state.errorData.fleetBOL}
                                helpMessage={this.state.errorMessage.fleetBOL}
                                label="Upload BOL"
                                notifyParent={this.updateValues} />
                        </Grid>

                      
                    </Grid>
                    <Grid container  spacing={3} xs={12}>
                        {/* <Grid item xs>
                            <FCheckBox
                                name='fleetLumperIsDriver'
                                value={this.state.data.fleetLumperIsDriver}
                                hasError={this.state.errorData.fleetLumperIsDriver}
                                helpMessage={this.state.errorMessage.fleetLumperIsDriver}
                                label="Driver-Handled Lumper Task"
                                notifyParent={this.updateValues} />
                        </Grid> */}
                        <Grid item xs>
                            <FInput name='fleetLumperFee' type='number'
                                value={this.state.data.fleetLumperFee}
                                hasError={this.state.errorData.fleetLumperFee}
                                helpMessage={this.state.errorMessage.fleetLumperFee}
                                label="Lumper Fee" notifyParent={this.updateValues}
                            />
                        </Grid>

                        <Grid item xs>
                            <FInput name='fleetDetantionFee' type='number'
                                value={this.state.data.fleetDetantionFee}
                                hasError={this.state.errorData.fleetDetantionFee}
                                helpMessage={this.state.errorMessage.fleetDetantionFee}
                                label="Detantion Fee" notifyParent={this.updateValues}
                            />
                        </Grid>
                        <Grid item xs>
                            <FInput name='fleetNoServiceFee' type='number'
                                value={this.state.data.fleetNoServiceFee}
                                hasError={this.state.errorData.fleetNoServiceFee}
                                helpMessage={this.state.errorMessage.fleetNoServiceFee}
                                label="No Service Fee ( Truck No Used Fee )" notifyParent={this.updateValues}
                            />
                        </Grid>
                        <Grid item xs>
                            <FInput name='fleetMiscFee' type='number'
                                value={this.state.data.fleetMiscFee}
                                hasError={this.state.errorData.fleetMiscFee}
                                helpMessage={this.state.errorMessage.fleetMiscFee}
                                label="Other Invoiceable Fees" notifyParent={this.updateValues}
                            />
                        </Grid>
                    </Grid>
                
                    <Grid container  spacing={2} xs={12} style={{paddingTop: '5px'}}
                        justify='space-between' >
                        <Grid item xs>
                            <FDate
                                name='fleetPayCheckProcessingDate'
                                required={true}
                                defaultValue={this.state.data.fleetPayCheckProcessingDate}
                                hasError={this.state.errorData.fleetPayCheckProcessingDate}
                                helpMessage={this.state.errorMessage.fleetPayCheckProcessingDate}
                                label="PayCheck Processing Date "
                                notifyParent={this.updateValues} />
                        </Grid>
                        {/* <Grid item xs>
                            <FDate
                                name='fleetInvoiceProcessingDate'
                                required={true}
                                defaultValue={this.state.data.fleetInvoiceProcessingDate}
                                hasError={this.state.errorData.fleetInvoiceProcessingDate}
                                helpMessage={this.state.errorMessage.fleetInvoiceProcessingDate}
                                label="Invoice Processing Date "
                                notifyParent={this.updateValues} />
                        </Grid> */}
                          
                            <Grid item xs>
                                <FSelect
                                    
                                    name='status'
                                    value={this.state.data.status}
                                    hasError={this.state.errorData.status}
                                    helpMessage={this.state.errorMessage.status}
                                    label='Fleet Status'
                                    style={{width: 400}}
                                    data={this.props.statusChoice}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                    </Grid>
                    <Grid container  spacing={3} xs={12} >
                           
                            <Grid item  xs>
                                <FInput
                                        name='desc' value={this.state.data.desc} multiline={true}
                                        value={this.state.data.desc}
                                        hasError={this.state.errorData.desc}
                                        helpMessage={this.state.errorMessage.desc}
                                        label="Description" notifyParent={this.updateValues} />
                                </Grid>
               
                </Grid>
                <Grid item container xs={8} >
                        {this.getUpdateButton(this.props.profile, FLEET_FLEETS)}
                </Grid>
                {/* <Grid item container xs={12} >
                    <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                    <Grid item style={{ paddingTop: '9px' }}>
                       <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                    </Grid>
                    
                </Grid> */}
                <Grid item xs className={classes.padding}>
                    {!this.state.isNew &&
                        <DocumentView
                            ownerId={this.props.match.params.id}
                            owner={this.state.data}
                            listUrl={API_DOCUMENTS_URL + `?&fleet=${this.props.match.params.id}`}
                            ownerType='fleet'
                            policy_id={FLEET_FLEETS}
                        />}
                </Grid> 
           </Grid >
        );
    };
}

FleetDetial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(FleetDetial))
