import React from 'react';
import { API_DRIVER_PROFILE_URL, CHOICE_DRIVER, TAB_DRIVER_APP, ADMIN_DRIVERS, ROUTE_DRIVER_URL, ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL, DRIVER_APP_DRIVER_PROFILES, API_DRIVERS_URL } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import BlockIcon from '@material-ui/icons/Block';

import { getDriverType, getEmploymentVerificationReport } from '../../util/icon_util';
import FMaterialTableNew from '../widgets/FMaterialTableNew';
import FTableActionNew from '../widgets/FTableActionNew';
import FToolTip from '../widgets/FTooltip';
import FAlert from '../widgets/FAlert';


class DriverProfiles extends FListComponent {

  constructor(props) {
    super(props, API_DRIVER_PROFILE_URL, CHOICE_DRIVER)

    this.currentTitle = 'Driver Accounts'
    this.currentTab = TAB_DRIVER_APP
    this.routeUrl = ROUTE_DRIVER_APP_DRIVER_PROFILE_ADD_URL
    this.policyID = DRIVER_APP_DRIVER_PROFILES
  }

  renderLatestTruck = (assignedVehicles) => {
    if (!assignedVehicles || !Array.isArray(assignedVehicles)) return null;

    const trucks = assignedVehicles.filter(vehicle => vehicle.vehicleType === 'TRUCK');
    if (trucks.length === 0) return null;

    const latestTruck = trucks[trucks.length - 1]; // Or trucks[0]

    return this.getVehicleInfoEditLink(latestTruck);
  };

  renderLatestTrailer = (assignedVehicles) => {
    if (!assignedVehicles || !Array.isArray(assignedVehicles)) return null;

    const trailers = assignedVehicles.filter(vehicle => vehicle.vehicleType === 'TRAILER');
    if (trailers.length === 0) return null;

    const latestTrailer = trailers[trailers.length - 1]; // Or trailers[0]

    return this.getVehicleInfoEditLink(latestTrailer);
  };

  onTableAction = (actionId, rowData) => {
    let data = { id: rowData.id };
    let url = this.getListURL() + rowData.id + '/event/';

    switch (actionId) {
      case "ACTION_DELETE":
        this.deleteRow(rowData);
        break;
      case "ACTION_INACTIVE":
        this.updateRow(rowData, { state: "INACTIVE" });
        break;
      case "ACTION_ACTIVE":
        this.updateRow(rowData, { state: "ACTIVE" });
        break;
      case "REQUEST_DRIVER_SUBMIT_EMPLOYMENT_APPLICATION":
      case "REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_CDL":
      case "REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_MVR":
      case "REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_MEDICAL_CARD":
      case "REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_W9":
      case "REQUEST_DRIVER_DISCIPLINARY_ACTION_POLICY":
      case "REQUEST_DRIVER_HOS_POLICY":
      case "REQUEST_DRIVER_DRUG_AND_ALCOHOL_POLICY":
      case "REQUEST_DRIVER_HIRING_POLICY":
      case "REQUEST_VEHICLE_OPERATOR_AND_CARRIER_LEASE_AGREEMENT_POLICY":
      case "REQUEST_TRUCK_RECORD":
      case "REQUEST_TRAILER_RECORD":
      case "REQUEST_DRIVER_ROAD_TEST":
        this.actionHandler(url, { ...data, eventType: actionId });
        break;
      default:
        console.warn(`Unhandled action: ${actionId}`);
    }
  };

  render() {
    return (
      <div>
        <FMaterialTableNew
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Driver',
              render: this.getDriverInfoEditLink
            },
            {
              title: 'Driver Type', render: rowData => {
                return (
                  <React.Fragment>
                    {getDriverType(rowData.driverInfo)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Latest Truck',
              render: (rowData) => {
                return this.renderLatestTruck(rowData.assignedVehicles);
              },
            },
            {
              title: 'Latest Trailer',
              render: (rowData) => {
                return this.renderLatestTrailer(rowData.assignedVehicles);
              },
            },
            // {
            //   title: 'Carrier',
            //   render: this.getSelfCarrierLink
            // },
            {
              title: 'Email',
              fields: 'driverInfo',
              render: rowData => {
                const { email } = rowData.driverInfo?.contact
                return (
                  <p>
                    <p> {email} </p>
                  </p>
                );
              }
            },
            {
              title: 'Phone',
              fields: 'driverInfo',
              render: rowData => {
                const { phoneNumber } = rowData.driverInfo?.contact
                return (
                  <p> {phoneNumber} </p>
                );
              }
            },
            {
              title: 'CDL',
              field: 'driverInfo',
              render: rowData => {
                const { cdl, cdlIssuedDate, cdlIssuedState } = rowData.driverInfo?.driver || {};

                if (!cdl) return null; // Ensure CDL exists before rendering

                return (
                  <FToolTip
                    content={
                      <div>
                        <strong>CDL Details:</strong>
                        <br />
                        <br />
                        <strong>ID: </strong> {cdl} <br />
                        <strong>Issued Date: </strong> {this.getPrettyDate(cdlIssuedDate)} <br />
                        <strong>Issued State: </strong> {cdlIssuedState} <br />
                      </div>
                    }
                  >
                    <FAlert alertType="neutral">
                      {cdl}&nbsp;|&nbsp;{cdlIssuedState}
                    </FAlert>
                  </FToolTip>
                );
              }
            },

            {
              title: 'Employment Verification Status',
              fields: 'verificationInfo',
              render: rowData => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
                    <span style={{ fontWeight: 'bold', color: '#333', marginRight: 3 }}>
                      {getEmploymentVerificationReport(rowData)}
                    </span>
                  </div>
                );
              }
            },


            {
              title: 'Actions',
              render: rowData => {
                const actions = rowData.workflowActionItems || [];

                return (
                  <React.Fragment>
                    {!!actions.length ? (
                      <FTableActionNew
                        actions={actions}
                        onTableAction={this.onTableAction}
                        rowData={rowData}
                      />
                    ) : (
                      <BlockIcon color="disabled" titleAccess="No Actions Available" />
                    )}
                  </React.Fragment>
                );
              }
            }
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          options={{
            pageSize: this.state.pageSize,
            pageSizeOptions: this.state.pageSizeOptions,
            actionsColumnIndex: -1
          }}
          data={this.fetchData}
          title="Accounts"
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(DriverProfiles)
