import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#333",
    border: "1px solid #ccc",
    padding: "15px",
    fontSize: "16px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    maxWidth: "500px",
  },
  arrow: {
    color: "#ccc",
  },
}));

const FToolTip = ({ children, content }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={<div style={{ textAlign: "left" }}>{content}</div>}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      arrow
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default FToolTip;
