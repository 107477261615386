import React from 'react';
import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, Grid, CircularProgress, Paper } from '@material-ui/core';
import FInput from '../widgets/FInput';
import FDetailComponent from '../../util/FDetailComponent';
import FSelect from '../widgets/FSelect';
import { connect } from 'react-redux';
import { VALIDATE_REQUIRED, VALIDATE_EMAIL, VALIDATE_PHONE_NUMBER, VALIDATE_PASSWORD } from '../../util/ValidatorConst';
import { API_AUTH_SIGNUP_CARRIER_OWNERS, RECAPCH_SITE_KEY, ZTRUCKING_WEBSITE } from '../../util/Const';
import { isValid } from '../../util/Validator';
import FRecaptcha from '../widgets/FRecaptcha';
import { getErrroMessage } from '../widgets/FMessage';
import { UPDATE } from '../../util/FAPI';
import logo from './logo_zt_square.png';
import SignupSQPaymentForm from '../square_payment/SignupSQPaymentForm';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';

const styles = (theme) => ({
  root: {
    backgroundColor: '#e3f2fd',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row', // Side-by-side layout on larger screens
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', // Stack on small screens
    },
  },
  imageSection: {
    width: '60%', // 50% of the page for the image on large screens
    backgroundImage: `url('/truck.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the image on small screens
    },
  },
  formSection: {
    margin: '2em',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4), // Adjust padding
    backgroundColor: '#ffffff', // White background for the form
    position: 'relative', // For absolute positioning of the logo
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for 3D effect
    borderRadius: '8px', // Rounded corners for a modern look
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Full width for the form section on small screens
      padding: theme.spacing(2), // Reduce padding for small screens
      marginTop: theme.spacing(2), // Add space between header and form
      margin: '0.01em',
    },
  },
  logo: {
    width: '90px',
    height: '90px',
    position: 'relative', // Keep logo in the top-right corner
    top: theme.spacing(.1),
    right: theme.spacing(2),
    marginBottom: theme.spacing(2), // Space from top
    zIndex: 10, // Keep logo on top
    [theme.breakpoints.down('sm')]: {
      position: 'relative', // Move logo to be relative on small screens
      top: '0', // Adjust logo position for small screens
      right: '0',
      marginBottom: theme.spacing(1), // Space from top
    },
  },
  header: {
    textAlign: 'center', // Align the header text to the left
    marginTop: theme.spacing(1), // Space from top
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'center',
    maxWidth: '100%',
    lineHeight: '1.4', // Adjust line height for better spacing
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '1.3', // Slightly tighter spacing on small screens
    },
  },

  formContainer: {
    width: '100%',
    // maxWidth: '500px', // Limit the width of the form
  },
  inputField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  footer: {
    marginTop: 'auto', // Push footer to the bottom
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  recaptchaContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center', // Center reCAPTCHA
  },
  paymentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center', // Center the payment form
    marginBottom: theme.spacing(2),
  },
  alreadyMember: {
    paddingTop: theme.spacing(2),
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  errorContainer: {
    backgroundColor: theme.palette.error.light, // Light red background
    color: theme.palette.error.contrastText, // Use contrast text for better visibility
    padding: theme.spacing(1),
    borderRadius: '5px',
    // marginTop: theme.spacing(.3),
    fontSize: '1.1rem',
    display: 'flex',
    justifyContent: 'center', // Center the message
    alignItems: 'center',
  },
  errorMessage: {
    fontWeight: 'bold',
    fontSize: '1.1rem', // Slightly larger text for readability
    color: theme.palette.common.white, // White text to contrast with the red background
  },
});


class SignUpNew extends FDetailComponent {
  constructor(props) {
    super(props, API_AUTH_SIGNUP_CARRIER_OWNERS, 'profile', {});
    this.state = {
      loading: false,
      errorResponse: '',
      method: 'post',
      url: API_AUTH_SIGNUP_CARRIER_OWNERS,
      formData: {},
      data: {
        dotNumber: '',
        profileEmailAsUsername: '',
        password: '',
        profileFirstName: '',
        profileLastName: '',
        profilePhoneNumber: '',
        profileSubscriptionType: '',
        clientDiscoveryType: '',
        clinetDiscoveryReference: '',
        agreeToTerms: false,
        reCaptcha: '',
        squareToken: '',
        paymentZipCode: '',
      },
      errorData: {},
      touched: { isCarrier: true },
      validators: {
        dotNumber: [VALIDATE_REQUIRED],
        profileEmailAsUsername: [VALIDATE_EMAIL],
        password: [VALIDATE_PASSWORD],
        profileFirstName: [VALIDATE_REQUIRED],
        profileLastName: [VALIDATE_REQUIRED],
        profilePhoneNumber: [VALIDATE_PHONE_NUMBER],
        clientDiscoveryType: [VALIDATE_REQUIRED],
        profileSubscriptionType: [VALIDATE_REQUIRED],
        agreeToTerms: [],
        reCaptcha: [VALIDATE_REQUIRED],
        squareToken: [VALIDATE_REQUIRED],
      },
    };
    this.recaptchaComponentRef = createRef();
  }

  fetchDetail = () => {
  }

  routeTosignIn = () => {
    this.props.dispatchSignIn()
    window.location.href = '/'
  }

  getSaveMeta = () => {
    return {
      method: "post",
      url: this.state.url
    }
  }



  // getErrorData = () => {
  //   let errorData = {
  //     dotNumber: !isValid(this.state.data.dotNumber, this.state.validators.dotNumber),
  //     profileEmailAsUsername: !isValid(this.state.data.profileEmailAsUsername, this.state.validators.profileEmailAsUsername),
  //     password: !isValid(this.state.data.password, this.state.validators.password),
  //     profileFirstName: !isValid(this.state.data.profileFirstName, this.state.validators.profileFirstName),
  //     profileLastName: !isValid(this.state.data.profileLastName, this.state.validators.profileLastName),
  //     profilePhoneNumber: !isValid(this.state.data.profilePhoneNumber, this.state.validators.profilePhoneNumber),
  //     profileSubscriptionType: !isValid(this.state.data.profileSubscriptionType, this.state.validators.profileSubscriptionType),
  //     clientDiscoveryType: !isValid(this.state.data.clientDiscoveryType, this.state.validators.clientDiscoveryType),
  //     squareToken: !isValid(this.state.data.squareToken, this.state.validators.squareToken),

  //     reCaptcha: this.state.data.reCaptcha && !isValid(this.state.data.reCaptcha, this.state.validators.reCaptcha),
  //   }
  //   return errorData
  // }

  hasCustomErrors = () => {
    return !(this.isAddressValid(true, true, true, true))
  }


  notifyNonce = (squareToken, paymentZipCode) => {
    this.updateValues('paymentZipCode', { paymentZipCode: paymentZipCode });
    this.updateValues('agreeToTerms', { agreeToTerms: true });
    this.updateValues('squareToken', { squareToken: squareToken }, this.onSignup);
  };


  notifyNoanceError = (error) => {
    this.updateValues('squareToken', { squareToken: '' }, this.onSignup);
  };

  resetCaptcha = () => {
    this.recaptchaComponentRef.current.resetRecaptcha();

  }


  updateValues = (name, obj, callback = () => { }) => {
    this.setState(prevState => {
      let newState = {
        ...prevState,
        data: {
          ...prevState.data, ...obj
        },
        touched: {
          ...prevState.touched,
          [name]: true
        }
      }
      return newState
    }, () => {
      this.validateSingle(name);
      callback();
    });
  }

  onSignup = (evt) => {
    this.validate().then(succ => {
      let data = this.getFormData()
      const { url, method } = this.getSaveMeta()
      this.setState({ errorResponse: '', loading: true })
      UPDATE(url, method, data).
        then(response => {
          this.setState({ loading: false })
          this.routeTosignIn()
        }).catch(error => {
          this.resetCaptcha()
          let errMsg = getErrroMessage(error)
          this.setState({ loading: false, errorResponse: errMsg })
        });

    }).catch(error => {

      this.setState({
        loading: false, errorResponse: `There were errors in your input. Please review and correct them before proceeding ${error || ''}.`
      })
    })
  }


  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        {/* Left Section: Background Image */}
        <div className={classes.imageSection} />

        {/* Right Section: Form */}
        <Paper className={classes.formSection} elevation={3}>
          {/* Logo in the top-right corner */}
          <img src={logo} alt="Carrier Management Software" className={classes.logo} />

          {/* Header */}
          <div className={classes.header}>
            <Typography component="h4" variant="h4" className={classes.title}>
              All-in-One Platform to Streamline Operations, <br /> Cut Costs, and Stay Compliant.
            </Typography>
          </div>

          {/* Form Container */}
          <Grid container className={classes.formContainer} spacing={2}>
            {/* DOT */}
            <Grid item xs={12} md={12}>
              <FInput
                name="dotNumber"
                required
                value={this.state.data.dotNumber}
                hasError={this.state.errorData.dotNumber}
                helpMessage="Please enter a valid DOT #."
                label="DOT #"
                notifyParent={this.updateValues}
              />
            </Grid>

            {/* First Name and Last Name */}
            <Grid item xs={12} md={6}>
              <FInput
                name="profileFirstName"
                required
                value={this.state.data.profileFirstName}
                hasError={this.state.errorData.profileFirstName}
                helpMessage="Please enter a valid first name."
                label="First Name"
                notifyParent={this.updateValues}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FInput
                name="profileLastName"
                required
                value={this.state.data.profileLastName}
                hasError={this.state.errorData.profileLastName}
                helpMessage="Please enter a valid last name."
                label="Last Name"
                notifyParent={this.updateValues}
              />
            </Grid>

            {/* Phone and Email */}
            <Grid item xs={12} md={6}>
              <FInput
                name="profilePhoneNumber"
                required
                value={this.state.data.profilePhoneNumber}
                hasError={this.state.errorData.profilePhoneNumber}
                helpMessage="Please enter a valid phone number."
                label="Phone Number"
                notifyParent={this.updateValues}
              />
            </Grid>
            {/* Fleet Size */}
            <Grid item xs={12} md={6}>
              <FSelect
                name="profileSubscriptionType"
                value={this.state.data.profileSubscriptionType}
                hasError={this.state.errorData.profileSubscriptionType}
                helpMessage="Please select a valid subscription type."
                label="Fleet Size *"
                data={this.props.profileSubscriptionTypeChoices}
                valueRef="prettyName"
                keyRef="id"
                notifyParent={this.updateValues}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FInput
                name="profileEmailAsUsername"
                required
                value={this.state.data.profileEmailAsUsername}
                hasError={this.state.errorData.profileEmailAsUsername}
                helpMessage="Please enter a valid email address."
                label="Email ( Username )"
                notifyParent={this.updateValues}
              />
            </Grid>
            {/* Password and Confirm Password */}
            <Grid item xs={12} md={6}>
              <FInput
                name="password"
                required
                value={this.state.data.password}
                type="password"
                hasError={this.state.errorData.password}
                helpMessage="Please enter a valid password."
                label="Password"
                notifyParent={this.updateValues}
                autoComplete="new-password"
              />
            </Grid>

            {/* Discovery Type */}
            <Grid item xs={12} md={6}>
              <FSelect
                name="clientDiscoveryType"
                required
                value={this.state.data.clientDiscoveryType}
                hasError={this.state.errorData.clientDiscoveryType}
                helpMessage="Please choose an option or select 'Other'."
                label="How did you discover us?"
                data={this.props.clinetDiscoverySourceChoices}
                valueRef="prettyName"
                keyRef="id"
                notifyParent={this.updateValues}
              />
            </Grid>

            {/* Reference Note */}
            <Grid item xs={12} md={6}>
              <FInput
                name="clinetDiscoveryReference"
                value={this.state.data.clinetDiscoveryReference}
                hasError={this.state.errorData.clinetDiscoveryReference}
                helpMessage="Reference Note (e.g., Coupon, Referred by)"
                label="Promo / Referral Code"
                notifyParent={this.updateValues}
              />
            </Grid>


            {/* Recaptcha */}
            <Grid item xs={12} md={6}>
              <FRecaptcha
                name="reCaptcha"
                hasError={this.state.errorData.reCaptcha}
                helpMessage="Please complete the CAPTCHA to prove you are not a robot."
                notifyParent={this.updateValues}
                recapthaSiteKey={RECAPCH_SITE_KEY}
                ref={this.recaptchaComponentRef}
              />
            </Grid>

            {/* Payment Form */}
            <Grid item xs={12}>
              <SignupSQPaymentForm
                loading={this.state.loading}
                validate={this.validate}
                notifyNonce={this.notifyNonce}
                notifyNonceError={this.notifyNonceError}
              />
            </Grid>
          </Grid>

          {/* Already a Member? Sign In */}
          <Typography className={classes.alreadyMember}>
            <Link component="button" variant="body2" onClick={this.routeTosignIn}>
              Already a Member? Sign In
            </Link>
          </Typography>

          <Grid container spacing={2} className={classes.formContainer}>
            {this.state.errorResponse && (
              <Grid item xs={12}>
                <div className={classes.errorContainer}>
                  <span className={classes.errorMessage}>{this.state.errorResponse}</span>
                </div>
              </Grid>
            )}
          </Grid>



          {/* Footer */}
          <div className={classes.footer}>
            <Typography variant="body2" align="center" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgba(0, 0, 0, 0.54)' }}>
              &copy; Copyright <a href="https://www.ztrucking.com" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>ZTRUCKING GROUP CORP.</a> &nbsp;| &nbsp;
              <a href="https://www.ztrucking.com/zlegals/UserAgreements.html" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Terms of Use</a> &nbsp;| &nbsp;
              <a href="https://www.ztrucking.com/zlegals/PrivacyAndData.html" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Privacy Policy</a>
            </Typography>
          </div>
          {this.state.loading && <CircularProgress className={classes.progress} />}
        </Paper>
      </div >
    );
  }
}

SignUpNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignUpNew));