import React, { Component } from "react";
import { Grid, Paper, Tooltip, IconButton, Typography } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import FGroupBox from "../widgets/FGroupBox";

// Integration Data with FMCSA Updated
const INTEGRATIONS = [
  {
    name: "Effortless Audit Preparation",
    link: "https://safer.fmcsa.dot.gov/CompanySnapshot.aspx",
    img: "/fmcsa.png",
    description: "Access FMCSA data to track your safety score and compliance status—helping you stay compliant and improve your operations."
  },
  {
    name: "Motive",
    link: "https://keeptruckin.com/oauth/authorize?client_id=1806c87dad880e5910fda1937951b3b960852a069d45abbf7b81ed74259b9389&redirect_uri=https://admin.ztrucking.com/integration/oauth-redirect/gomotive/&response_type=code&scope=users.read+fuel_purchases.read+hos_logs.hours_of_service+locations.vehicle_locations_list+locations.vehicle_locations_single+ifta_reports.trips+ifta_reports.summary+companies.read+hos_logs.read",
    img: "/motive.png",
    description: "Syncs trip data, HOS logs, and vehicle locations."
  },
  {
    name: "Apex",
    link: "https://www.apexcapitalcorp.com/",
    img: "/apex.png",
    description: "Seamless invoice submission for Apex factoring customers."
  },
  {
    name: "Triumph",
    link: "https://www.invoicefactoring.com/",
    img: "/triumph.png",
    description: "Direct invoice submission for Triumph customers."
  },
  {
    name: "TCS",
    link: "https://www.tcsfuel.com/",
    img: "/tcs.jpeg",
    description: "Manual fuel transaction uploads from TCS portal."
  },
  {
    name: "Fleet One Edge",
    link: "https://member.fleetoneedge.com/Account/Login?ReturnUrl=%2F",
    img: "/fleet_edge_one.png",
    description: "Manually upload fuel transactions from FleetOne Edge."
  },
  {
    name: "RoadEx (FlatRate)",
    link: "https://www.roadex.com/services/efs-fuel-card/",
    img: "/flatrate.png",
    description: "Upload RoadEx fuel transactions for driver tracking."
  },
  {
    name: "RTS",
    link: "https://www.rtsinc.com",
    img: "/rts.png",
    description: "Manually upload RTS fuel transactions for tracking."
  },
  {
    name: "QuickBooks",
    link: "https://quickbooks.intuit.com/accounting/",
    img: "/quickbooks.png",
    description: "Export transactions for seamless accounting integration."
  }
];

const IntegrationBlock = ({ link, img, name, description }) => (
  <Grid item xs={4} style={{ padding: "15px" }}>
    <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
      <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
        {name}
      </Typography>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Tooltip title={description} placement="top">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <img
              src={img}
              alt={name}
              style={{
                maxWidth: '100%',  // Make the image responsive to container width
                maxHeight: '80px', // Limit max height for consistency
                objectFit: 'contain',  // Preserve aspect ratio of the image
              }}
            />
          </div>
        </Tooltip>
      </a>
      <Typography variant="body2" style={{ fontSize: "12px", color: "#666", marginTop: "10px" }}>
        {description}
      </Typography>
    </Paper>
  </Grid>
);


// Main Integration Grid Component
export class APIIntegrations extends Component {
  render() {
    return (
      <FGroupBox groupTitle="Available Integrations" elevation={3} titlePaddingLeft="20px">
        <Grid container spacing={3} style={{ padding: "20px" }}>
          {INTEGRATIONS.map(integration => (
            <IntegrationBlock key={integration.name} {...integration} />
          ))}
        </Grid>
      </FGroupBox>
    );
  }
}
