import React from 'react';
import FRouterLink from '../widgets/FRouterLink';
import { API_FLEET_URL, ROUTE_FLEET_URL, ROUTE_CARRIERS_URL, ROUTE_CUSTOMER_URL, TAB_FLEET, FLEET_FLEETS, CHOICE_FLEET, getModuleTitle, FINANCE_INVOICE } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { Link } from '@material-ui/core';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal } from '../../util';
import BlockIcon from '@material-ui/icons/Block';
import { getFleetInvoiceReport, getStatusIcon } from '../../util/icon_util';
import FTableActionNew from '../widgets/FTableActionNew';
import FMaterialTableNew from '../widgets/FMaterialTableNew';
import { has_view_permission } from '../../util/ACL';



class Fleets extends FListComponent {
  constructor(props) {
    super(props, API_FLEET_URL, CHOICE_FLEET)
    this.currentTitle = 'Fleets'
    this.currentTab = TAB_FLEET
    this.routeUrl = ROUTE_FLEET_URL
    this.policyID = FLEET_FLEETS
    this.includeStateAction = false
  }

  onTableAction = (actionId, rowData) => {
    let data = { id: rowData.id };
    let url = this.getListURL() + rowData.id + '/event/';

    switch (actionId) {
      case "ACTION_DELETE":
        this.deleteRow(rowData);
        break;
      case "GENERATE_STANDARD_INVOICE":
      case "GENERATE_FACTORY_INVOICE":
        this.actionHandler(url, { ...data, eventType: actionId });
        break;
      default:
        console.warn(`Unhandled action: ${actionId}`);
    }
  };



  render() {

    return (
      <div>

        <FMaterialTableNew
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Reference #', field: 'fleetLoadNumber',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.fleetLoadNumber} to={ROUTE_FLEET_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },

            // {
            //   title: 'Dispatcher',
            //   render: this.getDispatcherLink
            // },
            {
              title: 'Driver',
              render: this.getDriverLink
            },

            {
              title: 'Customer',
              render: this.getCustomerLink
            },

            // {
            //   title: 'Pickup Date', field: 'fleetPickUpDate',
            //   render: rowData => this.getPrettyDate(rowData.fleetPickUpDate)
            // },
            {
              title: 'Trip Information',
              render: rowData => {
                const pickupLocation = rowData.fleetPickupLocation || 'N/A';
                const deliveryLocation = rowData.fleetDeliveryLocation || 'N/A';

                return (
                  <p style={{ // Use a div for better control
                    whiteSpace: 'nowrap', // Prevent wrapping
                    overflow: 'hidden',   // Hide overflow
                    textOverflow: 'ellipsis', // Add ellipsis
                    fontFamily: 'Arial, sans-serif',
                    display: 'flex',       // Use flexbox for alignment
                    alignItems: 'center'  // Center vertically
                  }}>
                    (<b>{this.getPrettyDate(rowData.fleetPickUpDate)}</b>) {pickupLocation}<b>&nbsp;{"==>"}&nbsp;</b>{deliveryLocation}
                  </p>
                );
              }
            },
            { title: 'Load Fee', render: rowData => roundDecimal(rowData.fleetLoadFee) },

            { title: 'Other Fees', field: 'supplementalFees', render: rowData => roundDecimal(rowData.supplementalFees) },

            {
              title: 'Confirmation / BOL',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.fleetConfirmation && <Link href={rowData.fleetConfirmation} target="_blank">Confirmation</Link>}
                    &nbsp; | &nbsp;
                    {rowData.fleetBOL && <Link href={rowData.fleetBOL} target="_blank">BOL</Link>}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Status',
              render: rowData => {
                return (
                  <React.Fragment>
                    {getStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Latest Invoice',
              render: rowData => {
                if (!has_view_permission(this.props.profile, FINANCE_INVOICE)) {
                  return null;
                }

                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: '1em', fontWeight: 'bold', color: '#333', marginRight: 1 }}>
                      {getFleetInvoiceReport(rowData)}
                    </span>
                  </div>
                );
              }
            },
            {
              title: 'Actions',
              render: rowData => {
                const actions = rowData.workflowActionItems || [];

                return (
                  <React.Fragment>
                    {!!actions.length ? (
                      <FTableActionNew
                        actions={actions}
                        onTableAction={this.onTableAction}
                        rowData={rowData}
                      />
                    ) : (
                      <BlockIcon color="disabled" titleAccess="No Actions Available" />
                    )}
                  </React.Fragment>
                );
              }
            },
          ]}
          actions={
            [
              this.getAddNewModelActions()
            ]}
          options={{
            pageSize: this.state.pageSize,
            pageSizeOptions: this.state.pageSizeOptions,
            actionsColumnIndex: -1
          }}
          data={this.fetchData}
          title="Fleets"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(Fleets)
