import React, { useState, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import FInput from './FInput';
import { isValidZipCode } from '../../util/Validator';
import { COUNTRY_STATES_NAMES } from '../../fredux/Const';
import FSelect from './FSelect';
import { toJson } from '../../util';

const FAddress = (props) => {
  const getAddress = (val) => {
    const parsedVal = toJson(val);
    if (parsedVal && typeof parsedVal === 'object') {
      return parsedVal;
    } else {
      return {
        place: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      };
    }
  };

  const [addressState, setAddressState] = useState(getAddress(props.address));

  useEffect(() => {
    setAddressState(getAddress(props.address));
  }, [props.address]);

  const updateValues = (name, obj) => {
    setAddressState(prevState => {
      const newState = { ...prevState, ...obj };
      props.notifyParent(props.name, { [props.name]: JSON.stringify(newState) });
      return newState;
    });
  };

  const hasErr = (val) => {
    if (!addressState) return true;
    let hasVal = !!addressState[val];
    if (val === 'zipCode') {
      return !isValidZipCode(addressState.zipCode);
    }
    return !hasVal;
  };

  const getValue = (val) => {
    return addressState?.[val] || '';
  };

  const place_label = `${props.props_label || "Address"}`;
  const city_label = `${props.label_city || "City"}`;
  const state_label = `${props.label_state || "Province / State"}`;
  const zipCode_label = `${props.label_zipCode || "Zip Code"}`;

  return (
    <Grid container spacing={4} xs={12}>
      <Grid item xs={12} md>
        <FInput
          name={'place'}
          required={props.place_required}
          disabled={props.disabled}
          value={getValue('place')}
          hasError={props.place && hasErr('place')}
          helpMessage={'Invalid Place'}
          label={place_label}
          notifyParent={updateValues}
        />
      </Grid>

      <Grid item xs={12} md>
        <FInput
          name='city'
          required={props.city_required}
          disabled={props.disabled}
          value={getValue('city')}
          hasError={props.city && hasErr('city')}
          helpMessage={'Invalid City'}
          label={city_label}
          notifyParent={updateValues}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <FSelect
          name='state'
          required={props.state_required}
          disabled={props.disabled}
          value={getValue('state')}
          hasError={props.state && hasErr('state')}
          helpMessage={'Invalid Province / State'}
          label={state_label}
          data={COUNTRY_STATES_NAMES}
          valueRef='prettyName'
          keyRef='prettyName'
          notifyParent={updateValues}
        />
      </Grid>

      <Grid item xs={12} md>
        <FInput
          name='zipCode'
          required={props.zipCode_required}
          disabled={props.disabled}
          value={getValue('zipCode')}
          hasError={props.zipCode && hasErr('zipCode')}
          helpMessage={'Invalid Zip'}
          label={zipCode_label}
          notifyParent={updateValues}
        />
      </Grid>
    </Grid>
  );
};

export default FAddress;