import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { TAB_DRIVER_APP, DRIVER_APP_EMPLOYMENT_APPLICATION, ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL, API_DRIVER_UPLOAD_DOCUMENT_URL, CHOICE_DRIVER_UPLOAD_DOCUMENT, ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL, DRIVER_APP_DOCUMENT_UPLOADS, ROUTE_DRIVER_URL, ADMIN_DRIVERS } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FTableAction from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import BlockIcon from '@material-ui/icons/Block';
// import { getDriverAppUploadIcon } from '../../util/icon_util';
import { Link } from '@material-ui/core';
import FAttachment from '../widgets/FAttachment';
import FRouterLink from '../widgets/FRouterLink';
import { getDriverAppUploadStatusIcon } from '../../util/icon_util';
import { has_edit_permission } from '../../util/ACL';

const ACTION_DELETE = 'Delete / Reject / Cancel'
const ACTION_APPROVE_DOCUMENT_UPLOAD = 'Approve & Save'

const  PENDING = 'PENDING'
const  PENDING_REVIEW = 'PENDING_REVIEW'
const COMPLETE = 'COMPLETE'


const DRIVER_DOCUMENT_UPLOAD_STATE_MACHINE = {
  [PENDING]: [ACTION_APPROVE_DOCUMENT_UPLOAD, ACTION_DELETE],
  [PENDING_REVIEW]: [ACTION_APPROVE_DOCUMENT_UPLOAD, ACTION_DELETE],
  [COMPLETE]: [ACTION_APPROVE_DOCUMENT_UPLOAD, ACTION_DELETE],
}


const styles = theme => ({
  smallAvatar: {
    width: 10,
    height: 10,
    border: `2px solid ${theme.palette.background.paper}`,

  },
});

class DriverAppUploads extends FListComponent {
  constructor(props) {
    super(props, API_DRIVER_UPLOAD_DOCUMENT_URL, CHOICE_DRIVER_UPLOAD_DOCUMENT)
    this.currentTitle = 'Drive Uploads'
    this.currentTab = TAB_DRIVER_APP
    this.routeUrl = ROUTE_DRIVER_APP_UPLOAD_DOCUMENT_URL
    this.policyID = DRIVER_APP_DOCUMENT_UPLOADS
    this.includeStateAction = false

  }

  getActions = (rowData, target, ...actions) => {
    let _actions = []
    let profileHasPermission = (has_edit_permission(this.props.profile, ADMIN_DRIVERS))
    if (!!profileHasPermission) {
      _actions = _actions.concat(DRIVER_DOCUMENT_UPLOAD_STATE_MACHINE[rowData.driverAppUploadStatus])
    }
    return _actions
  }

  onTableAction = (action, rowData) => {
    switch (action) {
      case ACTION_APPROVE_DOCUMENT_UPLOAD:
        this.updateRow(rowData, { driverAppUploadStatus: 'COMPLETE'})
        break;
      case ACTION_DELETE:
        this.deleteRow(rowData)
        break;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Driver / Prospect Driver',
              render: rowData => {
                return (
                  <React.Fragment>
                    <div style={{ display: 'inline-flex' }}>
                      {this.getDriverInfoEditLink(rowData.driverProfile)}
                      
                    </div>
                </React.Fragment>
                );
              }
            },
            {
              title: 'Document / Status',
              render: rowData => {
                const hasDocumentFile = rowData.documentFile || false;
                return (
                  hasDocumentFile ? (
                    <React.Fragment>
                      <div style={{ display: 'inline-flex' }}>
                        {getDriverAppUploadStatusIcon(rowData)}
                        
                      </div>
                    </React.Fragment>
                  ) : null
                );
              }
            },
            {
              title: 'ID Number',
              render: rowData => rowData.driverAppUploadData?.documentID || ''
            },
            {
              title: 'Issuing State',
              render: rowData => rowData.driverAppUploadData?.documentIssuedState || ''
            },
            {
              title: 'Issue Date',
              render: rowData => {
                const documentIssuedDate = rowData.driverAppUploadData?.documentIssuedDate;
                return documentIssuedDate
                  ? new Date(documentIssuedDate).toLocaleDateString()
                  : 'N/A';
              }
            },
            {
              title: 'Expiration Date',
              render: rowData => {
                const documentExpirationDate = rowData.driverAppUploadData?.documentExpirationDate;
                return documentExpirationDate
                  ? new Date(documentExpirationDate).toLocaleDateString()
                  : 'N/A';
              }
            },


            { title: 'Upload Date', render: rowData => this.getPrettyDate(rowData.driverAppUploadDate) },
          

            {
              title: 'Actions',
              render: rowData => {
                let actions = this.getActions(rowData, DRIVER_APP_EMPLOYMENT_APPLICATION)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction
                      actions={actions}
                      onTableAction={this.onTableAction}
                      rowData={rowData}
                    />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          options={{
            pageSize: this.state.pageSize,
            pageSizeOptions: this.state.pageSizeOptions,
            actionsColumnIndex: -1
          }}
          data={this.fetchData}
          title="Driver Employment Application"
          listErrCode={this.state.listErrCode}
        />
      </div>
    );
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(DriverAppUploads))
