import React from 'react';
import { Link } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

// import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';

import { API_EXPENSE_URL, ROUTE_EXPENSE_URL } from '../../../util/Const';
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';
// import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';

export default class Expenses extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            {
              title: 'Transaciton Id', 
              field: 'expenseTransactionID',
            },
            { title: 'Transaction Date', field: 'expenseTransactionDate',
               render: rowData => getPrettyDate(rowData.expenseTransactionDate) },
            { title: 'Maintenance Type', field: 'expenseMaintenanceCategory'},
            { title: 'Service Provider', field: 'maintenaceServiceProviderPrettyName' },
            { title: 'Amount ($)', field: 'expenseAmount',  render: rowData => roundDecimal(rowData.expenseAmount)},

          ]}
          
          options = {{
            pageSize: this.props.pstate.pageSize,
            pageSizeOptions: this.props.pstate.pageSizeOptions,
            actionsColumnIndex: -1,
            search: false
         }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}        
        />
      </div>
    );
  }
}


// export default connect(
//   mapStateToProps, mapDispatchToProps)(Expenses)
