import React, { Component } from 'react'

import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { Button, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Route } from 'react-router-dom'

const style = theme => ({
    defaultBack: {
        marginTop: theme.spacing(1),
    },

    backButton: {
        marginLeft: theme.spacing(4),
        width: 5 * theme.spacing(4)
    },
    titleUrl: {
        marginTop: theme.spacing(1),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 'x-large'
      },
});

class FRouteBackLink extends Component {

    render() {
        const { classes } = this.props;
        return (
            <Route render={({ history}) => (
                <Button
                  className={classes[this.props.className || 'defaultBack']}
                //   className={classes.backButton}
                  color={'secondary'}
                  variant={this.props.variant || "flat"}
                  onClick={() => {
                      history.goBack()  
                 }}
                >
                  {this.props.label || 'Cancel'}
                </Button>
              )} />
        )
    }
}

class FRouterLink extends Component {
    handleClick = (e) => {
        if (this.props.disabled) {
            e.preventDefault();
        }
        if (!!this.props.notifyParent) {
            this.props.notifyParent();
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Link
                component={RouterLink}
                to={this.props.to}
                onClick={this.handleClick}
                className={classes[this.props.className]}
            >
                {this.props.icon && <AddIcon />}
                {!this.props.icon && !!!this.props.editIcon && <span style={{ display: 'inline' }}>{this.props.label}</span>}
                {!this.props.icon && !!this.props.editIcon && (
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {this.props.label} <EditIcon style={{ fontSize: '16px', paddingLeft: '3px' }} />
                    </span>
                )}
            </Link>
        );
    }
}

class FRouterLinkOld extends Component {

    handleClick = (e) =>  {
        if (this.props.disabled) {
             e.preventDefault();
        }
        if (!!this.props.notifyParent) {
            this.props.notifyParent()
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Link button component={RouterLink} to={this.props.to} onClick={this.handleClick}
                className={classes[this.props.className]}
                >
                {this.props.icon && <AddIcon />}
                {!this.props.icon && !!!this.props.editIcon && 
                   <Button color={this.props.color || 'primary'} disabled={this.props.disabled}>
                    {this.props.label}
                </Button>
                }
                {!this.props.icon && !!this.props.editIcon && 
                   <Button color={this.props.color || 'primary'} disabled={this.props.disabled}>
                    {this.props.label}<EditIcon style={{fontSize: '20px', paddingLeft: '3px'}}/>
                </Button>
                }
            </Link>
        )
    }
 
}

class FRouterAddLink extends Component {

    handleClick = (e) =>  {
        if (this.props.disabled) {
             e.preventDefault();
        }
    }

    render() {
        const { classes } = this.props;
        return (
           <Link 
                component={RouterLink} 
                to={this.props.to}
                onClick={this.handleClick} >
                <Fab disabled={this.props.disabled} size="small"
                    color={this.props.color} aria-label="Add">
                     <AddIcon />

               </Fab>
                  
            </Link>
           
        )
    }
}

class FRouterWithAddLabelLink extends Component {

    handleClick = (e) => {
        if (this.props.disabled) {
            e.preventDefault();
        }
    }

    render() {
        const { 
            classes, 
            to, 
            label, 
            disabled, 
            linkColor = '#3f50b5', // default value 
            iconColor = "#ba000d" // default value 
        } = this.props;
        
        return (
            <Button
                component={RouterLink}
                to={to}
                onClick={this.handleClick}
                disabled={disabled}
                size="large"
                style={{ color: linkColor, textDecoration: 'none' }} // make it look more button-like
                endIcon={<AddIcon style={{ color: iconColor }} />}
            >
                {label}
            </Button>
        );
    }
}



class FRouterAddPopupLink extends Component {

    handleClick = (e) =>  {
        if (this.props.disabled) {
             e.preventDefault();
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Link 
                hidden={this.props.hidden}
                component={RouterLink} to={this.props.to} onClick={this.handleClick} >
                <AddIcon />
            </Link>
        )
    }
}


class FRouterTitleLink extends Component {

    handleClick = (e) =>  {
        if (this.props.disabled) {
             e.preventDefault();
        }
    }

    render() {
      const { classes } = this.props
      return (
            <Link component={RouterLink} to={this.props.to} onClick={this.handleClick} >
                <span className={classes.titleUrl}>{this.props.label} </span>
            </Link>)
    }
  }


FRouteBackLink =  withStyles(style)(FRouteBackLink)
FRouterLink = withStyles(style)(FRouterLink)
FRouterAddLink = withStyles(style)(FRouterAddLink)
FRouterTitleLink = withStyles(style)(FRouterTitleLink)
FRouterAddPopupLink = withStyles(style)(FRouterAddPopupLink)
FRouterWithAddLabelLink =  withStyles(style)(FRouterWithAddLabelLink)

export {FRouteBackLink, FRouterLink, FRouterAddLink, FRouterTitleLink, FRouterAddPopupLink, FRouterWithAddLabelLink}
export default withStyles(style)(FRouterLink)